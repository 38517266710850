import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import ImpactCarousel from 'components/impactCarousel';
import { routes } from 'routes/routes';
import { getAnonymousStripePaymentEmbedded } from 'store/auth/auth.services';
import { selectedCharitiesState } from 'store/charities/charities.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { tryParseInt, getImactCarouselData } from 'utils/utils';
import { causesState, getSpecialCauseData } from 'store/causes/causes.slice';
import { CharityTypes } from 'store/causes/types';
import { newFlow1State, setDonationAmount } from 'store/newFlow1/newFlow1.slice';
import { authState, createUserSession } from 'store/auth/auth.slice';

const SeaspiracyIntroPage = () => {
   const isWideScreen = useMediaQuery('(min-width:720px)');
   const { causesList } = useAppSelector(causesState);
   const { donationAmount } = useAppSelector(newFlow1State);
   const { signupData } = useAppSelector(authState);
   const minAmount = tryParseInt(process.env.REACT_APP_MIN_AMOUNT, 5);
   const changeAmount = tryParseInt(process.env.REACT_APP_CHANGE_AMOUNT, 5);
   const maxAmount = tryParseInt(process.env.REACT_APP_MAX_AMOUNT, 3000);
   const initialAmount = tryParseInt(process.env.REACT_APP_DEFAULT_AMOUNT, 20);

   const [causes, setCauses] = useState<string[]>([]);

   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { specialCauseData } = useAppSelector(causesState);
   const { selectedCharities } = useAppSelector(selectedCharitiesState);

   const feedbackTexts = [
      'getStarted.summary.feedback1',
      'getStarted.summary.feedback2',
      'getStarted.summary.feedback3',
   ];

   const getCauses = useCallback(() => {
      const causesSet = new Set<string>();
      selectedCharities.forEach((ch) => causesSet.add(ch.causes[0].title));
      const causesArray = Array.from(causesSet);
      return causesArray;
   }, [selectedCharities]);

   const handleNext = async () => {
      const metaData = {
         sytemSessionId: signupData.sessionId,
         flow: 'seaspiracy',
         ref_id: signupData.referralCode,
      };
      const cs = await getAnonymousStripePaymentEmbedded({
         money: donationAmount,
         serviceFee: 0,
         extraParam: 'flow=seaspiracy',
         metaData,
      });
      navigate(routes().seaspiracy.payment, { state: { cs } });
   };

   const changeAmountButtonClick = (increase: boolean) => {
      let multi = Math.floor(donationAmount / changeAmount);
      // eslint-disable-next-line no-plusplus
      increase ? multi++ : multi--;
      let finalValue = multi * changeAmount;
      if (finalValue < minAmount) {
         finalValue = minAmount;
      } else if (finalValue > maxAmount) {
         finalValue = maxAmount;
      }
      dispatch(setDonationAmount(finalValue));
   };

   useEffect(() => {
      if (!specialCauseData?.seaspiracy) {
         dispatch(getSpecialCauseData({ causeName: 'seaspiracy' }));
      }
      dispatch(setDonationAmount(initialAmount));
      dispatch(createUserSession());
   }, []);

   useEffect(() => {
      if (
         specialCauseData?.seaspiracy.causes?.[0]?.title &&
         !causes.includes(specialCauseData.seaspiracy.causes[0].title)
      ) {
         setCauses((prev) => [...prev, specialCauseData.seaspiracy.causes[0].title]);
      }
   }, [specialCauseData?.seaspiracy.causes]);

   return (
      <>
         <Box
            sx={{
               position: 'fixed',
               top: 0,
               height: '100dvh',
               left: isWideScreen ? 0 : 'unset',
               width: '100vw',
               backgroundImage: 'url(/images/seaspiracy/bg.png)',
               backgroundSize: 'cover',
               backgroundPosition: { xs: 'center', sm: 'top' },
               zIndex: 1,
            }}
         />
         <Box
            sx={{
               flex: '1',
               display: 'flex',
               flexDirection: 'column',
               px: { xs: 2.5, md: 0 },
               pb: 5,
               width: '100%',
            }}
         >
            <Box
               sx={{
                  pt: { xs: 23.5, md: 5 },
                  zIndex: 10,
               }}
            >
               <Box sx={{ px: 2.5, mb: 2.5 }}>
                  <img width='100%' src='/images/seaspiracy/seaspiracy.png' alt='seaspiracy' />
               </Box>
               <Box sx={{ px: 2.5 }}>
                  <Typography
                     textAlign='center'
                     sx={{
                        color: '#FFFFFF',
                        fontWeight: 700,
                        fontSize: '36px',
                        lineHeight: '36px',
                     }}
                  >
                     {t('seaspiracy.intro.title')}
                  </Typography>
               </Box>
               <Box sx={{ px: 2.5, mt: 2.5 }}>
                  <Typography
                     textAlign='center'
                     sx={{
                        color: '#FFFFFF',
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '19px',
                     }}
                  >
                     <Trans
                        i18nKey='seaspiracy.intro.text'
                        components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                     />
                  </Typography>
               </Box>

               <Box sx={{ mt: 2.5, mb: 50 / 8, display: 'flex', justifyContent: 'center' }}>
                  <svg width={28} height={49} viewBox='0 0 28 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
                     <path
                        d='M13.994 48.783a1.5 1.5 0 0 1-.544-.1 1.5 1.5 0 0 1-.5-.35l-11.9-11.9q-.45-.45-.45-1.06t.45-1.04q.44-.45 1.045-.45t1.055.45l9.35 9.35v-13.8q0-.637.434-1.07a1.46 1.46 0 0 1 1.075-.43q.641 0 1.066.43.425.433.425 1.07v13.8l9.35-9.35q.45-.45 1.055-.45t1.045.45q.45.45.45 1.05t-.45 1.05l-11.9 11.9a1.5 1.5 0 0 1-.506.35q-.258.1-.55.1m-.003-23.4q-.641 0-1.066-.432a1.46 1.46 0 0 1-.425-1.068v-4q0-.637.434-1.07a1.46 1.46 0 0 1 1.075-.43q.641 0 1.066.43.425.433.425 1.07v4.05q0 .65-.434 1.05-.433.4-1.075.4m0-10q-.641 0-1.066-.432a1.46 1.46 0 0 1-.425-1.068v-3q0-.637.434-1.07a1.46 1.46 0 0 1 1.075-.43q.641 0 1.066.43.425.432.425 1.07v3.05q0 .65-.434 1.05-.433.4-1.075.4m-1.066-9.432q.425.432 1.066.432.642 0 1.075-.4.434-.4.434-1.05v-3.05a1.463 1.463 0 0 0-1.491-1.5q-.642 0-1.075.43-.434.432-.434 1.07v3q0 .638.425 1.068'
                        fill='#fff'
                     />
                  </svg>
               </Box>

               {/* First island */}
               <Box
                  sx={{ backgroundColor: '#FFFFFF', py: 2.5, borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
               >
                  <Box sx={{ mb: 0.75, mx: 2.5 }}>
                     <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                        {t('seaspiracy.intro.donationPlan').toUpperCase()}
                     </Typography>
                  </Box>

                  <Box sx={{ mt: 0.5, mx: 2.5, pb: 1.5, mb: 1.5, borderBottom: '1px solid #E5E5E5' }}>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.donationPlan.title')}
                     </Typography>
                  </Box>
                  <Box sx={{ mb: 0.75, mx: 2.5 }}>
                     <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                        {t('seaspiracy.intro.causes').toUpperCase()}
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '6px',
                        borderBottom: '1px solid #E5E5E5',
                        pb: 1.5,
                        mb: 1.5,
                        mx: 2.5,
                     }}
                  >
                     {causes?.map((c, idx) => (
                        <Box
                           sx={{ width: 'fit', borderRadius: '6px', backgroundColor: '#EFCAC9', py: 0.75, px: 1 }}
                           key={`${c}-${idx + 1}`}
                        >
                           <Typography sx={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px' }}>{c}</Typography>
                        </Box>
                     ))}
                  </Box>

                  <Box sx={{ mb: 0.5, mx: 2.5 }}>
                     <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                        {t('seaspiracy.intro.charities').toUpperCase()}
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '6px',
                        borderBottom: '1px solid #E5E5E5',
                        mx: 2.5,
                        pb: 1.5,
                        mb: 1.5,
                     }}
                  >
                     {specialCauseData?.seaspiracy.charities.map((charity: CharityTypes) => (
                        <Box
                           key={`ch-${charity.id}`}
                           sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              columnGap: 1,
                              height: '40px',
                           }}
                        >
                           <Box
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 boxSizing: 'border-box',
                                 border: '1px solid #E6E6E6',
                                 overflow: 'hidden',
                              }}
                           >
                              <img alt={charity.title} src={charity.icon} height='100%' width='100%' />
                           </Box>

                           <Box
                              sx={{
                                 mr: 'auto',
                              }}
                           >
                              <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: 600 }}>
                                 {charity.title}
                              </Typography>
                              <Typography
                                 noWrap
                                 sx={{
                                    fontSize: '11px',
                                    lineHeight: '13px',
                                    fontWeight: 500,
                                    color: '#999999',
                                 }}
                              >
                                 {charity.shortDescription}
                              </Typography>
                           </Box>

                           <Box sx={{ ml: 'auto' }}>
                              <DynamicGoogleIcon iconName='info' />
                           </Box>
                        </Box>
                     ))}
                  </Box>
                  <Box
                     sx={{
                        borderBottom: '1px solid #E5E5E5',
                        mx: 2.5,
                        pb: 1.5,
                        mb: 1.5,
                     }}
                  >
                     <Box sx={{ mb: 0.75 }}>
                        <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                           {t('seaspiracy.intro.monthlyBudget').toUpperCase()}
                        </Typography>
                     </Box>

                     <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 1.25 }}>
                        <Box
                           onClick={() => {
                              analytics?.track('new_flow1_amount_change', {
                                 action: 'minus',
                                 amount: donationAmount - changeAmount,
                              });
                              changeAmountButtonClick(false);
                           }}
                           sx={{
                              width: 24,
                              height: 24,
                              borderRadius: '50%',
                              backgroundColor: '#000000',
                              opacity: donationAmount === minAmount ? 0.6 : 1,
                              cursor: 'pointer',
                           }}
                        >
                           <DynamicGoogleIcon iconColor='#FFFFFF' iconName='remove' />
                        </Box>
                        <Box
                           sx={{
                              py: 0.5,
                              px: 1.5,
                              width: 85,
                              borderRadius: '4px',
                              border: '1px solid #E5E5E5',
                           }}
                        >
                           <Typography
                              textAlign='center'
                              sx={{ fontSize: '20px', lineHeight: '22px', fontWeight: 700 }}
                           >
                              ${donationAmount}
                           </Typography>
                        </Box>
                        <Box
                           onClick={() => {
                              analytics?.track('new_flow1_amount_change', {
                                 action: 'plus',
                                 amount: donationAmount + changeAmount,
                              });

                              changeAmountButtonClick(true);
                           }}
                           sx={{
                              width: 24,
                              height: 24,
                              borderRadius: '50%',
                              backgroundColor: '#000000',
                              opacity: donationAmount === maxAmount ? 0.6 : 1,
                              cursor: 'pointer',
                           }}
                        >
                           <DynamicGoogleIcon iconColor='#FFFFFF' iconName='add' />
                        </Box>
                     </Box>
                  </Box>
                  <Box>
                     <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mx: 2.5, mb: 0.75 }}>
                        <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                           {t('getStarted.summary.impact')}
                        </Typography>
                        <DynamicGoogleIcon iconColor='#666666' iconSizeVariant='small' iconName='contact_support' />
                     </Box>
                     <ImpactCarousel
                        size='small'
                        amount={donationAmount}
                        charities={getImactCarouselData(specialCauseData?.seaspiracy.charities ?? [])}
                     />
                     <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px', px: 2.5, mb: 1.5 }}>
                        {[1, 2, 3].map((idx) => (
                           <Typography
                              sx={{ color: '#666666', fontSize: '14px', lineHeight: '17px' }}
                              key={`donationDot-${idx}`}
                           >
                              {t(`seaspiracy.intro.donationDot${idx}`)}
                           </Typography>
                        ))}
                     </Box>
                  </Box>
                  {/* sticky button */}
               </Box>
               <Box
                  sx={{
                     mt: '-1px',
                     px: 2.5,
                     py: 2.5,
                     top: 0,
                     position: { xs: 'sticky', sm: 'static' },
                     zIndex: 10,
                     backgroundColor: '#FFFFFF',
                     borderBottomRightRadius: '8px',
                     borderBottomLeftRadius: '8px',
                  }}
               >
                  <Button onClick={handleNext} variant='primaryBtn'>
                     <Typography>{t('seaspiracy.intro.mainCTA')}</Typography>
                  </Button>
               </Box>

               {/* 2nd island */}
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     py: 2.5,
                     px: 2,
                     borderRadius: '8px',
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island2.title')}
                     </Typography>
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#666666', fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>
                        <Trans
                           i18nKey='seaspiracy.intro.island2.text'
                           components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                        />
                     </Typography>
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island2.title2')}
                     </Typography>
                     <Box
                        sx={{
                           mt: 1.25,
                           display: 'flex',
                           flexDirection: 'column',
                           rowGap: 2,
                        }}
                     >
                        {specialCauseData?.seaspiracy?.charities.map((ch: CharityTypes) => (
                           <Box
                              key={`meet-${ch.title}`}
                              sx={{
                                 width: '100%',
                                 display: 'flex',
                                 alignItems: 'center',
                                 flexDirection: 'row',
                                 columnGap: 1,
                                 overflow: 'hidden',
                              }}
                           >
                              <Box
                                 sx={{
                                    minWidth: '40px',
                                    width: '40px',
                                    height: '40px',
                                    boxSizing: 'border-box',
                                    border: '1px solid #E6E6E6',
                                    overflow: 'hidden',
                                 }}
                              >
                                 <img alt={ch.title} src={ch.icon} height='100%' width='100%' />
                              </Box>

                              <Box
                                 sx={{
                                    mr: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                 }}
                              >
                                 <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: 600 }}>
                                    {ch.title}
                                 </Typography>
                                 <Typography
                                    sx={{
                                       fontSize: '11px',
                                       lineHeight: '13px',
                                       fontWeight: 500,
                                       color: '#999999',
                                    }}
                                 >
                                    {ch.description}
                                 </Typography>
                              </Box>
                           </Box>
                        ))}
                     </Box>
                  </Box>
               </Box>

               {/* 3rd island */}
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     py: 2.5,
                     px: 2,
                     borderRadius: '8px',
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island3.title')}
                     </Typography>
                  </Box>

                  <Box>
                     <img width='100%' src='/images/seaspiracy/ocean-conservation.png' alt='seaspiracy' />
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#666666', fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>
                        <Trans
                           i18nKey='seaspiracy.intro.island3.text'
                           components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                        />
                     </Typography>
                  </Box>
               </Box>

               {/* 4th island */}
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     py: 2.5,
                     px: 2,
                     borderRadius: '8px',
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island4.title')}
                     </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.5, alignItems: 'center' }}>
                     <img width='60px' src='/images/seaspiracy/ali-tabrizi.png' alt='seaspiracy' />
                     <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: '14px', lineHeight: '17px' }}>
                        {t('seaspiracy.intro.island4.aliTeam')}
                     </Typography>
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#666666', fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>
                        <Trans
                           i18nKey='seaspiracy.intro.island4.text'
                           components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                        />
                     </Typography>
                  </Box>
               </Box>

               {/* 5th island */}
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     py: 2.5,
                     px: 2,
                     borderRadius: '8px',
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island5.title')}
                     </Typography>
                  </Box>
                  <Box>
                     <Typography sx={{ color: '#666666', fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>
                        <Trans
                           i18nKey='seaspiracy.intro.island5.text'
                           components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                        />
                     </Typography>
                  </Box>
                  <Box>
                     <Typography
                        sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px', mb: '10px' }}
                     >
                        {t('seaspiracy.intro.island5.title2')}
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           rowGap: 1,
                        }}
                     >
                        {[1, 2, 3, 4].map((dot, idx) => (
                           <Box
                              key={`dot-${dot + idx}`}
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 columnGap: '12px',
                                 alignItems: 'center',
                              }}
                           >
                              <Box
                                 sx={{
                                    minWidth: '24px',
                                    minHeight: '24px',
                                    backgroundColor: '#F55D66',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Typography
                                    sx={{ color: '#FFFFFF', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}
                                 >
                                    {dot}
                                 </Typography>
                              </Box>
                              <Box>
                                 <Typography sx={{ color: '#666666', fontSize: '14px', lineHeight: '17px' }}>
                                    {t(`seaspiracy.intro.island5.dot${idx + 1}`)}
                                 </Typography>
                              </Box>
                           </Box>
                        ))}
                     </Box>
                  </Box>
               </Box>

               <Box
                  sx={{
                     px: 2,
                     mt: 2.5,
                     py: 2.5,
                     borderRadius: '8px',
                     backgroundColor: '#FFFFFF',
                     display: 'flex',
                     flexDirection: 'column',
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        columnGap: 0.5,
                        alignItems: 'center',
                        mb: 2.5,
                     }}
                  >
                     <DynamicGoogleIcon iconColor='#F55D66' iconName='reviews' />
                     <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px', color: '#F55D66' }}>
                        {t('getStarted.summary.feedBackTitle')}
                     </Typography>
                  </Box>

                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: 1.25,
                        alignItems: 'stretch',
                     }}
                  >
                     {feedbackTexts.map((text) => (
                        <Box
                           sx={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              rowGap: 0.5,
                              px: 1.25,
                              py: 2.5,
                              borderRadius: '8px',
                              border: '1px solid #E5E5E5',
                           }}
                           key={text}
                        >
                           <DynamicGoogleIcon iconColor='#F55D66' iconName='task_alt' />
                           <Typography textAlign='center'>{t(text)}</Typography>
                        </Box>
                     ))}
                  </Box>
               </Box>

               {/* 6th island */}
               <Box
                  sx={{
                     backgroundColor: '#FFFFFF',
                     py: 2.5,
                     px: 2,
                     borderRadius: '8px',
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island6.title')}
                     </Typography>
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#666666', fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>
                        <Trans
                           i18nKey='seaspiracy.intro.island6.text'
                           components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                        />
                     </Typography>
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#333333', fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                        {t('seaspiracy.intro.island6.title2')}
                     </Typography>
                  </Box>

                  <Box>
                     <Typography sx={{ color: '#666666', fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>
                        <Trans
                           i18nKey='seaspiracy.intro.island6.text2'
                           components={{ bold: <span style={{ fontWeight: 700 }} /> }}
                        />
                     </Typography>
                  </Box>

                  <Box>
                     <Button onClick={handleNext} variant='primaryBtn'>
                        <Typography>{t('seaspiracy.intro.donateNowCTA')}</Typography>
                     </Button>
                  </Box>
               </Box>

               {/* <FixedGradientContainer containerSx={{ height: '100px' }}>
                  <Button onClick={handleNext} variant='primaryBtn'>
                     <Typography>{t('seaspiracy.intro.mainCTA')}</Typography>
                  </Button>
               </FixedGradientContainer> */}
            </Box>
         </Box>
      </>
   );
};

export default SeaspiracyIntroPage;
