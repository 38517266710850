import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconWrapper, AmountBox } from 'pages/subscription/styles';
import { NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS } from 'styles/constants';
import { tryParseInt } from 'utils/utils';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import FixedGradientContainer from 'components/newFlow1/FixedGradientContainer';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDonationAmount, newFlow1State } from 'store/newFlow1/newFlow1.slice';
import { causesState } from 'store/causes/causes.slice';
import CreatorQuote from 'components/newFlow1/CreatorQuote';
import { creatorQuotesState } from 'store/creatorQuotes/creatorQuotes.slice';

const NewFlowSubscribtionPage = () => {
   const minAmount = tryParseInt(process.env.REACT_APP_MIN_AMOUNT, 5);
   const changeAmount = tryParseInt(process.env.REACT_APP_CHANGE_AMOUNT, 5);
   const maxAmount = tryParseInt(process.env.REACT_APP_MAX_AMOUNT, 3000);
   const { t } = useTranslation();
   const navigate = useNavigate();
   const { selectedCausesList } = useAppSelector(causesState);
   const { donationAmount } = useAppSelector(newFlow1State);
   const { creatorPhoto, textLocizeParam } = useAppSelector(creatorQuotesState);
   const dispatch = useAppDispatch();

   const [amount, setAmount] = useState(donationAmount);

   const changeAmountButtonClick = (increase: boolean) => {
      let multi = Math.floor(amount / changeAmount);
      // eslint-disable-next-line no-plusplus
      increase ? multi++ : multi--;
      let finalValue = multi * changeAmount;
      if (finalValue < minAmount) {
         finalValue = minAmount;
      } else if (finalValue > maxAmount) {
         finalValue = maxAmount;
      }
      setAmount(finalValue);
   };

   const handleNext = () => {
      dispatch(setDonationAmount(amount));
      analytics?.track('new_flow1_set_donation_amount', {
         causes: `[${selectedCausesList.map((cause) => cause.id).join()}]`,
         value: amount,
         currency: 'USD',
      });
      navigate(routes().newflow1.planbuildingAnimation);
   };

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            px: 2.5,
            pb: { xs: '110px', sm: '20px' },
         }}
      >
         <Box sx={{ mt: { xs: 3, sm: 5 }, mb: 2.5 }}>
            <Typography variant='custom-header'>{t('getStarted.subscribtion.title')}</Typography>
         </Box>

         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS,
               width: '100%',
               px: '20px',
               pt: '40px',
               pb: '30px',
               backgroundColor: '#FFFFFF',
               borderRadius: '8px',
            }}
         >
            <Box
               sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
               }}
            >
               <IconWrapper
                  onClick={() => {
                     analytics?.track('new_flow1_amount_change', {
                        action: 'minus',
                        amount: amount - changeAmount,
                     });
                     changeAmountButtonClick(false);
                  }}
                  disabled={amount === minAmount}
               >
                  <DynamicGoogleIcon iconColor='#FFFFFF' iconName='remove' />
               </IconWrapper>
               <AmountBox>
                  <Typography component='h6' className='SubscriptionAmount'>
                     ${amount}
                  </Typography>
               </AmountBox>
               <IconWrapper
                  onClick={() => {
                     analytics?.track('new_flow1_amount_change', {
                        action: 'plus',
                        amount: amount + changeAmount,
                     });

                     changeAmountButtonClick(true);
                  }}
                  disabled={amount === maxAmount}
               >
                  <DynamicGoogleIcon iconColor='#FFFFFF' iconName='add' />
               </IconWrapper>
            </Box>

            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '6px',
               }}
            >
               <DynamicGoogleIcon iconColor='#999999' iconName='contact_support' />
               <Box>
                  <Typography>{t('getStarted.subscribtion.disclaimer')}</Typography>
               </Box>
            </Box>
         </Box>
         {creatorPhoto &&
            (textLocizeParam ? (
               <CreatorQuote
                  creatorQuote={
                     <Trans
                        i18nKey={`getStarted.subscribtion.creatorQuote_${textLocizeParam}`}
                        components={{
                           1: <b>, 2: </b>,
                        }}
                     />
                  }
                  creatorPhoto={creatorPhoto}
               />
            ) : (
               <CreatorQuote creatorQuote={t('getStarted.subscribtion.creatorQuote')} creatorPhoto={creatorPhoto} />
            ))}
         <FixedGradientContainer>
            <Button
               sx={{ width: '100%', backgroundColor: '#F55D66 !important' }}
               onClick={handleNext}
               variant='primaryBtn'
            >
               <Typography>{t('getStarted.subscribtion.CTA')}</Typography>
            </Button>
         </FixedGradientContainer>
      </Box>
   );
};

export default NewFlowSubscribtionPage;
