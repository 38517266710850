/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import type { RootState } from 'store';
import { routes } from 'routes/routes';
import {
   getStripePayment,
   registerUser,
   loginUser,
   getUserByAccessToken,
   loginWithGoogle,
   getStripeFromProfile,
   getVerificationCode,
   updatePasswordWithCode,
   registerUserAnonymous,
   loginWithGoogleAnonymous,
   getUserBySlug,
   signInWithMagicLink,
   validateMagicLinkCode,
   createStripeSubscription,
   loginWithApple,
   createSession,
   loginWithReturnUserEmail,
} from './auth.services';
import { initialState } from './auth.state';
import type {
   LoggedUserTypes,
   PaymentData,
   LoggedUserDataTypes,
   AppleNameFormat,
   TokensTypes,
   SendMagicLinkPayloadType,
} from './types';

const updateAnalytics = (loggedUser: LoggedUserTypes | { user: LoggedUserDataTypes }, referralCode?: string) => {
   const loginType = loggedUser.user.settings.registeredWith ? loggedUser.user.settings.registeredWith : 'email';
   const actionType = loggedUser.user.newUser ? 'sign_up' : 'login';

   analytics?.identify(loggedUser.user.id.toString(), {
      email: loggedUser.user.email,
      userId: loggedUser.user.id.toString(),
      ref_id: referralCode,
      firstName: loggedUser.user.firstName,
   });

   if (window.smartlook) {
      window.smartlook('identify', loggedUser.user.id, {
         email: loggedUser.user.email,
         firstName: loggedUser.user.firstName,
      });
   }
   analytics?.track(actionType, {
      method: loginType,
      userId: loggedUser.user.id,
      email: loggedUser.user.email,
   });
};

export const saveUserNewPassword = createAsyncThunk(
   'auth/saveNewUserPassword',
   async ({ email, code, password }: { email: string; password: string; code: string }, { rejectWithValue }) => {
      try {
         const response = await updatePasswordWithCode({ email, password, code });
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const sendNewUserWithCode = createAsyncThunk(
   'auth/sendNewUserWithCode',
   async (_, { rejectWithValue, dispatch, getState }) => {
      const { signupData, loggedUserData } = (getState() as RootState).auth;
      const { email, password, codeFromEmail } = signupData;
      const loggedEmail = loggedUserData?.email || '';
      try {
         const response = await updatePasswordWithCode({
            email: email || loggedEmail,
            password,
            code: codeFromEmail,
         });
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchVerificationCode = createAsyncThunk(
   'auth/fetchVerificationCode',
   async (_, { rejectWithValue, getState }) => {
      const { signupData, loggedUserData } = (getState() as RootState).auth;
      const { email } = signupData;
      const loggedUserEmail = loggedUserData?.email || '';
      try {
         const response = await getVerificationCode(email || loggedUserEmail);
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const getStripe = createAsyncThunk(
   'auth/getStripe',
   async (data: PaymentData, { rejectWithValue, dispatch, getState }) => {
      const { money, serviceFee } = data;
      try {
         const response = await getStripePayment(money, serviceFee);
         const parsedData = JSON.parse(JSON.stringify(response.data));
         sessionStorage.setItem(parsedData.checkoutSessionId, JSON.stringify(parsedData.items));
         window.location.href = response.data.checkoutSessionUrl;
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const createSubscription = createAsyncThunk(
   'auth/createSubscription',
   async (data: PaymentData, { rejectWithValue, dispatch, getState }) => {
      const { money, serviceFee, promoCode } = data;
      try {
         const response = await createStripeSubscription(money, serviceFee, promoCode);
         const parsedData = JSON.parse(JSON.stringify(response.data));
         sessionStorage.setItem(parsedData.checkoutSessionId, JSON.stringify(parsedData.items));
         window.location.href = `${routes().creatorFlow.base}/${routes().creatorFlow.gratitude}`;
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const getStripeFromUserProfile = createAsyncThunk(
   'auth/getStripeFromUserProfile',
   async (_, { rejectWithValue }) => {
      try {
         const response = await getStripeFromProfile();
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const sendSignupData = createAsyncThunk('auth/sendSignupData', async (_, { rejectWithValue, getState }) => {
   const { signupData } = (getState() as RootState).auth;
   try {
      const response = await registerUser(signupData);
      return response.data;
   } catch (error) {
      if (error instanceof Error) {
         return rejectWithValue(error);
      }
      return error;
   }
});

export const sendSignupDataForAnonymous = createAsyncThunk(
   'auth/sendSignupDataAnonymous',
   async (_, { rejectWithValue, dispatch, getState }) => {
      const { signupData } = (getState() as RootState).auth;
      const { charityList, selectedCausesList } = (getState() as RootState).causes;
      try {
         const response = await registerUserAnonymous(signupData, selectedCausesList, charityList);
         dispatch(saveSignedupUser(response.data));
         localStorage.removeItem('anonymousUser');
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const createAnonymousSignup = createAsyncThunk(
   'auth/createAnonymousSignup',
   async (_, { rejectWithValue, dispatch, getState }) => {
      // Check for Refferal code
      try {
         const { signupData } = (getState() as RootState).auth;
         const { charityList, selectedCausesList } = (getState() as RootState).causes;
         const { dynamicData } = (getState() as RootState).newFlow1;
         const { dynamic } = signupData.details;
         const hasDynamicData = Object.values(dynamicData).length > 0;
         const hasDynamic = dynamic && Object.values(dynamic).length > 0;

         const newSignUpData = hasDynamicData
            ? {
                 ...signupData,
                 details: {
                    ...signupData.details,
                    dynamic: hasDynamic ? { ...dynamic, ...dynamicData } : dynamicData,
                 },
              }
            : signupData;
         const response = await registerUserAnonymous(newSignUpData, selectedCausesList, charityList);
         localStorage.setItem('anonymousUser', 'true');
         dispatch(saveSignedupUser(response.data));
         dispatch(saveUserData({ userData: response.data.user }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchLoggedUser = createAsyncThunk(
   'auth/fetchLoggedUser',
   async (_, { rejectWithValue, dispatch, getState }) => {
      const { signupData, loggedUserData } = (getState() as RootState).auth;
      const { email, password } = signupData;
      const loggedEmail = loggedUserData?.email || '';
      try {
         const response = await loginUser({ email: email || loggedEmail, password });
         dispatch(saveSignedupUser(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const loginUserWithGoogle = createAsyncThunk(
   'auth/loginUserWithGoogle',
   async (token: string, { rejectWithValue, dispatch }) => {
      try {
         const response = await loginWithGoogle(token);
         dispatch(saveSignedupUser(response.data));
         dispatch(saveUserData({ userData: response.data.user }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const loginUserWithApple = createAsyncThunk(
   'auth/loginUserWithApple',
   async (
      { token, fullName }: { token: string; fullName: AppleNameFormat },
      { rejectWithValue, getState, dispatch }
   ) => {
      try {
         const { signupData } = (getState() as RootState).auth;
         const { charityList, selectedCausesList } = (getState() as RootState).causes;
         const { dynamicData } = (getState() as RootState).newFlow1;
         const { dynamic } = signupData.details;
         const hasDynamicData = Object.values(dynamicData).length > 0;
         const hasDynamic = dynamic && Object.values(dynamic).length > 0;

         const newSignUpData = hasDynamicData
            ? {
                 ...signupData,
                 details: {
                    ...signupData.details,
                    dynamic: hasDynamic ? { ...dynamic, ...dynamicData } : dynamicData,
                 },
              }
            : signupData;
         const response = await loginWithApple(token, fullName, newSignUpData, selectedCausesList, charityList);
         dispatch(saveSignedupUser(response.data));
         dispatch(saveUserData({ userData: response.data.user }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const loginReturnUser = createAsyncThunk(
   'auth/loginReturnUser',
   async ({ email }: { email: string }, { rejectWithValue, getState, dispatch }) => {
      try {
         const response = await loginWithReturnUserEmail({ email });
         dispatch(saveSignedupUser(response.data));
         dispatch(saveUserData({ userData: response.data.user }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const loginUserWithGoogleForAnonymous = createAsyncThunk(
   'auth/loginUserWithGoogleForAnonymous',
   async (token: string, { rejectWithValue, getState, dispatch }) => {
      try {
         const { signupData } = (getState() as RootState).auth;
         const { charityList, selectedCausesList } = (getState() as RootState).causes;
         const { dynamicData } = (getState() as RootState).newFlow1;
         const { dynamic } = signupData.details;
         const hasDynamicData = Object.values(dynamicData).length > 0;
         const hasDynamic = dynamic && Object.values(dynamic).length > 0;

         const newSignUpData = hasDynamicData
            ? {
                 ...signupData,
                 details: {
                    ...signupData.details,
                    dynamic: hasDynamic ? { ...dynamic, ...dynamicData } : dynamicData,
                 },
              }
            : signupData;
         const response = await loginWithGoogleAnonymous(token, newSignUpData, selectedCausesList, charityList);
         dispatch(saveSignedupUser(response.data));
         dispatch(saveUserData({ userData: response.data.user }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchUserByAccessToken = createAsyncThunk(
   'auth/fetchUserByAccessToken',
   async (_, { rejectWithValue, dispatch }) => {
      try {
         const response = await getUserByAccessToken();
         dispatch(saveUserData({ userData: response.data }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchUserBySlug = createAsyncThunk(
   'auth/fetchUserBySlug',
   async (slug: string, { rejectWithValue, dispatch }) => {
      try {
         const response = await getUserBySlug(slug);
         // dispatch(saveUserData(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const sendMagicLink = createAsyncThunk(
   'auth/sendMagicLink',
   async (flow: string | undefined, { rejectWithValue, dispatch, getState }) => {
      try {
         const { signupData } = (getState() as RootState).auth;
         const { email } = signupData;
         const response = await signInWithMagicLink({ email }, flow);
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const createUserSession = createAsyncThunk(
   'auth/createUserSession',
   async (_, { rejectWithValue, dispatch, getState }) => {
      try {
         // get the utm from session storage
         const utm = sessionStorage.getItem('utm') ? JSON.parse(sessionStorage.getItem('utm') as string) : {};
         const response = await createSession(utm);
         dispatch(addSessionId(response.data.session));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const checkMagicLink = createAsyncThunk(
   'auth/checkMagicLink',
   async (code: string, { rejectWithValue, dispatch, getState }) => {
      try {
         const response = await validateMagicLinkCode(code);
         dispatch(saveSignedupUser(response.data));
         dispatch(saveUserData({ userData: response.data.user }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const authSlice = createSlice({
   name: 'authSlice',
   initialState,
   reducers: {
      addEmail(state, action: PayloadAction<string>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               email: action.payload,
            },
         };
      },
      addPassword(state, action: PayloadAction<string>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               password: action.payload,
            },
         };
      },

      addCodeFromEmail(state, action: PayloadAction<string>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               codeFromEmail: action.payload,
            },
         };
      },

      addName(state, action: PayloadAction<string>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               firstName: action.payload,
            },
         };
      },
      addCreatorCoupon(state, action: PayloadAction<string>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               details: {
                  ...state.signupData.details,
                  creatorCoupon: action.payload,
               },
            },
         };
      },
      updateHowMuchPosts(state, action: PayloadAction<number>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               details: {
                  ...state.signupData.details,
                  howMuchPosts: action.payload,
               },
            },
         };
      },
      updateFollowersCount(state, action: PayloadAction<number>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               details: {
                  ...state.signupData.details,
                  followersCount: action.payload,
               },
            },
         };
      },
      updateViewsCount(state, action: PayloadAction<number>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               details: {
                  ...state.signupData.details,
                  viewsCount: action.payload,
               },
            },
         };
      },
      addSelectedNetworks(state, action: PayloadAction<string[]>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               details: {
                  ...state.signupData.details,
                  socialNetworks: action.payload,
               },
            },
         };
      },

      addGender(state, action: PayloadAction<number>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               details: {
                  ...state.signupData.details,
                  gender: action.payload,
               },
            },
         };
      },

      addRefCode(state, action: PayloadAction<string>) {
         return {
            ...state,
            signupData: {
               ...state.signupData,
               referralCode: action.payload,
            },
         };
      },

      addSessionId(state, action: PayloadAction<string>) {
         sessionStorage.removeItem('utm');
         return {
            ...state,
            signupData: {
               ...state.signupData,
               sessionId: action.payload,
            },
         };
      },

      addTokens(state, action: PayloadAction<TokensTypes>) {
         const { accessToken, refreshToken } = action.payload;
         localStorage.setItem('accessToken', accessToken);
         localStorage.setItem('refreshToken', refreshToken);
         sessionStorage.setItem('profileWasActivated', 'true');
         if (state.loggedUser) {
            state.loggedUser = { ...state.loggedUser, accessToken, refreshToken };
         }
      },

      saveSignedupUser(state, action: PayloadAction<LoggedUserTypes>) {
         const { accessToken, refreshToken } = action.payload;
         localStorage.setItem('accessToken', accessToken);
         localStorage.setItem('refreshToken', refreshToken);
         sessionStorage.setItem('profileWasActivated', 'true');
         updateAnalytics(action.payload, state.signupData.referralCode);
         return {
            ...state,
            loggedUser: action.payload,
         };
      },

      saveUserData(state, action: PayloadAction<{ userData: LoggedUserDataTypes; updateAnalytics?: boolean }>) {
         if (action.payload.updateAnalytics === true) {
            updateAnalytics({ user: action.payload.userData }, state.signupData.referralCode);
         }
         return {
            ...state,
            loggedUserData: action.payload.userData,
         };
      },
      clearUserData(state) {
         return {
            ...state,
            loggedUserData: null,
            loggedUser: null,
         };
      },
      updateAccessToken(state, action: PayloadAction<string>) {
         return {
            ...state,
            accessToken: action.payload,
         };
      },
   },

   extraReducers(builder) {
      builder
         .addCase(sendSignupData.pending, (state) => {
            state.signupDataError = null;
            state.signupDataLoading = true;
            state.signupDataStatus = 'pending';
         })
         .addCase(sendSignupData.fulfilled, (state, action) => {
            state.signupDataError = null;
            state.signupDataLoading = false;
            state.signupDataStatus = 'resolved';
            state.loggedUser = action.payload;
         })
         .addCase(sendSignupData.rejected, (state, action) => {
            const { response } = action.payload as AxiosError<any, unknown>;
            state.signupDataError = response?.data ?? null;
            state.signupDataStatus = 'rejected';
         })
         .addCase(fetchVerificationCode.pending, (state) => {
            state.fetchVerCodeError = null;
            state.fetchVerCodeLoader = true;
            state.fetchVerCodeStatus = 'pending';
         })
         .addCase(fetchVerificationCode.fulfilled, (state) => {
            state.fetchVerCodeError = null;
            state.fetchVerCodeLoader = false;
            state.fetchVerCodeStatus = 'resolved';
         })
         .addCase(fetchVerificationCode.rejected, (state, action) => {
            const { response } = action.payload as AxiosError<any, unknown>;
            state.fetchVerCodeError = response?.data ?? null;
            state.fetchVerCodeStatus = 'rejected';
         })
         .addCase(sendNewUserWithCode.pending, (state) => {
            state.sendNewUserWithCodeError = null;
            state.sendNewUserWithCodeLoader = true;
            state.sendNewUserWithCodeStatus = 'pending';
         })
         .addCase(sendNewUserWithCode.fulfilled, (state) => {
            state.sendNewUserWithCodeError = null;
            state.sendNewUserWithCodeLoader = false;
            state.sendNewUserWithCodeStatus = 'resolved';
         })
         .addCase(sendNewUserWithCode.rejected, (state, action) => {
            const { response } = action.payload as AxiosError<any, unknown>;
            state.sendNewUserWithCodeError = response?.data ?? null;
            state.sendNewUserWithCodeStatus = 'rejected';
         })
         .addCase(fetchLoggedUser.pending, (state) => {
            state.loggedUserError = null;
            state.loggedUserLoader = true;
            state.loggedUserStatus = 'pending';
         })
         .addCase(fetchLoggedUser.fulfilled, (state, action) => {
            state.loggedUserError = null;
            state.loggedUserLoader = false;
            state.loggedUserStatus = 'resolved';
            state.loggedUser = action.payload;
            const { accessToken, refreshToken } = action.payload;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
         })
         .addCase(fetchLoggedUser.rejected, (state, action) => {
            const { response } = action.payload as AxiosError<any, unknown>;
            state.loggedUserError = response?.data ?? null;
            state.loggedUserStatus = 'rejected';
         })
         .addCase(saveUserNewPassword.pending, (state) => {
            state.saveNewUserPasswordError = null;
            state.saveNewUserPasswordLoader = true;
            state.saveNewUserPasswordStatus = 'pending';
         })
         .addCase(saveUserNewPassword.fulfilled, (state) => {
            state.saveNewUserPasswordError = null;
            state.saveNewUserPasswordLoader = false;
            state.saveNewUserPasswordStatus = 'resolved';
         })
         .addCase(saveUserNewPassword.rejected, (state, action) => {
            const { response } = action.payload as AxiosError<any, unknown>;
            state.saveNewUserPasswordError = response?.data ?? null;
            state.saveNewUserPasswordStatus = 'rejected';
         });
   },
});

export const {
   addEmail,
   addPassword,
   addName,
   addSelectedNetworks,
   addCreatorCoupon,
   updateViewsCount,
   updateHowMuchPosts,
   updateFollowersCount,
   addGender,
   saveSignedupUser,
   addTokens,
   updateAccessToken,
   saveUserData,
   addRefCode,
   addCodeFromEmail,
   clearUserData,
   addSessionId,
} = authSlice.actions;

export const authState = (state: RootState) => state.auth;
