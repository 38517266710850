export const TITLE_STYLE = {
   fontFamily: 'Figtree',
   fontWeight: 700,
   fontSize: '32px',
   lineHeight: '38px',
   justifyContent: 'center',
};

export const NAV_BG_COLOR = '#EFCAC9';
export const MAIN_CONTAINER_WIDTH = '390px';
export const MAIN_ACTION_COLOR = '#F55D66';
export const NEW_FLOW1_MAX_CONTENT_WIDTH = '390px';
export const NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS = '430px';
