import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

import { Trans, useTranslation } from 'react-i18next';
import { PageMainLabel, ContentWrapper, BottomContentBox, RegLinkText } from 'components/common';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isValidEmail } from 'utils/utils';
import { addEmail, authState, sendMagicLink } from 'store/auth/auth.slice';
import { commonState } from 'store/common/common.slice';

export const SignUpPage = () => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const { signupData } = useAppSelector(authState);
   const { project } = useAppSelector(commonState);
   const [email, setEmail] = useState('');
   const [error, setError] = useState(false);
   const [submitting, setSubmitting] = useState(false);

   const { t } = useTranslation();

   const mainLabelText = t('loginEmail.email.heading');
   const isButtonDisabled = submitting || error || email.length === 0;
   const inputPlaceholder = t('emailInputPlaceholder');
   const buttonText = 'Next';
   const errorMessage = t('saveYourPortfolio.emailErrorMessage');

   const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = e.target.value;
      if (error) {
         setError(false);
      }
      setEmail(newEmail);
   };

   const handleNext = async (userMail: string) => {
      if (submitting) return;
      const isInvalidEmail = !isValidEmail(userMail);
      if (isInvalidEmail) {
         console.log('Email is not valid');
         setError(true);
         return;
      }
      setSubmitting(true);
      dispatch(addEmail(userMail));
      const res = await dispatch(sendMagicLink());
      if (res?.payload?.userFound) {
         if (res?.payload?.autoLoginCode) {
            const routePosition = res.payload.autoLoginCode.indexOf('/ml?code');
            const autoLoginRoute =
               routePosition > 0 ? res.payload.autoLoginCode.substring(routePosition) : routes().checkYourEmail;
            navigate(autoLoginRoute);
         } else navigate(routes().checkYourEmail);
      } else {
         navigate(routes().name);
      }
   };

   useEffect(() => {
      if (signupData.email) {
         setEmail(signupData.email);
      }
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: '20px', sm: 0 },
            mb: { xs: '52px', sm: 0 },
         }}
      >
         <ContentWrapper
            sx={{
               maxWidth: '390px',
               justifyContent: 'start',
            }}
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' mb='30px'>
               <PageMainLabel mb='20px'>{mainLabelText}</PageMainLabel>

               <TextField
                  fullWidth
                  required
                  className='CommonInput'
                  value={email}
                  type='text'
                  ref={inputRef}
                  onKeyDown={(event: React.KeyboardEvent) => {
                     if (event.key === 'Enter') {
                        handleNext(email);
                     }
                  }}
                  onFocus={() => setError(false)}
                  onChange={handleInputOnChange}
                  error={error}
                  placeholder={inputPlaceholder}
               />
               {error && (
                  <Typography sx={{ mt: '4px', textAlign: { sm: 'center' }, color: '#F55D66' }}>
                     {errorMessage}
                  </Typography>
               )}
            </Box>

            <BottomContentBox isFocused={false} focusedValue='calc(-100% + 642px)'>
               <Button disabled={isButtonDisabled} onClick={() => handleNext(email)} variant='primaryBtn'>
                  <Typography>{buttonText}</Typography>
               </Button>
               {project === 'helene' ? (
                  <RegLinkText sx={{ mt: { xs: '28px', sm: '28px' } }}>
                     {t('clickTC')}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('terms')}</span>
                     </Link>{' '}
                     and{' '}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('privacyPolicy')}</span>
                     </Link>
                     <div>
                        <Trans
                           i18nKey='tcSupport'
                           // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, react/self-closing-comp
                           components={{ mailLink: <a href='mailto:support@proisrael.co'></a> }}
                        />
                     </div>
                  </RegLinkText>
               ) : (
                  ''
               )}
            </BottomContentBox>
         </ContentWrapper>
      </Box>
   );
};
