import { Box, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { CharityTypes } from 'store/causes/types';

interface IImageCarouselProps {
   size?: 'small' | 'normal';
   amount: number;
   charities: CharityTypes[];
}

const ImpactCarousel: React.FC<IImageCarouselProps> = ({ amount, charities, size = 'normal' }) => {
   const isBigScreen = useMediaQuery('(min-width:1040px)');
   const getCalculatedAmount = (indicatorPrice: number) =>
      `${Math.round((amount * 12) / indicatorPrice)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

   const alignItems = size === 'small' || !isBigScreen ? 'start' : 'center';

   return (
      <Box
         sx={{
            width: size === 'small' ? '100%' : '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems,
            overflowX: 'scroll',
            scrollbarWidth: 'none', // For Firefox
            '&::-webkit-scrollbar': {
               display: 'none', // For Chrome, Safari, and Edge
            },
            paddingBottom: '10px',
            px: size === 'small' ? 2.5 : undefined,
         }}
      >
         <ImageList
            cols={3}
            gap={10}
            sx={{
               width: size !== 'small' ? 1040 : 500,
               height: size !== 'small' ? '170px' : '90px',
               display: 'flex',
               flexWrap: 'nowrap',
               scrollbarWidth: 'none', // For Firefox
               '&::-webkit-scrollbar': { display: 'none' }, // For Webkit
            }}
         >
            {charities.map((item) => (
               <ImageListItem
                  sx={{
                     minWidth: size !== 'small' ? 340 : 160,
                     height: size !== 'small' ? '170px' : '90px',
                     borderRadius: '6px',
                     overflow: 'hidden',
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     backgroundImage: `url(${item.indicatorImage})`,
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'space-between',
                     color: 'white',
                  }}
                  key={item.id}
               >
                  <Box>
                     {size !== 'small' && (
                        <Box
                           sx={{
                              marginLeft: '10px',
                              marginTop: '10px',
                              borderRadius: '2px',
                              backgroundColor: '#EFCAC9',
                              py: '2px',
                              px: '8px',
                              width: 'fit-content',
                           }}
                        >
                           <Typography
                              sx={{
                                 fontFamily: 'Figtree',
                                 fontSize: '12px',
                                 lineHeight: '18px',
                                 fontWeight: 700,
                                 textShadow: 'none',
                                 color: '#333333',
                              }}
                           >
                              {item.causes[0]?.title}
                           </Typography>
                        </Box>
                     )}
                  </Box>

                  <Box
                     sx={{
                        px: '10px',
                        paddingBottom: '10px',
                        height: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
                     }}
                  >
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           color: '#ffffff',
                           fontSize: size === 'small' ? '18px' : '24px',
                           fontWeight: 700,
                           lineHeight: size === 'small' ? '22px' : '29px',
                        }}
                     >
                        {getCalculatedAmount(item.indicatorPrice)}
                     </Typography>
                     <Typography
                        sx={{
                           fontFamily: 'Figtree',
                           fontWeight: 700,
                           color: '#ffffff',
                           fontSize: size === 'small' ? '12px' : '18px',
                           lineHeight: size === 'small' ? '14px' : '22px',
                        }}
                     >
                        {item.indicatorTitle}
                     </Typography>
                  </Box>
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );
};

export default ImpactCarousel;
