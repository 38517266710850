import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';
import { LoggedUserTypes } from 'store/auth/types';

export type CustomRoutesType = {
   newUserRoute?: string;
};

const useRedirectAfterLogin = () => {
   const navigate = useNavigate();

   const navigateUser = (user: LoggedUserTypes['user'], customRoutes?: CustomRoutesType) => {
      if (user.subscription) {
         navigate(routes().myProfile);
      } else if (user.details?.creatorCoupon) {
         navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
      } else if (!user.firstName || user.firstName === user.email) {
         navigate(routes().name, { state: { updateName: true, customRoute: customRoutes?.newUserRoute } });
      } else {
         // navigate(routes().marketingSurvey); Not activated yet
         navigate(customRoutes?.newUserRoute ?? routes().causes);
      }
   };

   return { navigateUser };
};

export default useRedirectAfterLogin;
