import api from 'api';
import { CauseTypes } from 'store/causes/types';

type CreatorDataType = {
   data?: {
      referalCode: string;
      name: string;
      picture: string;
      causes: CauseTypes[];
   };
};

export const getCreatorData = ({ refId }: { refId: string }): Promise<CreatorDataType> => {
   return api.get(`/v1/publicProfile/ref_id/${refId}`);
};
