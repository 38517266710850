import { useMediaQuery } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';

interface ILogo2Props {
   isInContentPart?: boolean;
}

const Logo2: React.FC<ILogo2Props> = ({ isInContentPart }) => {
   const location = useLocation();
   const isGetStartedFlow = location.pathname.includes(routes().newflow1.base);
   const isFinalAnimationScreen = location.pathname.includes(routes().newflow1.thankYou);
   const isMobile = useMediaQuery('(max-width:600px)');

   if (isGetStartedFlow && (!isFinalAnimationScreen || isInContentPart)) {
      return (
         <svg width={90} height={40} viewBox='0 0 90 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#a)'>
               <path
                  d='M6.574 40c-.226 0-.444-.066-.633-.188l-5.416-3.52a1.16 1.16 0 0 1-.514-.943L0 7.09c0-.552.392-1.029.928-1.136l6.355-1.287a1 1 0 0 1 .222-.022 1.2 1.2 0 0 1 .625.181l5.416 3.517c.33.214.53.576.53.972v3.202c1.256.063 2.413.395 3.445.987l.007-.008.337.219 4.285 2.78c1.937-4.67 7.143-8.256 12.097-8.256 1.716 0 3.316.41 4.758 1.216l.011-.014.352.221 4.04 2.622c1.546-4.404 6.171-7.66 10.993-7.66a9.9 9.9 0 0 1 2.924.435c.891.248 2.817 1.531 4.366 2.566.403.27.77.514 1.065.706C64.68 3.628 69.9.012 74.886.012c1.857 0 3.572.477 5.107 1.416l5.412 3.512c2.237 1.412 3.723 4.1 3.871 7.014.278 3.516-1.016 7.117-3.56 9.871-2.559 2.773-6.075 4.363-9.642 4.367-1.834 0-3.502-.477-4.962-1.42l-3.424-2.222v4.588c0 .55-.388 1.028-.928 1.135L49.55 31.76a1.2 1.2 0 0 1-.23.022c-.225 0-.443-.063-.632-.189l-3.146-2.04c-2.47 3.001-6.307 4.857-10.104 4.869-1.834 0-3.502-.477-4.962-1.42l-4.237-2.75c-1.154 3.23-3.516 5.896-6.396 7.194-1.387.625-2.814.954-4.119.954a7 7 0 0 1-1.911-.255 1.15 1.15 0 0 1-.792.584l-6.21 1.257a1.2 1.2 0 0 1-.23.022z'
                  fill='#252626'
               />
               <path
                  d='M20.483 21.928c.133-4.134-2.3-8.337-6.8-8.27-2.247-.008-4.499.809-6.166 2.314V5.797L1.168 7.084v28.25c1.283-.259 4.984-1.01 6.212-1.257v-1.128c2.58 1.505 5.908.603 8.215-1.024 3.15-2.23 4.932-6.141 4.884-10zM14.03 23.78c-.13 1.55-1.046 3.032-2.459 3.701-1.327.691-3.19.488-3.94-.935-1.62-3.01 1.256-7.583 4.79-6.319 1.358.566 1.753 2.14 1.609 3.553m27.852-5.963c-.185-11.033-13.606-9.876-18.06-2.174-3.646 5.708-1.665 14.393 6.059 14.367 6.422-.019 12.09-5.783 12-12.197zm12.885 7.088 6.355-1.287V12.652c.093-5.028-3.97-7.753-8.651-6.658-3.228.632-6.004 2.658-7.48 5.542q-1.087 2.12-1.087 4.6V27.1l6.36-1.287V14.85c-.026-1.483.813-2.729 2.244-3.014 1.357-.362 2.336.74 2.251 2.104l.004 10.966zM82.662 7.936c-1.538-10.023-14.23-7.75-18.12-.577-3.623 6.004-1.15 14.663 6.81 14.124 6.677-.367 12.212-6.892 11.314-13.547z'
                  fill='#fff'
               />
               <path
                  d='m24.963 17.775 3.524-4.248.89.81-3.523 4.252zm.052 4.888 8.544-10.311.895.813-8.548 10.308zM65.751 9.19l3.52-4.249.894.81-3.523 4.252zm.048 4.887L74.347 3.77l.895.81-8.549 10.307z'
                  fill='#252626'
               />
            </g>
            <defs>
               <clipPath id='a'>
                  <path fill='#fff' d='M0 0h89.309v40H0z' />
               </clipPath>
            </defs>
         </svg>
      );
   }
   if (isGetStartedFlow && isFinalAnimationScreen && isInContentPart) {
      return (
         <svg width='112' height='50' viewBox='0 0 112 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_13449_21152)'>
               <path
                  d='M8.21702 50.0018C7.93511 50.0018 7.66244 49.9186 7.42675 49.7661L0.656253 45.3664C0.272668 45.1215 0.0323505 44.6824 0.0138645 44.1879L0 8.86117C0 8.17257 0.489879 7.5764 1.16 7.44237L9.10435 5.83409C9.19216 5.81561 9.28921 5.80636 9.38164 5.80636C9.64969 5.80636 9.92236 5.88493 10.1627 6.03282L16.9332 10.4279C17.3445 10.6959 17.594 11.1488 17.594 11.6433V15.6455C19.1654 15.7241 20.6119 16.14 21.9013 16.8795L21.9105 16.8702L22.3311 17.1429L27.6874 20.6183C30.1091 14.7813 36.6161 10.2985 42.8089 10.2985C44.9533 10.2985 46.9544 10.8114 48.7568 11.8189L48.7707 11.8004L49.2097 12.0777L54.261 15.3544C56.1928 9.85018 61.9743 5.77863 68.0007 5.77863C69.2624 5.77863 70.4917 5.96349 71.6563 6.32397C72.7701 6.63361 75.1779 8.23727 77.1143 9.53129C77.6181 9.86866 78.0756 10.1737 78.4453 10.414C80.8485 4.53545 87.3741 0.015625 93.6085 0.015625C95.9284 0.015625 98.0728 0.611798 99.9907 1.78566L106.757 6.17608C109.553 7.9415 111.41 11.3013 111.595 14.9431C111.942 19.3381 110.324 23.8395 107.145 27.2825C103.947 30.7486 99.5517 32.7358 95.0919 32.7405C92.7997 32.7405 90.7154 32.1443 88.8899 30.9658L84.6104 28.1883V33.9236C84.6104 34.6122 84.1251 35.2083 83.4504 35.3424L61.9373 39.7004C61.8403 39.7189 61.7478 39.7282 61.6508 39.7282C61.3689 39.7282 61.0962 39.6496 60.8605 39.4925L56.9276 36.9414C53.8405 40.6941 49.0433 43.014 44.2971 43.0279C42.0048 43.0279 39.9205 42.4317 38.095 41.2533L32.7988 37.8149C31.3569 41.8541 28.4037 45.1862 24.8036 46.8083C23.0705 47.5893 21.2866 48.0006 19.6552 48.0006C18.8187 48.0006 18.0192 47.8944 17.2659 47.6818C17.0672 48.0515 16.7113 48.3242 16.2769 48.412L8.5128 49.9833C8.42037 50.0018 8.32332 50.011 8.22627 50.011L8.21702 50.0018Z'
                  fill='#252626'
               />
               <path
                  d='M25.6027 27.4097C25.769 22.2429 22.7281 16.9882 17.1037 17.0714C14.2939 17.0622 11.4794 18.0835 9.39507 19.9645V7.24609L1.45996 8.85438V44.1673C3.06362 43.8438 7.68974 42.9056 9.22408 42.5959V41.1864C12.4499 43.0673 16.6092 41.9397 19.4931 39.9062C23.4306 37.1195 25.6581 32.2299 25.5981 27.4051L25.6027 27.4097ZM17.5382 29.7251C17.3764 31.6615 16.2303 33.5147 14.4649 34.3512C12.8057 35.2154 10.4765 34.9612 9.53834 33.182C7.51412 29.4201 11.1097 23.7033 15.5278 25.2838C17.2239 25.9909 17.7184 27.9597 17.5382 29.7251Z'
                  fill='white'
               />
               <path
                  d='M52.3526 22.2716C52.1215 8.48108 35.3455 9.92761 29.7766 19.5542C25.2198 26.6898 27.6969 37.5457 37.3512 37.5133C45.3787 37.4902 52.4635 30.2853 52.3526 22.267V22.2716Z'
                  fill='white'
               />
               <path
                  d='M68.4578 31.1302L76.4022 29.5219V15.8145C76.5177 9.5293 71.4387 6.12325 65.5879 7.49122C61.5533 8.28149 58.0826 10.8141 56.2386 14.4188C55.3328 16.1843 54.8799 18.1022 54.8799 20.168V33.8754L62.8289 32.2671V18.5597C62.7965 16.7065 63.8456 15.149 65.6341 14.7932C67.3302 14.3403 68.5549 15.7175 68.4486 17.4228L68.4532 31.1302H68.4578Z'
                  fill='white'
               />
               <path
                  d='M103.328 9.92091C101.405 -2.60798 85.5397 0.234245 80.6779 9.19995C76.1488 16.7053 79.2406 27.5288 89.1907 26.8541C97.5371 26.3966 104.455 18.2396 103.332 9.92091H103.328Z'
                  fill='white'
               />
               <path
                  d='M31.2041 22.2203L35.6084 16.9102L36.7222 17.9223L32.3179 23.237L31.2041 22.2203Z'
                  fill='#252626'
               />
               <path
                  d='M31.2686 28.3308L41.9488 15.4414L43.0672 16.4581L32.3823 29.3429L31.2686 28.3308Z'
                  fill='#252626'
               />
               <path
                  d='M82.1885 11.4898L86.5881 6.17969L87.7065 7.1918L83.3023 12.5065L82.1885 11.4898Z'
                  fill='#252626'
               />
               <path
                  d='M82.249 17.5996L92.9339 4.71484L94.0523 5.72695L83.3674 18.6117L82.249 17.5996Z'
                  fill='#252626'
               />
            </g>
            <defs>
               <clipPath id='clip0_13449_21152'>
                  <rect width='111.637' height='50' fill='white' />
               </clipPath>
            </defs>
         </svg>
      );
   }

   return isMobile ? (
      <svg width='112' height='50' viewBox='0 0 112 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <g clipPath='url(#clip0_13449_21152)'>
            <path
               d='M8.21702 50.0018C7.93511 50.0018 7.66244 49.9186 7.42675 49.7661L0.656253 45.3664C0.272668 45.1215 0.0323505 44.6824 0.0138645 44.1879L0 8.86117C0 8.17257 0.489879 7.5764 1.16 7.44237L9.10435 5.83409C9.19216 5.81561 9.28921 5.80636 9.38164 5.80636C9.64969 5.80636 9.92236 5.88493 10.1627 6.03282L16.9332 10.4279C17.3445 10.6959 17.594 11.1488 17.594 11.6433V15.6455C19.1654 15.7241 20.6119 16.14 21.9013 16.8795L21.9105 16.8702L22.3311 17.1429L27.6874 20.6183C30.1091 14.7813 36.6161 10.2985 42.8089 10.2985C44.9533 10.2985 46.9544 10.8114 48.7568 11.8189L48.7707 11.8004L49.2097 12.0777L54.261 15.3544C56.1928 9.85018 61.9743 5.77863 68.0007 5.77863C69.2624 5.77863 70.4917 5.96349 71.6563 6.32397C72.7701 6.63361 75.1779 8.23727 77.1143 9.53129C77.6181 9.86866 78.0756 10.1737 78.4453 10.414C80.8485 4.53545 87.3741 0.015625 93.6085 0.015625C95.9284 0.015625 98.0728 0.611798 99.9907 1.78566L106.757 6.17608C109.553 7.9415 111.41 11.3013 111.595 14.9431C111.942 19.3381 110.324 23.8395 107.145 27.2825C103.947 30.7486 99.5517 32.7358 95.0919 32.7405C92.7997 32.7405 90.7154 32.1443 88.8899 30.9658L84.6104 28.1883V33.9236C84.6104 34.6122 84.1251 35.2083 83.4504 35.3424L61.9373 39.7004C61.8403 39.7189 61.7478 39.7282 61.6508 39.7282C61.3689 39.7282 61.0962 39.6496 60.8605 39.4925L56.9276 36.9414C53.8405 40.6941 49.0433 43.014 44.2971 43.0279C42.0048 43.0279 39.9205 42.4317 38.095 41.2533L32.7988 37.8149C31.3569 41.8541 28.4037 45.1862 24.8036 46.8083C23.0705 47.5893 21.2866 48.0006 19.6552 48.0006C18.8187 48.0006 18.0192 47.8944 17.2659 47.6818C17.0672 48.0515 16.7113 48.3242 16.2769 48.412L8.5128 49.9833C8.42037 50.0018 8.32332 50.011 8.22627 50.011L8.21702 50.0018Z'
               fill='#252626'
            />
            <path
               d='M25.6027 27.4097C25.769 22.2429 22.7281 16.9882 17.1037 17.0714C14.2939 17.0622 11.4794 18.0835 9.39507 19.9645V7.24609L1.45996 8.85438V44.1673C3.06362 43.8438 7.68974 42.9056 9.22408 42.5959V41.1864C12.4499 43.0673 16.6092 41.9397 19.4931 39.9062C23.4306 37.1195 25.6581 32.2299 25.5981 27.4051L25.6027 27.4097ZM17.5382 29.7251C17.3764 31.6615 16.2303 33.5147 14.4649 34.3512C12.8057 35.2154 10.4765 34.9612 9.53834 33.182C7.51412 29.4201 11.1097 23.7033 15.5278 25.2838C17.2239 25.9909 17.7184 27.9597 17.5382 29.7251Z'
               fill='white'
            />
            <path
               d='M52.3526 22.2716C52.1215 8.48108 35.3455 9.92761 29.7766 19.5542C25.2198 26.6898 27.6969 37.5457 37.3512 37.5133C45.3787 37.4902 52.4635 30.2853 52.3526 22.267V22.2716Z'
               fill='white'
            />
            <path
               d='M68.4578 31.1302L76.4022 29.5219V15.8145C76.5177 9.5293 71.4387 6.12325 65.5879 7.49122C61.5533 8.28149 58.0826 10.8141 56.2386 14.4188C55.3328 16.1843 54.8799 18.1022 54.8799 20.168V33.8754L62.8289 32.2671V18.5597C62.7965 16.7065 63.8456 15.149 65.6341 14.7932C67.3302 14.3403 68.5549 15.7175 68.4486 17.4228L68.4532 31.1302H68.4578Z'
               fill='white'
            />
            <path
               d='M103.328 9.92091C101.405 -2.60798 85.5397 0.234245 80.6779 9.19995C76.1488 16.7053 79.2406 27.5288 89.1907 26.8541C97.5371 26.3966 104.455 18.2396 103.332 9.92091H103.328Z'
               fill='white'
            />
            <path d='M31.2041 22.2203L35.6084 16.9102L36.7222 17.9223L32.3179 23.237L31.2041 22.2203Z' fill='#252626' />
            <path
               d='M31.2686 28.3308L41.9488 15.4414L43.0672 16.4581L32.3823 29.3429L31.2686 28.3308Z'
               fill='#252626'
            />
            <path d='M82.1885 11.4898L86.5881 6.17969L87.7065 7.1918L83.3023 12.5065L82.1885 11.4898Z' fill='#252626' />
            <path d='M82.249 17.5996L92.9339 4.71484L94.0523 5.72695L83.3674 18.6117L82.249 17.5996Z' fill='#252626' />
         </g>
         <defs>
            <clipPath id='clip0_13449_21152'>
               <rect width='111.637' height='50' fill='white' />
            </clipPath>
         </defs>
      </svg>
   ) : (
      <svg width='67' height='30' viewBox='0 0 67 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <g clipPath='url(#clip0_13680_18649)'>
            <path
               d='M4.93021 29.9995C4.76107 29.9995 4.59747 29.9496 4.45605 29.8581L0.393752 27.2183C0.163601 27.0713 0.0194103 26.8079 0.0083187 26.5112L0 5.31514C0 4.90198 0.293927 4.54428 0.695998 4.46386L5.46261 3.49889C5.5153 3.4878 5.57353 3.48226 5.62898 3.48226C5.78981 3.48226 5.95341 3.52939 6.0976 3.61813L10.1599 6.25515C10.4067 6.41598 10.5564 6.68773 10.5564 6.98443V9.38576C11.4992 9.4329 12.3671 9.68246 13.1408 10.1261L13.1463 10.1206L13.3986 10.2842L16.6124 12.3694C18.0654 8.86723 21.9697 6.17751 25.6854 6.17751C26.972 6.17751 28.1727 6.4853 29.2541 7.0898L29.2624 7.07871L29.5258 7.24508L32.5566 9.21107C33.7157 5.90854 37.1846 3.46562 40.8004 3.46562C41.5574 3.46562 42.295 3.57653 42.9938 3.79282C43.6621 3.9786 45.1067 4.9408 46.2686 5.71721C46.5708 5.91963 46.8454 6.10265 47.0672 6.24684C48.5091 2.71971 52.4244 0.0078125 56.1651 0.0078125C57.5571 0.0078125 58.8437 0.365516 59.9944 1.06983L64.054 3.70409C65.7316 4.76333 66.8463 6.77923 66.9572 8.96428C67.1652 11.6013 66.1946 14.3021 64.2869 16.3679C62.368 18.4476 59.731 19.6399 57.0552 19.6427C55.6798 19.6427 54.4292 19.285 53.3339 18.5779L50.7662 16.9114V20.3526C50.7662 20.7657 50.4751 21.1234 50.0702 21.2039L37.1624 23.8187C37.1042 23.8298 37.0487 23.8353 36.9905 23.8353C36.8213 23.8353 36.6577 23.7882 36.5163 23.6939L34.1566 22.1633C32.3043 24.4149 29.426 25.8069 26.5782 25.8152C25.2029 25.8152 23.9523 25.4575 22.857 24.7504L19.6793 22.6874C18.8141 25.1109 17.0422 27.1101 14.8821 28.0834C13.8423 28.552 12.772 28.7988 11.7931 28.7988C11.2912 28.7988 10.8115 28.735 10.3596 28.6075C10.2403 28.8293 10.0268 28.9929 9.76615 29.0456L5.10768 29.9884C5.05222 29.9995 4.99399 30.005 4.93576 30.005L4.93021 29.9995Z'
               fill='#252626'
            />
            <path
               d='M15.3616 16.4458C15.4614 13.3457 13.6369 10.1929 10.2622 10.2428C8.57632 10.2373 6.88762 10.8501 5.63705 11.9787V4.34766L0.875977 5.31263V26.5004C1.83817 26.3063 4.61384 25.7434 5.53445 25.5576V24.7118C7.46993 25.8404 9.96554 25.1638 11.6958 23.9437C14.0583 22.2717 15.3949 19.338 15.3588 16.443L15.3616 16.4458ZM10.5229 17.835C10.4258 18.9969 9.73816 20.1088 8.67892 20.6107C7.68344 21.1292 6.2859 20.9767 5.723 19.9092C4.50847 17.652 6.66579 14.222 9.31668 15.1703C10.3343 15.5945 10.631 16.7758 10.5229 17.835Z'
               fill='white'
            />
            <path
               d='M31.4112 13.3614C31.2725 5.08708 21.2069 5.955 17.8655 11.731C15.1315 16.0123 16.6177 22.5258 22.4103 22.5064C27.2269 22.4926 31.4777 18.1696 31.4112 13.3586V13.3614Z'
               fill='white'
            />
            <path
               d='M41.0745 18.6797L45.8411 17.7147V9.49029C45.9105 5.71914 42.863 3.67551 39.3525 4.49629C36.9318 4.97046 34.8494 6.49001 33.743 8.65287C33.1995 9.71212 32.9277 10.8629 32.9277 12.1024V20.3268L37.6971 19.3618V11.1374C37.6777 10.0255 38.3072 9.09099 39.3803 8.87747C40.3979 8.60573 41.1327 9.43205 41.069 10.4553L41.0717 18.6797H41.0745Z'
               fill='white'
            />
            <path
               d='M61.9961 5.95489C60.8426 -1.56244 51.3232 0.142891 48.4061 5.52232C45.6887 10.0255 47.5438 16.5196 53.5138 16.1148C58.5217 15.8403 62.6727 10.9461 61.9989 5.95489H61.9961Z'
               fill='white'
            />
            <path
               d='M18.7227 13.3345L21.3652 10.1484L22.0335 10.7557L19.3909 13.9445L18.7227 13.3345Z'
               fill='#252626'
            />
            <path d='M18.7617 16.9992L25.1699 9.26562L25.8409 9.87566L19.43 17.6065L18.7617 16.9992Z' fill='#252626' />
            <path d='M49.3135 6.89309L51.9533 3.70703L52.6243 4.3143L49.9817 7.50313L49.3135 6.89309Z' fill='#252626' />
            <path d='M49.3496 10.559L55.7605 2.82812L56.4316 3.43539L50.0207 11.1662L49.3496 10.559Z' fill='#252626' />
         </g>
         <defs>
            <clipPath id='clip0_13680_18649'>
               <rect width='66.9822' height='30' fill='white' />
            </clipPath>
         </defs>
      </svg>
   );
};
export default Logo2;
