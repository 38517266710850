import { useLocation } from 'react-router-dom';

interface IBackGround {
   mobile: string;
   desktop: string;
}

const useThemePage = (): { theme: string | null; themedBG: null | IBackGround } => {
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const theme = searchParams.get('theme');
   const themedBG: IBackGround | null = theme
      ? { mobile: `url(/images/${theme}-page-mobile-bg.png)`, desktop: `url(/images/${theme}-page-desktop-bg.png)` }
      : null;

   return { theme, themedBG };
};

export default useThemePage;
