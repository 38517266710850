import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FixedGradientContainer from 'components/newFlow1/FixedGradientContainer';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
   clearQuotesStore,
   creatorQuotesState,
   setCreatorCauses,
   setCreatorName,
   setCreatorPicture,
   setTextLocizeParam,
} from 'store/creatorQuotes/creatorQuotes.slice';
import { getCreatorData } from 'store/creatorQuotes/creatorQuotes.services';
import CreatorQuote from 'components/newFlow1/CreatorQuote';

const WelcomePage = () => {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useAppDispatch();
   const { creatorName, creatorPhoto, creatorCauses, textLocizeParam } = useAppSelector(creatorQuotesState);
   const searchParams = new URLSearchParams(location.search);
   const button = searchParams.get('button');
   const texts = searchParams.get('txt');
   const showLoginButton = button === 'login';

   const [hasCreatorData, setHasCreatorData] = useState(false);

   // check if user came with ref_id param
   const refId = searchParams.get('ref_id');

   const fillCreatorData = async (id: string) => {
      if (texts) dispatch(setTextLocizeParam(texts));
      const response = await getCreatorData({ refId: id });

      if (response?.data?.name && response?.data?.causes) {
         dispatch(setCreatorPicture(response.data.picture || '/images/getStartedFlow/creator-photo-placeholder.png'));
         dispatch(setCreatorName(response.data.name));
         dispatch(setCreatorCauses(response.data.causes));
         setHasCreatorData(true);
      }
   };

   useEffect(() => {
      const loadAppleScript = () => {
         return new Promise<void>((resolve, reject) => {
            if (document.getElementById('apple-js')) {
               resolve(); // Avoid loading multiple times
               return;
            }

            const script = document.createElement('script');
            script.id = 'apple-js';
            script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Apple JS SDK failed to load'));
            document.body.appendChild(script);
         });
      };

      const initializeAppleLogin = () => {
         if (window.AppleID) {
            const redirectURI = process.env.REACT_APP_BASE_URL ?? '';

            window.AppleID.auth.init({
               clientId: 'so.bono',
               scope: 'name email',
               redirectURI,
               usePopup: true,
            });
         }
      };

      loadAppleScript()
         .then(() => initializeAppleLogin())
         .catch((error) => console.error('Apple Login initialization failed:', error));

      document.title = t('siteTitleName');
   }, []);

   useEffect(() => {
      if (refId) {
         fillCreatorData(refId);
      } else if (creatorName || creatorPhoto || creatorCauses) dispatch(clearQuotesStore());
   }, [refId, texts]);

   const handleNext = () => {
      analytics?.track('new_flow1_welcome_start', {});
      navigate(routes().newflow1.causes);
   };

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            px: 2.5,
            pb: '110px',
         }}
      >
         <Box
            sx={{
               flex: '1',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            <Box sx={{ display: 'flex', width: '100%', mb: 2.5 }}>
               <Typography sx={{ fontSize: '40px', lineHeight: '40px', textAlign: 'center' }} variant='custom-header'>
                  {hasCreatorData && !textLocizeParam
                     ? t('getStarted.introPage.titleCreator', { creator: creatorName })
                     : t('getStarted.introPage.title')}
               </Typography>
            </Box>
            <Box>
               <Typography sx={{ textAlign: 'center', color: '#666666', fontWeight: 500 }}>
                  {t('getStarted.introPage.text')}
               </Typography>
            </Box>
            {creatorPhoto && (
               <CreatorQuote
                  creatorQuote={t(`getStarted.introPage.creatorQuote${textLocizeParam ? `_${textLocizeParam}` : ''}`)}
                  creatorPhoto={creatorPhoto}
               />
            )}
            <FixedGradientContainer
               contentWrapperSx={{ pt: { xs: 2.5 } }}
               containerSx={{ bottom: showLoginButton ? 10 : 0, position: 'fixed' }}
            >
               <Button onClick={() => handleNext()} variant='primaryBtn'>
                  <Typography>{t('getStarted.introPage.CTA')}</Typography>
               </Button>
               {showLoginButton && (
                  <Button
                     sx={{
                        width: '100%',
                        py: 1.25,
                     }}
                     onClick={() => navigate(routes().welcome)}
                  >
                     <Typography
                        textAlign='center'
                        sx={{
                           fontSize: '16px',
                           lineHeight: '19px',
                           fontWeight: 700,
                           color: '#666666',
                           textDecoration: 'underline',
                        }}
                        textTransform='none'
                     >
                        {t('getStarted.introPage.loginCTA')}
                     </Typography>
                  </Button>
               )}
            </FixedGradientContainer>
         </Box>
      </Box>
   );
};

export default WelcomePage;
