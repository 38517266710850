import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CauseTypes } from 'store/causes/types';
import type { RootState } from 'store';
import { CreatorStoreType } from './types';

const initialState: CreatorStoreType = {
   creatorCauses: undefined,
   creatorPhoto: undefined,
   creatorName: undefined,
   textLocizeParam: undefined,
};

export const creatorQuotesSlice = createSlice({
   name: 'creatorQuotesSlice',
   initialState,
   reducers: {
      setCreatorPicture(state, action: PayloadAction<string>) {
         return {
            ...state,
            creatorPhoto: action.payload,
         };
      },
      setCreatorCauses(state, action: PayloadAction<CauseTypes[]>) {
         return {
            ...state,
            creatorCauses: action.payload,
         };
      },
      setCreatorName(state, action: PayloadAction<string>) {
         return {
            ...state,
            creatorName: action.payload,
         };
      },
      setTextLocizeParam(state, action: PayloadAction<string>) {
         return {
            ...state,
            textLocizeParam: action.payload,
         };
      },
      clearQuotesStore() {
         return initialState;
      },
   },
});

export const { clearQuotesStore, setCreatorCauses, setCreatorPicture, setCreatorName, setTextLocizeParam } =
   creatorQuotesSlice.actions;

export const creatorQuotesState = (state: RootState) => state.creatorQuotes;
