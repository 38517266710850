import { Box, Button, Checkbox, Modal, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import {
   CauseBoxItem,
   CauseTitleWrapper,
   CauseTitle,
   CharitiesSelected,
   CharitiesWrapper,
   CharityItemBox,
} from 'pages/portfolio/styles';
import { causesState } from 'store/causes/causes.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { NEW_FLOW1_MAX_CONTENT_WIDTH, NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS } from 'styles/constants';
import { SelectedCHaritiesByCauseType } from 'wrappers/types';
import { CauseTypes, CharityTypes } from 'store/causes/types';
import { clearSelectedCharities, handleOnClickCharityCheckbox } from 'store/charities/charities.slice';
import { routes } from 'routes/routes';
import FixedGradientContainer from 'components/newFlow1/FixedGradientContainer';
import { BackIconButton } from 'components/common';
import { newFlow1State } from 'store/newFlow1/newFlow1.slice';
import { creatorQuotesState } from 'store/creatorQuotes/creatorQuotes.slice';
import CreatorQuote from 'components/newFlow1/CreatorQuote';

const NewFlowPortfolioPage = () => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();

   const isWideScreen = useMediaQuery('(min-width:1134px)');
   const isDesktop = useMediaQuery('(min-width:600px)');
   const { creatorPhoto, textLocizeParam } = useAppSelector(creatorQuotesState);

   const { charityList, selectedCausesList } = useAppSelector(causesState);
   const { returnedUser } = useAppSelector(newFlow1State);
   const [selectedCharitiesIds, setSelectedCharitiesIds] = useState<number[]>([]);
   const [selectedCharitiesByCause, setSelectedCharitiesByCause] = useState<SelectedCHaritiesByCauseType>({});
   const [charityModalData, setCharityModalData] = useState<CharityTypes | null>(null);

   const handleNext = () => {
      if (returnedUser) {
         navigate(routes().newflow1.summary);
      } else navigate(routes().newflow1.subscription);
   };

   const displayedCauseTypes = new Set();

   const getCharitiesSelectedText = (amount: number) => {
      if (amount === 1) return '1 charity selected';
      return `${amount} charities selected`;
   };

   const handleCharityClick = (charity: CharityTypes) => {
      setCharityModalData(charity);
   };

   const isNextDisabled = selectedCharitiesIds.length === 0;

   const handleSelectCharity = (event: React.ChangeEvent<HTMLInputElement>, causeId: number, charity: CharityTypes) => {
      const isChecked = event.target.checked;
      dispatch(handleOnClickCharityCheckbox(charity));
      if (isChecked) {
         setSelectedCharitiesByCause((prev) => {
            const newState = { ...prev };
            if (!newState[`${causeId}`]) newState[`${causeId}`] = [];
            newState[`${causeId}`].push(charity);
            return newState;
         });
         setSelectedCharitiesIds((prev) => [...prev, charity.id]);
      } else {
         setSelectedCharitiesByCause((prev) => {
            const newState = { ...prev };
            newState[`${causeId}`] = newState[`${causeId}`]?.filter((ch) => ch.id !== charity.id);
            return newState;
         });
         setSelectedCharitiesIds((prev) => [...prev].filter((ch) => ch !== charity.id));
      }
   };

   const selectAll = () => {
      dispatch(clearSelectedCharities());
      const charitiesIds = new Set<number>();
      const preparedCharities: SelectedCHaritiesByCauseType = {};

      if (selectedCausesList.length) {
         selectedCausesList.forEach((cause) => {
            preparedCharities[`${cause.id}`] = [];
            charityList.forEach((charity) => {
               if (charity.causes.some((c) => c.id === cause.id)) {
                  preparedCharities[`${cause.id}`].push(charity);
                  charitiesIds.add(charity.id);
                  dispatch(handleOnClickCharityCheckbox(charity));
               }
            });
         });
      } else {
         const causes = new Set<CauseTypes>();
         charityList.forEach((ch) => causes.add(ch.causes[0]));
         causes.forEach((cause) => {
            const charitiesByCause = charityList.filter((ch) =>
               ch.causes.some((parentCause) => parentCause.id === cause.id)
            );
            charitiesByCause.forEach((ch) => charitiesIds.add(ch.id));
            preparedCharities[`${cause.id}`] = charitiesByCause;
         });
      }

      const charitiesIdsArray = Array.from(charitiesIds);

      setSelectedCharitiesIds(charitiesIdsArray);
      setSelectedCharitiesByCause(preparedCharities);
   };

   useEffect(() => {
      if (charityList && charityList.length > 0) {
         charityList.forEach((charity) => {
            new Image().src = charity.image;
         });
         selectAll();
      }
   }, [charityList]);

   const charitiesContainerFlexDirection = charityList.length === 3 || !isWideScreen ? 'column' : 'row';
   const maxCharitiesContainerWidth =
      charityList.length === 3 || !isWideScreen ? NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS : '1130px';

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 2.5,
            pb: { xs: '110px', sm: '20px' },
         }}
      >
         <Box sx={{ mt: { xs: 3, sm: 5 }, mb: 2.5, maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH }}>
            <Typography variant='custom-header'>{t('getStarted.portfolio.title')}</Typography>
         </Box>
         {creatorPhoto && textLocizeParam && (
            <CreatorQuote
               containerSx={{ mt: 0, mb: 2.5 }}
               creatorQuote={t(`getStarted.portfolio.creatorQuote_${textLocizeParam}`)}
               creatorPhoto={creatorPhoto}
            />
         )}
         <Box
            component='div'
            display='flex'
            gap='20px'
            alignItems='center'
            justifyContent='center'
            flexDirection={charitiesContainerFlexDirection}
            sx={{ maxWidth: maxCharitiesContainerWidth, width: '100%' }}
         >
            {charityList.length > 0 &&
               charityList.map((charity) => {
                  if (charity.causes.length > 0) {
                     for (const cause of charity.causes) {
                        if (!displayedCauseTypes.has(cause.title)) {
                           displayedCauseTypes.add(cause.title);
                           return (
                              <CauseBoxItem
                                 sx={{ width: { sm: '100%' }, maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH }}
                                 key={cause.id}
                              >
                                 <CauseTitleWrapper>
                                    <Box>
                                       <CauseTitle>{cause.title}</CauseTitle>
                                       <CharitiesSelected>
                                          {getCharitiesSelectedText(
                                             selectedCharitiesByCause[`${cause.id}`]?.length ?? 0
                                          )}
                                       </CharitiesSelected>
                                    </Box>
                                    <ReactSVG
                                       src={`data:image/svg+xml;utf8,${encodeURIComponent(cause.impactImage)}`}
                                       beforeInjection={(svg) => {
                                          svg.querySelectorAll('path').forEach((path) => {
                                             path.setAttribute('fill', '#333333');
                                          });
                                          svg.setAttribute('width', '40');
                                          svg.setAttribute('height', '40');
                                       }}
                                       style={{ width: '40px', height: '40px%' }}
                                    />
                                 </CauseTitleWrapper>
                                 <CharitiesWrapper>
                                    {charityList
                                       .filter((ch) => ch.causes.some((parentCause) => parentCause.id === cause.id))
                                       .map((ch) => (
                                          <CharityItemBox
                                             sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: 'fit-content',
                                                px: '16px',
                                                py: '10px',
                                                boxSizing: 'border-box',
                                                borderBottom: '1px solid #0000001A',
                                             }}
                                             key={ch.id}
                                          >
                                             <Checkbox
                                                sx={{
                                                   p: 0,
                                                   width: '24px',
                                                   height: '24px',
                                                   color: '#F55D66',
                                                   '&.Mui-checked': {
                                                      color: '#F55D66',
                                                   },
                                                }}
                                                checked={selectedCharitiesIds.includes(ch.id)}
                                                onChange={(e) => handleSelectCharity(e, cause.id, ch)}
                                             />
                                             <Box
                                                sx={{
                                                   display: 'flex',
                                                   alignItems: 'center',
                                                   width: '100%',
                                                }}
                                                onClick={() => {
                                                   analytics?.track('new_flow1_view_charity', {
                                                      charity_id: ch.id,
                                                      charity_name: ch.title,
                                                   });
                                                   handleCharityClick(ch);
                                                }}
                                             >
                                                <Box
                                                   sx={{
                                                      width: '50px',
                                                      height: '50px',
                                                      border: '1px solid #E6E6E6',
                                                      overflow: 'hidden',
                                                      mx: '8px',
                                                   }}
                                                >
                                                   <img alt={ch.title} src={ch.icon} height='100%' width='100%' />
                                                </Box>
                                                <Box
                                                   sx={{
                                                      mr: 'auto',
                                                   }}
                                                >
                                                   <Typography
                                                      sx={{ fontSize: '14px', lineHeight: '17px', fontWeight: 700 }}
                                                   >
                                                      {ch.title}
                                                   </Typography>
                                                   <Typography
                                                      sx={{
                                                         fontSize: '12px',
                                                         lineHeight: '14px',
                                                         fontWeight: 500,
                                                         color: '#999999',
                                                      }}
                                                   >
                                                      {ch.shortDescription}
                                                   </Typography>
                                                </Box>
                                                <DynamicGoogleIcon iconName='chevron_forward' />
                                             </Box>
                                          </CharityItemBox>
                                       ))}
                                 </CharitiesWrapper>
                              </CauseBoxItem>
                           );
                        }
                     }
                  }
                  return null;
               })}
         </Box>

         <Modal open={charityModalData !== null} onClose={() => setCharityModalData(null)}>
            {isDesktop ? (
               <Box
                  sx={{
                     width: '480px',
                     height: 'auto',
                     borderRadius: '6px',
                     overflow: 'hidden',
                     position: 'absolute',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     backgroundColor: '#ffffff',
                  }}
               >
                  <BackIconButton
                     sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: '10000',
                     }}
                     onClick={() => {
                        setCharityModalData(null);
                     }}
                  >
                     <DynamicGoogleIcon iconColor='#999999' iconName='close' />
                  </BackIconButton>

                  {/* Charity icon */}
                  <Box
                     sx={{
                        position: 'absolute',
                        width: 80,
                        height: 80,
                        top: 190,
                        left: 20,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        border: '1px solid #999999',
                        boxSizing: 'border-box',
                     }}
                  >
                     <img
                        style={{
                           width: '100%',
                           height: 'auto',
                        }}
                        src={charityModalData?.icon}
                        alt=''
                     />
                  </Box>

                  {/* Charity image */}
                  <Box
                     sx={{
                        width: '100%',
                        maxHeight: '210px',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <img
                        style={{
                           width: '100%',
                           height: 'auto',
                        }}
                        src={charityModalData?.image}
                        alt=''
                     />
                  </Box>

                  <Box
                     sx={{
                        px: '20px',
                        marginTop: '70px',
                        mb: '20px',
                     }}
                  >
                     <Box
                        sx={{
                           marginBottom: '20px',
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: '20px',
                              lineHeight: '24px',
                              fontWeight: 700,
                           }}
                        >
                           {charityModalData?.title}
                        </Typography>
                        <Typography
                           sx={{
                              color: '#666666',
                              fontSize: '16px',
                              lineHeight: '19px',
                              fontWeight: 500,
                           }}
                        >
                           {charityModalData?.shortDescription}
                        </Typography>
                     </Box>

                     <Typography
                        sx={{
                           color: '#666666',
                           fontSize: '16px',
                           lineHeight: '19px',
                           fontWeight: 500,
                        }}
                     >
                        {charityModalData?.description}
                     </Typography>
                  </Box>
               </Box>
            ) : (
               <Box
                  sx={{
                     width: '100vw',
                     height: '100vh',
                     borderRadius: '6px',
                     overflow: 'hidden',
                     position: 'absolute',
                     backgroundColor: '#ffffff',
                  }}
               >
                  <BackIconButton
                     sx={{
                        border: '1px solid #F55D66',
                        position: 'absolute',
                        top: '30px',
                        left: '20px',
                        zIndex: '10000',
                     }}
                     onClick={() => {
                        setCharityModalData(null);
                     }}
                  >
                     <DynamicGoogleIcon iconColor='#999999' iconName='arrow_back' />
                  </BackIconButton>

                  {/* Charity image */}
                  <Box
                     sx={{
                        width: '100%',
                        maxHeight: '210px',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <img
                        style={{
                           width: '100%',
                           height: 'auto',
                        }}
                        src={charityModalData?.image}
                        alt=''
                     />
                  </Box>
                  {/* Charity icon */}
                  <Box
                     sx={{
                        mt: '-40px',
                        width: 80,
                        height: 80,
                        borderRadius: '6px',
                        overflow: 'hidden',
                        border: '1px solid #999999',
                        boxSizing: 'border-box',
                        ml: 2.5,
                     }}
                  >
                     <img
                        style={{
                           width: '100%',
                           height: 'auto',
                        }}
                        src={charityModalData?.icon}
                        alt=''
                     />
                  </Box>

                  <Box
                     sx={{
                        px: '20px',
                        marginTop: '20px',
                     }}
                  >
                     <Box
                        sx={{
                           marginBottom: '20px',
                        }}
                     >
                        <Typography
                           sx={{
                              fontSize: '20px',
                              lineHeight: '24px',
                              fontWeight: 700,
                           }}
                        >
                           {charityModalData?.title}
                        </Typography>
                        <Typography
                           sx={{
                              color: '#666666',
                              fontSize: '16px',
                              lineHeight: '19px',
                              fontWeight: 500,
                           }}
                        >
                           {charityModalData?.shortDescription}
                        </Typography>
                     </Box>

                     <Typography
                        sx={{
                           color: '#666666',
                           fontSize: '16px',
                           lineHeight: '19px',
                           fontWeight: 500,
                        }}
                     >
                        {charityModalData?.description}
                     </Typography>
                  </Box>
               </Box>
            )}
         </Modal>

         <FixedGradientContainer containerSx={{ maxWidth: { sm: NEW_FLOW1_MAX_CONTENT_WIDTH } }}>
            <Button
               sx={{
                  width: '100%',
                  maxWidth: { xs: NEW_FLOW1_MAX_CONTENT_WIDTH, md: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS },
               }}
               disabled={isNextDisabled}
               onClick={handleNext}
               variant='primaryBtn'
            >
               <Typography>{t('getStarted.yearlyPage.CTA')}</Typography>
            </Button>
         </FixedGradientContainer>
      </Box>
   );
};

export default NewFlowPortfolioPage;
