import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
import { NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS } from 'styles/constants';

interface IFixedGradientContainer {
   children: React.ReactNode;
   containerSx?: SxProps<Theme>;
   contentWrapperSx?: SxProps<Theme>;
}

const FixedGradientContainer: React.FC<IFixedGradientContainer> = ({ children, containerSx, contentWrapperSx }) => {
   return (
      <Box
         sx={{
            background: 'linear-gradient(to top, #EBE9E5CC 0%, transparent 100%)',
            height: '110px',
            width: '100%',
            position: { xs: 'fixed', sm: 'static' },
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            ...containerSx,
         }}
      >
         <Box
            sx={{
               maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS,
               height: '110px',
               width: '100%',
               px: { xs: 2.5, sm: 0 },
               pt: { xs: 2.5, sm: 5 },
               pb: 5,
               ...contentWrapperSx,
            }}
         >
            {children}
         </Box>
      </Box>
   );
};

export default FixedGradientContainer;
