import { Box, SxProps, Theme, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

interface ICreatorQuoteProps {
   creatorQuote: string | React.ReactElement;
   creatorPhoto: string;
   containerSx?: SxProps<Theme> | undefined;
}

const CreatorQuote: React.FC<ICreatorQuoteProps> = ({ creatorPhoto, creatorQuote, containerSx }) => {
   return (
      <Box
         sx={{
            width: '100%',
            mt: 2.5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            ...containerSx,
         }}
      >
         <Box
            sx={{
               width: '100%',
               maxWidth: '390px',
               display: 'flex',
               flexDirection: 'row',
               alignItems: 'center',
               justifyContent: { xs: 'center', sm: 'space-between' },
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mr: 'auto',
               }}
            >
               <motion.div
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.6, ease: 'easeOut' }}
               >
                  <Box
                     sx={{
                        maxWidth: '310px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px',
                        px: 2,
                        py: 1.5,
                     }}
                  >
                     <Typography sx={{ fontSize: '16px', lineHeight: '19px', color: '#666666' }}>
                        {creatorQuote}
                     </Typography>
                  </Box>
               </motion.div>
               <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4, delay: 0.3 }}>
                  <Box sx={{ backgroundColor: '#FFFFFF', minWidth: 16, minHeight: 16, rotate: '45deg', ml: '-8px' }} />
               </motion.div>
            </Box>
            <motion.img
               initial={{ scale: 0.8, opacity: 0 }}
               animate={{ scale: 1, opacity: 1 }}
               transition={{ duration: 0.5, delay: 0.2, ease: 'easeOut' }}
               style={{ marginLeft: '10px', width: 60, height: 60, borderRadius: '50%' }}
               src={creatorPhoto}
               alt='creatorPhoto'
            />
         </Box>
      </Box>
   );
};

export default CreatorQuote;
