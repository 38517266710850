import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { tryParseInt } from 'utils/utils';
import { NewFlow1StoreType } from './types';

const initialState: NewFlow1StoreType = {
   averageAnnualCharitableExpenses: undefined,
   donationAmount: tryParseInt(process.env.REACT_APP_START_AMOUNT, 10),
   returnedUser: false,
   couponCode: undefined,
   couponCodeID: undefined,
   dynamicData: {},
};

export const newFlow1Slice = createSlice({
   name: 'newFlow1Slice',
   initialState,
   reducers: {
      setAverageAnnualCharitableExpenses(state, action: PayloadAction<string>) {
         return {
            ...state,
            averageAnnualCharitableExpenses: action.payload,
            dynamicData: { ...state.dynamicData, averageAnnualCharitableExpenses: action.payload },
         };
      },
      setDonationAmount(state, action: PayloadAction<number>) {
         return {
            ...state,
            donationAmount: action.payload,
            dynamicData: { ...state.dynamicData, donationAmount: action.payload },
         };
      },
      setReturnedUser(state, action: PayloadAction<boolean>) {
         return {
            ...state,
            returnedUser: action.payload,
         };
      },
      setCouponCode(state, action: PayloadAction<string>) {
         return {
            ...state,
            couponCode: action.payload,
         };
      },
      setCouponId(state, action: PayloadAction<string>) {
         return {
            ...state,
            couponCodeID: action.payload,
         };
      },
      clearNewFlowStore() {
         return initialState;
      },
   },
});

export const {
   setAverageAnnualCharitableExpenses,
   setDonationAmount,
   clearNewFlowStore,
   setReturnedUser,
   setCouponId,
} = newFlow1Slice.actions;

export const newFlow1State = (state: RootState) => state.newFlow1;
