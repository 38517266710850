import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoginWithApple from 'components/loginButtons/LoginWithApple';
import LoginWithGoogle from 'components/loginButtons/LoginWithGoogle';
import { routes } from 'routes/routes';
import { addEmail, addName, authState, createAnonymousSignup, sendMagicLink } from 'store/auth/auth.slice';
import { causesState } from 'store/causes/causes.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { detectWebview, isValidEmail } from 'utils/utils';
import useRedirectAfterLogin from 'hooks/useRedirectAfterLogin';
import { creatorQuotesState } from 'store/creatorQuotes/creatorQuotes.slice';
import CreatorQuote from 'components/newFlow1/CreatorQuote';

const NewFlowSignUpPage = () => {
   const { t } = useTranslation();
   const { selectedCausesList } = useAppSelector(causesState);
   const { signupData } = useAppSelector(authState);
   const { navigateUser } = useRedirectAfterLogin();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { creatorPhoto, textLocizeParam } = useAppSelector(creatorQuotesState);

   const emailInputPlaceholder = t('emailInputPlaceholder');
   const nameInputPlaceholder = t('nameInputPlaceholder');
   const errorMessage = t('saveYourPortfolio.emailErrorMessage');

   const userBrowserData = detectWebview();
   const isWebview = userBrowserData.Webview;

   const nameInputRef = useRef<HTMLDivElement | null>(null);

   const [email, setEmail] = useState(signupData.email ?? '');
   const [error, setError] = useState(false);
   const [showNameInput, setShowNameInput] = useState(false);
   const [name, setName] = useState(signupData.firstName ?? '');
   const [submitting, setSubmitting] = useState(false);

   const isSignUpDisabled = error || name.length < 3;

   const handleEmailInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = e.target.value;
      if (error) {
         setError(false);
      }
      setEmail(newEmail);
   };

   const handleNameInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      if (error) {
         setError(false);
      }
      setName(newName);
   };

   const handleNext = async () => {
      if (submitting) return;
      const isInvalidEmail = !isValidEmail(email);
      if (isInvalidEmail) {
         console.log('Email is not valid');
         setError(true);
         return;
      }
      if (name.length >= 3) {
         setSubmitting(true);
         dispatch(addEmail(email));
         dispatch(addName(name));
         const res = await dispatch(sendMagicLink());
         if (res?.payload?.userFound) {
            navigate(routes().checkYourEmail);
         } else {
            dispatch(createAnonymousSignup())
               .then(({ payload }) => {
                  if (payload.response && payload.response?.status === 409) {
                     dispatch(sendMagicLink()).then(({ payload: mlPayload }) => {
                        if (mlPayload?.autoLoginCode) {
                           // auto login with test users
                           const routePosition = mlPayload.autoLoginCode.indexOf('/ml?code');
                           const autoLoginRoute =
                              routePosition > 0
                                 ? mlPayload.autoLoginCode.substring(routePosition)
                                 : routes().checkYourEmail;
                           navigate(autoLoginRoute);
                        } else navigate(routes().checkYourEmail);
                     });
                  } else if (payload.user) {
                     if (payload.user.details.creatorCoupon) {
                        navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
                     } else {
                        navigateUser(payload.user, { newUserRoute: routes().newflow1.summary });
                     }
                  } else {
                     dispatch(addEmail(''));
                     dispatch(addName(''));
                  }
               })
               .finally(() => {
                  setSubmitting(false);
               });
         }
      } else {
         nameInputRef.current?.focus();
      }
   };

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            px: 2.5,
            pb: '20px',
         }}
      >
         <Box sx={{ mt: 3, mb: 2.5 }}>
            <Typography variant='custom-header'>{t('getStarted.signUp.title')}</Typography>
         </Box>

         <Box
            sx={{
               backgroundColor: '#FFFFFF',
               px: 2.5,
               py: 3.75,
               borderRadius: '8px',
            }}
         >
            <Box>
               <Typography sx={{ color: '#666666' }}>{t('getStarted.signUp.text')}</Typography>
            </Box>

            <Box
               sx={{
                  mt: 0.5,
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: 0.5,
               }}
            >
               {selectedCausesList.map((cause) => (
                  <Box
                     sx={{
                        borderRadius: '2px',
                        backgroundColor: '#EFCAC9',
                        px: 1,
                        py: 0.25,
                     }}
                     key={cause.title}
                  >
                     <Typography
                        sx={{
                           fontSize: '12px',
                           lineHeight: '18px',
                           fontWeight: 700,
                        }}
                     >
                        {cause.impactHashtag}
                     </Typography>
                  </Box>
               ))}
            </Box>
            {isWebview ? (
               <Box
                  sx={{
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <TextField
                     fullWidth
                     required
                     className='CommonInput'
                     value={name}
                     type='text'
                     ref={nameInputRef}
                     onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                           handleNext();
                        }
                     }}
                     onFocus={() => setError(false)}
                     onChange={handleNameInputOnChange}
                     error={error}
                     placeholder={nameInputPlaceholder}
                  />
                  <TextField
                     fullWidth
                     required
                     className='CommonInput'
                     value={email}
                     type='text'
                     onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                           nameInputRef.current?.focus();
                        }
                     }}
                     onFocus={() => {
                        setError(false);
                        !showNameInput && setShowNameInput(true);
                     }}
                     onChange={handleEmailInputOnChange}
                     error={error}
                     placeholder={emailInputPlaceholder}
                  />
                  {error && (
                     <Typography sx={{ mt: '4px', textAlign: { sm: 'center' }, color: '#F55D66' }}>
                        {errorMessage}
                     </Typography>
                  )}
                  <Button disabled={isSignUpDisabled} onClick={handleNext} variant='primaryBtn'>
                     <Typography>{t('getStarted.signUp.CTA')}</Typography>
                  </Button>
                  <Box>
                     <Typography fontWeight={600} textAlign='center'>
                        or
                     </Typography>
                  </Box>

                  <Box>
                     <LoginWithApple customRoutes={{ newUserRoute: routes().newflow1.summary }} />
                  </Box>
               </Box>
            ) : (
               <Box
                  sx={{
                     mt: 2.5,
                     display: 'flex',
                     flexDirection: 'column',
                     rowGap: 2.5,
                  }}
               >
                  <TextField
                     fullWidth
                     required
                     className='CommonInput'
                     value={email}
                     type='text'
                     onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === 'Enter') {
                           nameInputRef.current?.focus();
                        }
                     }}
                     onFocus={() => {
                        setError(false);
                        !showNameInput && setShowNameInput(true);
                     }}
                     onChange={handleEmailInputOnChange}
                     error={error}
                     placeholder={emailInputPlaceholder}
                  />
                  {error && (
                     <Typography sx={{ mt: '4px', textAlign: { sm: 'center' }, color: '#F55D66' }}>
                        {errorMessage}
                     </Typography>
                  )}
                  {showNameInput && (
                     <TextField
                        fullWidth
                        required
                        className='CommonInput'
                        value={name}
                        type='text'
                        ref={nameInputRef}
                        onKeyDown={(event: React.KeyboardEvent) => {
                           if (event.key === 'Enter') {
                              handleNext();
                           }
                        }}
                        onFocus={() => setError(false)}
                        onChange={handleNameInputOnChange}
                        error={error}
                        placeholder={nameInputPlaceholder}
                     />
                  )}
                  <Button disabled={isSignUpDisabled} onClick={handleNext} variant='primaryBtn'>
                     <Typography>{t('getStarted.signUp.CTA')}</Typography>
                  </Button>
                  <Box>
                     <Typography fontWeight={600} textAlign='center'>
                        or
                     </Typography>
                  </Box>

                  <Box>
                     <LoginWithApple customRoutes={{ newUserRoute: routes().newflow1.summary }} />
                     <LoginWithGoogle customRoutes={{ newUserRoute: routes().newflow1.summary }} />
                  </Box>
               </Box>
            )}
         </Box>
         {creatorPhoto && (
            <CreatorQuote
               creatorQuote={t(`getStarted.signUp.creatorQuote${textLocizeParam ? `_${textLocizeParam}` : ''}`)}
               creatorPhoto={creatorPhoto}
            />
         )}
      </Box>
   );
};

export default NewFlowSignUpPage;
