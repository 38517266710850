import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { NewFlow1Layout, NoLayout, PublicLayout } from 'components/layout/publicLayout';
import {
   AnonymousConnectPage,
   CausesPage,
   CharityPage,
   ForgotPasswordPage,
   GeneratingProfilePage,
   ImpactDetailPage,
   IntroPage,
   LoadingProfilePage,
   MyProfileForward,
   MyProfilePage,
   NewPasswordPage,
   PortfolioPage,
   ProfilePage,
   ResetPasswordPage,
   SubscriptionPage,
   DonationPage,
   MobileRedirectPage,
} from 'pages';
import { CheckYourEmail } from 'pages/checkYourEmail/checkYourEmail';
import { GratitudeDisplayPage } from 'pages/gratitude/gratitude.display.page';
import { GratitudePage } from 'pages/gratitude/gratitude.page';
import { SignInPage } from 'pages/signIn/signIn';
import { SignUpPage } from 'pages/signUp/signUp';

import { CreatorFlowLayout } from 'components/creatorFlow/layout/CreatorFlowLayout';
import { MagicLinkVerification } from 'pages/magicLinkVerification/magicLinkVerification.page';
import { CreatorFlowIntroPage } from 'pages/creatorFlow/intro/Intro';
import { CreatorFlowEmailValidatedPage } from 'pages/creatorFlow/validator/magicLinkVerification.page';
import { CreatorOnBoardingPage } from 'pages/creatorFlow/validator/creatorOnBoarding.page';
import { CreatorGratitudePage } from 'pages/creatorFlow/gratitude/creatorGratitudePage';
import { WelcomePageV2 } from 'pages/welcome/welcomePage-v2';
import MarketingSurvey from 'pages/marketingSurvey/marketingSurvey.page';
import PaymentPage from 'pages/payment/payment.page';
import PaymentCheckPage from 'pages/paymentCheck/paymentCheck.page';
import { NamePage } from 'pages/name/name.page';
import WelcomePage from 'pages/getStartedFlow/welcome.page';
import AnimationFinishPage from 'pages/getStartedFlow/animationFinish.page';
import YearlyPage from 'pages/getStartedFlow/yearly.page';
import NewFlowCausesPage from 'pages/getStartedFlow/newFlowCauses.page';
import MatchingAnimationPage from 'pages/getStartedFlow/matchingAnimation.page';
import NewFlowPortfolioPage from 'pages/getStartedFlow/newFlowPortfolio.page';
import PlanbuildingAnimationPage from 'pages/getStartedFlow/planbuildingAnimation.page';
import NewFlowSubscribtionPage from 'pages/getStartedFlow/newFlowSubscription.page';
import NewFlowSignUpPage from 'pages/getStartedFlow/newFlowSignUp.page';
import NewFlowSummaryPage from 'pages/getStartedFlow/newFlowSummary.page';
import NewFlowPaymentPage from 'pages/getStartedFlow/newFlowPayment.page';
import SeaspiracyIntroPage from 'pages/seaspiracy/seaspiracyIntro.page';
import { routes } from './routes';

export const AppRoutes: React.FC = () => {
   const routeList: RouteObject[] = [
      {
         path: routes().newflow1.base,
         element: <NewFlow1Layout />,
         children: [
            {
               path: routes().newflow1.welcome,
               Component: WelcomePage,
            },
            // {
            //    path: routes().newflow1.yearly,
            //    Component: YearlyPage,
            // },
            {
               path: routes().newflow1.causes,
               Component: NewFlowCausesPage,
            },
            {
               path: routes().newflow1.matchingAnimation,
               Component: MatchingAnimationPage,
            },
            {
               path: routes().newflow1.portfolio,
               Component: NewFlowPortfolioPage,
            },
            {
               path: routes().newflow1.subscription,
               Component: NewFlowSubscribtionPage,
            },
            {
               path: routes().newflow1.planbuildingAnimation,
               Component: PlanbuildingAnimationPage,
            },
            {
               path: routes().newflow1.signup,
               Component: NewFlowSignUpPage,
            },
            {
               path: routes().newflow1.summary,
               Component: NewFlowSummaryPage,
            },
            {
               path: routes().newflow1.payment,
               Component: NewFlowPaymentPage,
            },
            {
               path: routes().newflow1.thankYou,
               Component: AnimationFinishPage,
            },
         ],
      },
      {
         element: <NewFlow1Layout />,
         children: [
            { path: routes().seaspiracy.intro, Component: SeaspiracyIntroPage },
            { path: routes().seaspiracy.payment, Component: NewFlowPaymentPage },
            { path: routes().seaspiracy.thankYou, Component: AnimationFinishPage },
         ],
      },
      {
         path: routes().creatorFlow.base,
         element: <CreatorFlowLayout />,
         children: [
            {
               path: routes().creatorFlow.intro,
               Component: CreatorFlowIntroPage,
            },
            {
               path: routes().creatorFlow.emailValidation,
               Component: CreatorFlowEmailValidatedPage,
            },
            {
               path: routes().creatorFlow.creatorImpact,
               Component: CreatorOnBoardingPage,
            },
            {
               path: routes().creatorFlow.gratitude,
               Component: CreatorGratitudePage,
            },
         ],
      },
      {
         element: <PublicLayout />,
         children: [
            {
               path: routes().welcome,
               Component: WelcomePageV2,
               id: '1',
            },
            {
               path: routes().loginEmail,
               Component: SignUpPage,
               id: '2',
            },
            {
               path: routes().causes,
               Component: CausesPage,
               id: '3',
            },
            {
               path: routes().checkYourEmail,
               Component: CheckYourEmail,
               id: '4',
            },
            {
               path: routes().signIn,
               Component: SignInPage,
               id: '5',
            },
            {
               path: routes().generatePage,
               Component: GeneratingProfilePage,
               id: '6',
            },
            {
               path: routes().portfolio,
               Component: PortfolioPage,
               id: '7',
            },
            {
               path: routes().subscription,
               Component: SubscriptionPage,
               id: '8',
            },
            {
               path: routes().share,
               Component: GratitudeDisplayPage,
               id: '9',
            },
            {
               path: routes().magicLink,
               Component: MagicLinkVerification,
               id: '10',
            },
            {
               path: routes().donation,
               Component: DonationPage,
               id: '11',
            },
            {
               path: routes().mobileRedirect,
               Component: MobileRedirectPage,
               id: '12',
            },
            {
               path: routes().marketingSurvey,
               Component: MarketingSurvey,
               id: '13',
            },
            {
               path: routes().payment,
               Component: PaymentPage,
               id: '14',
            },
            {
               path: routes().paymentCheck,
               Component: PaymentCheckPage,
               id: '15',
            },
            {
               path: routes().name,
               Component: NamePage,
               id: '16',
            },
         ],
      },
      {
         element: <NoLayout />,
         children: [
            {
               path: routes().gratitude,
               Component: GratitudePage,
               id: '17',
            },
            {
               path: routes().charity,
               Component: CharityPage,
               id: '11',
            },
            {
               path: routes().profile,
               Component: ProfilePage,
               id: '13',
            },
            {
               path: routes().newPassword,
               Component: NewPasswordPage,
               id: '14',
            },
            {
               path: routes().resetPassword,
               Component: ResetPasswordPage,
               id: '16',
            },
            {
               path: routes().intro,
               Component: IntroPage,
               id: '18',
            },
            {
               path: routes().anonymousConnect,
               Component: AnonymousConnectPage,
               id: '19',
            },
            {
               path: routes().loadingProfile,
               Component: LoadingProfilePage,
               id: '20',
            },
            {
               path: routes().forgotPassword,
               Component: ForgotPasswordPage,
               id: '21',
            },
            {
               path: routes().myProfile,
               Component: MyProfileForward,
               id: '22',
            },
            {
               path: routes().impactDetail,
               Component: ImpactDetailPage,
               id: '23',
            },
            {
               path: routes().publicProfile,
               Component: MyProfilePage,
               id: '24',
            },
         ],
      },
   ];

   return useRoutes(routeList);
};
