import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, LinearProgress, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactSVG } from 'react-svg';
import { PageMainLabel, PageSubLabel, ButtonFurther, ContentWrapper } from 'components/common';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { ENUM_ICON } from 'enums/icons';
import { Icon, CheckedIcon, PlusIcon, SumIcon } from 'components/icon';
import {
   causesState,
   handleSelectCause,
   handleSendSelectedCauses,
   fetchCharitiesBySelectedCauses,
   fetchSelectedCauses,
} from 'store/causes/causes.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { shuffleArray } from 'utils/utils';
import type { CauseTypes } from 'store/causes/types';

import { FixedBottomNav } from 'components/common/common';
import { authState } from 'store/auth/auth.slice';
import { MAIN_CONTAINER_WIDTH } from 'styles/constants';
import CausesDetails from './causesDetails';
import ModalComponent from './modal';

const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';

export const CausesPage = () => {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { loggedUserData } = useAppSelector(authState);
   const screenSE = useMediaQuery('(max-width:599px)');
   const [sortedElements, setSortedElements] = useState<CauseTypes[]>([]);
   const { causesList, selectedCausesList } = useAppSelector(causesState);
   const [searchParams] = useSearchParams();
   const maxCausesSelected = Number(process.env.REACT_APP_MAX_CAUSES_SELECTION);
   const minCausesSelected = 1;

   // Modal state
   const [missedCausesModalIsOpen, setMissedCausesModalIsOpen] = useState<boolean>(false);

   const [overSelectedCausesModalIsOpen, setOverSelectedCausesModalIsOpen] = useState<boolean>(false);

   const isSelected = (img: CauseTypes) => {
      return selectedCausesList.some((selectedImage) => selectedImage.id === img.id);
   };

   const getCauseStyle = (cause: CauseTypes) => {
      if (isSelected(cause)) return { p: '11px', border: '2px solid #F55D66', backgroundColor: '#EBE9E5' };
      return { border: '1px solid #0000001A', backgroundColor: '#ffffff', p: '12px' };
   };

   // don't allow user to go back to password page
   window.onpopstate = () => {
      const currentLocation = window.location.href;
      if (currentLocation.includes('causes') || currentLocation.toLocaleLowerCase().includes('password')) {
         navigate(routes().causes);
      }
   };

   const handleSendData = () => {
      // Open modal when selectedCauses less than 3
      if (selectedCausesList?.length < minCausesSelected) {
         setMissedCausesModalIsOpen(true);
         return;
      }

      if (selectedCausesList.length > 0) {
         dispatch(handleSendSelectedCauses()).then(() => {
            dispatch(fetchCharitiesBySelectedCauses()).then(() => {
               analytics?.track('select_causes', {
                  causes: selectedCausesList.map((cause) => cause.id),
                  causesNames: selectedCausesList.map((cause) => cause.title),
               });
               localStorage.setItem('selectedCauses', JSON.stringify(selectedCausesList));
               navigate(routes().generatePage);
            });
         });
      }
   };

   useEffect(() => {
      const sorted = shuffleArray<CauseTypes>(causesList);
      setSortedElements(sorted);
   }, [causesList]);

   const containerRef = useRef<HTMLDivElement | null>(null);
   const [isDragging, setIsDragging] = useState(false);
   const [startPosition, setStartPosition] = useState(0);

   useEffect(() => {
      const handleMouseMove = (e: MouseEvent) => {
         e.preventDefault();
         if (isDragging && containerRef.current) {
            const newDeltaX = e.pageX - startPosition;
            containerRef.current.scrollLeft -= newDeltaX;
            setStartPosition(e.pageX);
         }
      };

      const handleMouseUp = (event: MouseEvent) => {
         event.preventDefault();
         setIsDragging(false);
         document.querySelectorAll('.singleCauseBox').forEach((element) => {
            (element as HTMLDivElement).style.userSelect = '';
         });
         document.removeEventListener('mousemove', handleMouseMove);
         document.removeEventListener('mouseup', handleMouseUp);
      };

      if (isDragging) {
         document.addEventListener('mousemove', handleMouseMove);
         document.addEventListener('mouseup', handleMouseUp);
      }

      return () => {
         document.removeEventListener('mousemove', handleMouseMove);
         document.removeEventListener('mouseup', handleMouseUp);
      };
   }, [isDragging, startPosition]);

   const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      if (containerRef.current) {
         setIsDragging(true);
         setStartPosition(e.pageX);
         document.querySelectorAll('.singleCauseBox').forEach((element) => {
            (element as HTMLDivElement).style.userSelect = 'none';
         });
      }
   };

   const sessionProfile = sessionStorage.getItem('profileWasActivated');

   useEffect(() => {
      if (loggedUserData && selectedCausesList?.length === 0) {
         dispatch(fetchSelectedCauses());
      }
      document.title = `Causes | ${t('siteTitleName')}`;
   }, []);

   // Preload images
   useEffect(() => {
      if (causesList?.length > 0) {
         causesList.forEach((cause) => {
            if (cause.icon) {
               const image = new Image();
               image.src = cause.icon;
            }
         });
      }
   }, [causesList]);

   return (
      <Box
         sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
         }}
      >
         <Box pb={{ xs: 21.5, sm: 0 }} sx={{ maxWidth: MAIN_CONTAINER_WIDTH, gap: '25px' }}>
            <Box
               sx={{ overflow: 'visible' }}
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               alignItems='center'
               justifyContent='center'
               gap={0.1}
            >
               <PageMainLabel
                  marginBottom='30px'
                  sx={{ color: '#111111', textTransform: 'none', px: '21px', mx: { sm: 'auto' } }}
               >
                  {t(searchParams.get('back') === 'true' ? 'cuases.headingBack' : 'cuases.heading', {
                     maxCauses: maxCausesSelected,
                  })}
               </PageMainLabel>
               <Box>
                  {!!sortedElements.length && (
                     <Stack
                        maxWidth='350px'
                        spacing='6px'
                        direction='row'
                        sx={{ flexWrap: 'wrap' }}
                        justifyContent='center'
                        useFlexGap
                     >
                        {sortedElements
                           .filter((cause) => cause.active)
                           .map((cause) => (
                              <Box
                                 sx={{
                                    ...{
                                       cursor: 'pointer',
                                       width: 'calc((100% - 12px) / 3)',
                                       aspectRatio: 1,
                                       borderRadius: '8px',
                                       boxSizing: 'border-box',
                                       display: 'flex',
                                       flexDirection: 'column',
                                       justifyContent: 'space-between',
                                    },
                                    ...getCauseStyle(cause),
                                 }}
                                 onClick={() => {
                                    if (
                                       selectedCausesList?.length > 2 &&
                                       !selectedCausesList?.some((causes) => causes.id === cause?.id)
                                    ) {
                                       analytics.track('max_causes_reached', {
                                          selectedCausesList: selectedCausesList.map((c) => c.title),
                                       });
                                       setOverSelectedCausesModalIsOpen(true);
                                    }
                                    dispatch(handleSelectCause(cause));
                                 }}
                                 key={cause.id}
                              >
                                 <Box sx={{ width: '32px', height: '32px' }}>
                                    <ReactSVG
                                       src={`data:image/svg+xml;utf8,${encodeURIComponent(cause.impactImage)}`}
                                       beforeInjection={(svg) => {
                                          svg.querySelectorAll('path').forEach((path) => {
                                             path.setAttribute('fill', '#333333');
                                          });
                                          svg.setAttribute('width', '32');
                                          svg.setAttribute('height', '32');
                                       }}
                                       style={{ width: '32px', height: '32px%' }}
                                    />
                                 </Box>
                                 <Typography sx={{ fontSize: '14px', lineHeight: '17px', fontWeight: 600 }}>
                                    {cause.title}
                                 </Typography>
                              </Box>
                           ))}
                     </Stack>
                  )}
               </Box>
            </Box>
            <Box
               sx={{
                  width: { sm: '100%' },
               }}
            >
               <FixedBottomNav
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: 2,
                     paddingTop: '30px',
                     px: '21px',
                     opacity: { xs: 0.9, sm: 1 },
                     width: { xs: 'auto', sm: '100%' },
                     maxWidth: '390px  !important',
                  }}
               >
                  <Button onClick={handleSendData} variant='primaryBtn'>
                     <Typography>Next</Typography>
                  </Button>
               </FixedBottomNav>
            </Box>
         </Box>
         {!!missedCausesModalIsOpen && (
            <ModalComponent
               content={t('cuases.error.pick3', { maxCauses: maxCausesSelected })}
               onClose={() => setMissedCausesModalIsOpen(false)}
               open
            />
         )}
         {!!overSelectedCausesModalIsOpen && (
            <ModalComponent
               title={t('cuases.error.pick3limit', { maxCauses: maxCausesSelected })}
               content={t('cuases.error.pick3limitContent')}
               onClose={() => setOverSelectedCausesModalIsOpen(false)}
               open
            />
         )}
      </Box>
   );
};
