import { Box } from '@mui/material';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';

const NewFlowPaymentPage = () => {
   const location = useLocation();
   // const navigate = useNavigate();
   // const isNewflow1PaymentPage = location.pathname.includes(routes().newflow1.payment);
   const { cs: clientSecret } = location.state || {};
   const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
   const stripePromise = stripeKey && loadStripe(stripeKey);

   // const handleComplete = () => {
   //    isNewflow1PaymentPage && navigate(routes().newflow1.thankYou);
   // };
   return (
      <Box
         sx={{
            width: '100%',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '30px' },
            justifyContent: 'start',
            alignItems: 'center',
            py: { sm: 5 },
         }}
      >
         <Box sx={{ flex: '1', width: '100%' }}>
            <Box
               sx={{ flex: '1', width: '100%' }}
               component='div'
               width='100%'
               display='flex'
               flexDirection='column'
               alignItems='center'
            >
               <Box
                  sx={{
                     flex: '1',
                     width: '100%',
                     textAlign: 'left',
                     display: 'flex',
                     flexDirection: 'column',
                  }}
                  id='checkout'
               >
                  {clientSecret && stripePromise && (
                     <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{
                           clientSecret,
                           // onComplete: handleComplete,
                        }}
                     >
                        <EmbeddedCheckout />
                     </EmbeddedCheckoutProvider>
                  )}
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

export default NewFlowPaymentPage;
