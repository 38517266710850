import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import ModalComponent from 'pages/causes/modal';
import { causesState, handleSelectCause } from 'store/causes/causes.slice';
import { CauseTypes } from 'store/causes/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { preloadGif, preloadImage, shuffleArray } from 'utils/utils';
import { MAIN_ACTION_COLOR, NAV_BG_COLOR, NEW_FLOW1_MAX_CONTENT_WIDTH } from 'styles/constants';
import FixedGradientContainer from 'components/newFlow1/FixedGradientContainer';
import { routes } from 'routes/routes';
import { creatorQuotesState } from 'store/creatorQuotes/creatorQuotes.slice';
import CreatorQuote from 'components/newFlow1/CreatorQuote';

const NewFlowCausesPage = () => {
   const bgImage = '/images/getStartedFlow/bg-matching-mobile.png';
   const bgDesktopImage = '/images/getStartedFlow/bg-matching-desktop.png';
   const gif = '/images/getStartedFlow/badges-animation.gif';
   const isMobile = useMediaQuery('(max-width:599px)');

   const maxCausesSelected = Number(process.env.REACT_APP_MAX_CAUSES_SELECTION);
   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { creatorPhoto, creatorCauses, textLocizeParam } = useAppSelector(creatorQuotesState);
   const [sortedElements, setSortedElements] = useState<CauseTypes[]>([]);
   const [overSelectedCausesModalIsOpen, setOverSelectedCausesModalIsOpen] = useState<boolean>(false);
   const { causesList, selectedCausesList } = useAppSelector(causesState);

   const isSelected = (img: CauseTypes) => {
      return selectedCausesList.some((selectedImage) => selectedImage.id === img.id);
   };
   const getCauseStyle = (cause: CauseTypes) => {
      if (isSelected(cause)) return { border: `2px solid ${MAIN_ACTION_COLOR}`, backgroundColor: NAV_BG_COLOR };
      return { border: '2px solid #0000001A', backgroundColor: '#ffffff' };
   };

   const creatorCausesTitles =
      creatorCauses && creatorCauses.length > 0 ? creatorCauses.map((cause) => cause.title) : [];
   const getI18CreatorCausesData = useCallback((): Record<number, string> => {
      const data: Record<number, string> = {};

      if (creatorCausesTitles.length > 0) {
         creatorCausesTitles.forEach((title, idx) => {
            data[idx + 1] = title;
         });
      }
      return data;
   }, [creatorCauses]);

   const isNextDisabled = selectedCausesList.length === 0;

   const handleNext = async () => {
      if (selectedCausesList.length > 0) {
         localStorage.setItem('selectedCauses', JSON.stringify(selectedCausesList));
         analytics?.track('new_flow1_select_causes', {
            causes: selectedCausesList.map((cause) => cause.id),
            causesNames: selectedCausesList.map((cause) => cause.title),
         });
         // prefetch bg-images & animation gif
         try {
            await Promise.all([preloadImage(isMobile ? bgImage : bgDesktopImage), preloadGif(gif)]);
         } catch (error) {
            console.warn('Some assets failed to preload', error);
         }
         navigate(routes().newflow1.matchingAnimation);
      }
   };

   useEffect(() => {
      const sorted = shuffleArray<CauseTypes>(causesList);
      setSortedElements(sorted);
   }, [causesList]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            px: 2.5,
            pb: { xs: '110px', sm: '20px' },
         }}
      >
         <Box sx={{ mt: { xs: 3, sm: 5 }, mb: 2.5 }}>
            <Typography variant='custom-header'>{t('getStarted.causes.title')}</Typography>
         </Box>
         {!!sortedElements.length && (
            <Stack
               maxWidth={NEW_FLOW1_MAX_CONTENT_WIDTH}
               spacing='6px'
               direction='row'
               sx={{ flexWrap: 'wrap' }}
               justifyContent='center'
               useFlexGap
            >
               {sortedElements
                  .filter((cause) => cause.active)
                  .map((cause) => (
                     <Box
                        sx={{
                           ...{
                              p: '12px',
                              cursor: 'pointer',
                              width: 'calc((100% - 12px) / 3)',
                              aspectRatio: 1,
                              borderRadius: '8px',
                              boxSizing: 'border-box',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                           },
                           ...getCauseStyle(cause),
                        }}
                        onClick={() => {
                           if (
                              selectedCausesList?.length > 2 &&
                              !selectedCausesList?.some((causes) => causes.id === cause?.id)
                           ) {
                              analytics.track('max_causes_reached', {
                                 selectedCausesList: selectedCausesList.map((c) => c.title),
                              });
                              setOverSelectedCausesModalIsOpen(true);
                           }
                           dispatch(handleSelectCause(cause));
                        }}
                        key={cause.id}
                     >
                        <Box sx={{ width: '32px', height: '32px' }}>
                           <ReactSVG
                              src={`data:image/svg+xml;utf8,${encodeURIComponent(cause.impactImage)}`}
                              beforeInjection={(svg) => {
                                 svg.querySelectorAll('path').forEach((path) => {
                                    path.setAttribute('fill', '#333333');
                                 });
                                 svg.setAttribute('width', '32');
                                 svg.setAttribute('height', '32');
                              }}
                              style={{ width: '32px', height: '32px%' }}
                           />
                        </Box>
                        <Typography sx={{ fontSize: '14px', lineHeight: '17px', fontWeight: 600 }}>
                           {cause.title}
                        </Typography>
                     </Box>
                  ))}
            </Stack>
         )}
         {!!overSelectedCausesModalIsOpen && (
            <ModalComponent
               title={t('cuases.error.pick3limit', { maxCauses: maxCausesSelected })}
               content={t('cuases.error.pick3limitContent')}
               onClose={() => setOverSelectedCausesModalIsOpen(false)}
               open
            />
         )}
         {creatorPhoto && (
            <CreatorQuote
               creatorQuote={
                  textLocizeParam
                     ? t(`getStarted.causes.creatorQuote_${textLocizeParam}`)
                     : t('getStarted.causes.creatorQuote', getI18CreatorCausesData())
               }
               creatorPhoto={creatorPhoto}
            />
         )}
         <FixedGradientContainer>
            <Button disabled={isNextDisabled} onClick={handleNext} variant='primaryBtn'>
               <Typography>{t('getStarted.yearlyPage.CTA')}</Typography>
            </Button>
         </FixedGradientContainer>
      </Box>
   );
};

export default NewFlowCausesPage;
