import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo2 from 'components/Logo2';
import { routes } from 'routes/routes';
import { creatorQuotesState } from 'store/creatorQuotes/creatorQuotes.slice';
import { useAppSelector } from 'store/hooks';

const PlanbuildingAnimationPage = () => {
   const { t } = useTranslation();
   const { creatorPhoto } = useAppSelector(creatorQuotesState);
   const bgImage = '/images/getStartedFlow/bg-matching-mobile.png';
   const bgDesktopImage = '/images/getStartedFlow/bg-matching-desktop.png';
   const [renderTexts, setRenderTexts] = useState<string[]>([t('getStarted.planbuildingAnimation.checkbox1')]);
   const [showAnimation, setShowAnimation] = useState(false);
   const animationTexts = [
      t('getStarted.planbuildingAnimation.checkbox2'),
      t('getStarted.planbuildingAnimation.checkbox3'),
   ];
   const navigate = useNavigate();

   const handleNext = () => {
      navigate(routes().newflow1.signup);
   };

   const addText = () => {
      if (renderTexts.length < 3) {
         setRenderTexts((prev) => {
            if (prev.length < 3) return [...prev, animationTexts[prev.length - 1]];
            handleNext();
            return prev;
         });
         setShowAnimation(true); // Trigger animation
      }
   };

   useEffect(() => {
      const interval = setInterval(addText, 1500);
      return () => clearInterval(interval);
   }, []);

   useEffect(() => {
      if (showAnimation) {
         const timeout = setTimeout(() => setShowAnimation(false), 100); // Hide GIF after 1s
         return () => clearTimeout(timeout);
      }
      return undefined;
   }, [showAnimation]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'flex-start' },
            px: 2.5,
            pt: { sm: 12.5 },
            backgroundImage: { xs: `url(${bgImage})`, sm: `url(${bgDesktopImage})` },
            backgroundOrigin: 'border-box',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
         }}
      >
         <Box
            sx={{
               width: '100%',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <Logo2 isInContentPart />

            <Box sx={{ width: '100%', my: 3.75, textAlign: 'center' }}>
               <Typography variant='custom-header'>{t('getStarted.planbuildingAnimation.title')}</Typography>
            </Box>

            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
               }}
            >
               {creatorPhoto && (
                  <>
                     <motion.img
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2, ease: 'easeOut' }}
                        style={{ marginLeft: '10px', width: 60, height: 60, borderRadius: '50%' }}
                        src={creatorPhoto}
                        alt='creatorPhoto'
                     />
                     <motion.div
                        style={{ marginLeft: '8px', marginRight: '-8px' }}
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 3, ease: 'easeOut' }}
                     >
                        <Box sx={{ backgroundColor: '#FFFFFF', minWidth: 16, minHeight: 16, rotate: '45deg' }} />
                     </motion.div>
                  </>
               )}

               <Box
                  sx={{
                     borderRadius: '7px',
                     display: 'flex',
                     flexDirection: 'column',
                     overflow: 'hidden',
                     minHeight: { xs: creatorPhoto ? '300px' : '200px', sm: '200px' },
                  }}
               >
                  {renderTexts.length > 0 &&
                     renderTexts.map((text, index) => (
                        <Box
                           key={text}
                           sx={{
                              backgroundColor: '#FFFFFF',
                              display: 'flex',
                              flexDirection: 'row',
                              width: '100%',
                              alignItems: 'center',
                              px: 2,
                              py: 2.625,
                           }}
                        >
                           <img
                              style={{
                                 width: '24px',
                                 height: '24px',
                              }}
                              src={`/images/getStartedFlow/checkbox${index + 1}.gif`}
                              alt='animation'
                           />

                           <Typography
                              sx={{
                                 ml: '6px',
                                 fontWeight: 600,
                                 fontSize: '19px',
                                 lineHeight: '23px',
                                 color: '#666666',
                              }}
                           >
                              {text}
                           </Typography>
                        </Box>
                     ))}
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

export default PlanbuildingAnimationPage;
