import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import Intercom from '@intercom/messenger-js-sdk';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppRoutes } from 'routes/routes-data';
import { getCausesList, causesState, setSelectedCauses } from 'store/causes/causes.slice';
import { addRefCode, addCreatorCoupon, createUserSession } from 'store/auth/auth.slice';
import { addProject, commonState } from 'store/common/common.slice';
import { CauseTypes } from 'store/causes/types';
import i18next from './utils/i18n';

const App = () => {
   const isMobile = useMediaQuery('(max-width:599px)');

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const dispatch = useAppDispatch();

   const { causesList } = useAppSelector(causesState);
   const { project } = useAppSelector(commonState);

   useEffect(() => {
      if (causesList.length === 0) {
         dispatch(getCausesList());
      }
   }, [dispatch]);

   useEffect(() => {
      if (location.search) {
         const referalCode = searchParams.get('ref_id');
         const creatorCoupon = searchParams.get('cc');
         const projectCode = searchParams.get('p');

         // save the UTM params in the session storage
         const utmParams = {
            utm_source: searchParams.get('utm_source'),
            utm_medium: searchParams.get('utm_medium'),
            utm_campaign: searchParams.get('utm_campaign'),
            utm_term: searchParams.get('utm_term'),
            utm_content: searchParams.get('utm_content'),
         };
         // remove empty values
         const clearEmptyUtmParams = Object.fromEntries(Object.entries(utmParams).filter(([key, value]) => value));

         if (clearEmptyUtmParams) {
            clearEmptyUtmParams.location = location.pathname;
            sessionStorage.setItem('utm', JSON.stringify(clearEmptyUtmParams));
            dispatch(createUserSession());
         }

         if (referalCode) {
            dispatch(addRefCode(referalCode));
         }

         if (creatorCoupon) {
            dispatch(addCreatorCoupon(creatorCoupon));
         }

         if (projectCode || project) {
            // helene
            if (projectCode === 'helene' || project === 'helene') {
               dispatch(addProject(projectCode || project));
               i18next.setDefaultNamespace('helene');
            }
         }
      }
   }, [location.search, dispatch]);

   useEffect(() => {
      if (causesList.length > 0) {
         const preselectedCauses = searchParams.get('causes');
         if (preselectedCauses && preselectedCauses?.length > 0) {
            const idsArray = preselectedCauses.split(',');
            const causesArray: CauseTypes[] = [];
            idsArray.forEach((id) => {
               const cause = causesList.find((c) => c.id === Number(id));
               if (cause) {
                  causesArray.push(cause);
               }
            });
            causesArray.length > 0 && dispatch(setSelectedCauses(causesArray));
         }
      }
   }, [causesList]);

   // document.body.style.background = '#F7F7F7';
   /*
   useEffect(() => {
      if (location.pathname === routes().welcome) {
         document.body.style.background =
         'linear-gradient(168deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #4777F1';
         } else {
            document.body.style.background = '#F7F7F7';
      }
      
      return () => {
         document.body.style.background = '';
         };
         }, [location.pathname]); */

   useEffect(() => {
      window.analytics?.page();
      const root = document?.getElementById('root');
      if (root && isMobile) {
         root.scrollTop = 0;
      }
   }, [location.pathname, location.search]);

   if (process.env.REACT_APP_INTERCOM) {
      Intercom({
         app_id: process.env.REACT_APP_INTERCOM,
      });
   }

   return (
      <>
         <ScrollToTop />
         <AppRoutes />
      </>
   );
};

export default App;

const ScrollToTop = () => {
   const { pathname } = useLocation();
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   return null;
};
