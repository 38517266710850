import React, { useLayoutEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { useAppDispatch } from 'store/hooks';
import { checkMagicLink } from 'store/auth/auth.slice';
import { LoadingIcon } from 'components/common';

export const MagicLinkVerification = () => {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const [searchParams] = useSearchParams();

   const navigate = useNavigate();

   useLayoutEffect(() => {
      const validationCode = searchParams.get('code');
      const flow = searchParams.get('flow');
      if (validationCode) {
         dispatch(checkMagicLink(validationCode)).then(({ payload }) => {
            if (payload.response && payload.response.status === 400) {
               navigate(`${routes().loginEmail}`);
            } else if (payload.user?.subscription?.status === 'active') {
               navigate(routes().myProfile);
            } else {
               navigate(`${routes().welcome}?back=true`);
            }
         });
      }
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Box>
            <LoadingIcon />
         </Box>
      </Box>
   );
};
