import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getBase64FromUrl } from 'utils/utils';
import type { RootState } from 'store';

import {
   fetchCauses,
   sendSelectedCauses,
   getCharitiesBySelectedCauses,
   sendSingleCause,
   getCharities,
   getSelectedCauses,
   getCharitiesBySelectedCausesAnonymous,
   fetchSpecialCauseData,
} from './causes.services';
import type { CausesSliceTypes, CausesInfoTypes, CauseTypes, CharityTypes } from './types';

export const getCausesList = createAsyncThunk('causes/getCausesList', async (_, { rejectWithValue, dispatch }) => {
   try {
      const response = await fetchCauses();
      dispatch(storeReceivedCauses(response.data));
      return response.data;
   } catch (error) {
      if (error instanceof Error) {
         return rejectWithValue(error);
      }
      return error;
   }
});

export const getSpecialCauseData = createAsyncThunk(
   'causes/getSpecialCauseData',
   async ({ causeName }: { causeName: string }, { rejectWithValue, dispatch }) => {
      try {
         const response = await fetchSpecialCauseData({ causeName });
         dispatch(storeSpecialCauseData({ data: response.data, causeName }));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const handleSendSelectedCauses = createAsyncThunk(
   'causes/handleSendSelectedCauses',
   async (_, { rejectWithValue, getState }) => {
      const { selectedCausesList } = (getState() as RootState).causes;
      const { loggedUserData } = (getState() as RootState).auth;
      if (!loggedUserData) return selectedCausesList;

      try {
         const response = await sendSelectedCauses(selectedCausesList);
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const handleSendSingleCause = createAsyncThunk(
   'causes/handleSendSingleCause',
   async (_, { rejectWithValue, getState }) => {
      const { selectedCausesList } = (getState() as RootState).causes;
      try {
         const response = await sendSingleCause(selectedCausesList[0].id);
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchSelectedCharities = createAsyncThunk(
   'causes/fetchSelectedCharities',
   async (_, { rejectWithValue, dispatch, getState }) => {
      try {
         const response = await getCharities();
         dispatch(setCharityList(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchCharitiesByPassedSelectedCauses = createAsyncThunk(
   'causes/fetchCharitiesByPassedSelectedCauses',
   async (_, { rejectWithValue, dispatch, getState }) => {
      try {
         const { selectedCausesList } = (getState() as RootState).causes;
         const response = await getCharitiesBySelectedCausesAnonymous(selectedCausesList.map((c) => c.id));
         response.data.forEach((item: any) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = item.icon;
            document.head.appendChild(link);
         });
         dispatch(setCharityList(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchSelectedCauses = createAsyncThunk(
   'causes/fetchSelectedCauses',
   async (_, { rejectWithValue, dispatch, getState }) => {
      try {
         const response = await getSelectedCauses();
         dispatch(setSelectedCauses(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const fetchCharitiesBySelectedCauses = createAsyncThunk(
   'causes/fetchCharitiesBySelectedCauses',
   async (_, { rejectWithValue, getState, dispatch }) => {
      try {
         const { loggedUserData, signupData } = (getState() as RootState).auth;
         const { selectedCausesList } = (getState() as RootState).causes;
         let response = null;
         if (loggedUserData) {
            response = await getCharitiesBySelectedCauses();
         } else {
            response = await getCharitiesBySelectedCausesAnonymous(
               selectedCausesList.map((c) => c.id),
               signupData?.referralCode
            );
         }
         response.data.forEach((item: any) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = item.icon;
            document.head.appendChild(link);
         });
         dispatch(setCharityList(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const getFormattedCauses = createAsyncThunk(
   'causes/getFormattedCauses',
   // eslint-disable-next-line consistent-return
   async (_, { rejectWithValue, dispatch, getState }) => {
      try {
         const { selectedCausesList } = (getState() as RootState).causes;
         const updateBase64Images = async () => {
            const modified = await Promise.all(
               selectedCausesList.map(async (cause) => {
                  const icon = await getBase64FromUrl(cause.icon);
                  return {
                     ...cause,
                     icon,
                     title: cause.title,
                  } as CauseTypes;
               })
            );
            return modified;
         };
         updateBase64Images().then((data) => {
            dispatch(handleFormatCauses(data));
         });
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

const initialState: CausesSliceTypes = {
   causesList: [],
   selectedCausesList: [],
   convertedCausesList: [],
   charityList: [],
   specialCauseData: null,
};

export const causesSlice = createSlice({
   name: 'causesSlice',
   initialState,
   reducers: {
      storeReceivedCauses(state, action: PayloadAction<CausesInfoTypes>) {
         const { data } = action.payload;
         return {
            ...state,
            causesList: data,
         };
      },

      storeSpecialCauseData(state, action: PayloadAction<{ data: any; causeName: string }>) {
         const { data, causeName } = action.payload;
         return {
            ...state,
            specialCauseData: { ...state.specialCauseData, [causeName]: data },
         };
      },

      handleSelectCause(state, action: PayloadAction<CauseTypes>) {
         const { id } = action.payload;
         const isExist = state.selectedCausesList.find((cause) => cause.id === id);

         if (isExist) {
            return {
               ...state,
               selectedCausesList: state.selectedCausesList.filter((image) => image.id !== id),
            };
         }
         if (state.selectedCausesList.length < 3) {
            return {
               ...state,
               selectedCausesList: [...state.selectedCausesList, action.payload],
            };
         }
         return state;
      },

      handleFormatCauses(state, action: PayloadAction<CauseTypes[]>) {
         return {
            ...state,
            convertedCausesList: action.payload,
         };
      },

      setCharityList(state, action: PayloadAction<CharityTypes[]>) {
         return {
            ...state,
            charityList: action.payload,
         };
      },

      setSelectedCauses(state, action: PayloadAction<CauseTypes[]>) {
         return {
            ...state,
            selectedCausesList: action.payload,
         };
      },
      clearCausesData() {
         return initialState;
      },
   },
});

export const {
   storeReceivedCauses,
   storeSpecialCauseData,
   handleSelectCause,
   handleFormatCauses,
   setCharityList,
   setSelectedCauses,
   clearCausesData,
} = causesSlice.actions;

export const causesState = (state: RootState) => state.causes;
