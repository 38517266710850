import api from 'api';

import { CauseTypes, CharityTypes } from 'store/causes/types';
import { routes } from 'routes/routes';
import type { AppleNameFormat, SignupDataTypes, UserDataUpdateType } from './types';

const API_DOMAIN = process.env.REACT_APP_BASE_URL;

export const getStripePayment = (money: number, serviceFee?: number) => {
   return api.post('/v1/payment/stripe/subscriptions', {
      amount: money * 100,
      serviceFee: serviceFee ? serviceFee * 100 : 0,
      successUrl: `${API_DOMAIN}/gratitude?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${API_DOMAIN}/subscription`,
   });
};
export const getStripePaymentEmbedded = async ({
   money,
   serviceFee,
   extraParam,
   metaData,
   couponCode,
   couponCodeID,
}: {
   couponCode?: string;
   couponCodeID?: string;
   money: number;
   serviceFee?: number;
   extraParam?: string;
   metaData?: {
      ref_id?: string;
      flow?: string;
      sytemSessionId?: string;
   };
}) => {
   const getPayload = () => {
      const payload = {
         amount: money * 100,
         serviceFee: serviceFee ? serviceFee * 100 : 0,
         embedded: true,
         returnUrl: `${API_DOMAIN}${routes().paymentCheck}?session_id={CHECKOUT_SESSION_ID}${extraParam ? `&${extraParam}` : ''}`,
         metaData,
      };

      if (couponCode) {
         return { ...payload, couponCode };
      }

      if (couponCodeID) {
         return { ...payload, couponCodeID };
      }

      return payload;
   };
   try {
      const payload = getPayload();
      const res = await api.post('/v1/payment/stripe/subscriptions', payload);
      if (res?.data && res.data?.clientSecret) {
         const parsedData = JSON.parse(JSON.stringify(res.data));
         sessionStorage.setItem(parsedData.checkoutSessionId, JSON.stringify(parsedData.items));
         return res.data.clientSecret;
      }
      return new Error('Server error');
   } catch (error: any) {
      throw new Error(error);
   }
};

export const getAnonymousStripePaymentEmbedded = async ({
   money,
   serviceFee,
   extraParam,
   metaData,
}: {
   money: number;
   serviceFee?: number;
   extraParam?: string;
   metaData?: {
      ref_id?: string;
      flow?: string;
      sytemSessionId?: string;
   };
}) => {
   try {
      const res = await api.post('/v1/payment/stripe/subscriptionAnonymous', {
         amount: money * 100,
         serviceFee: serviceFee ? serviceFee * 100 : 0,
         embedded: true,
         returnUrl: `${API_DOMAIN}${routes().paymentCheck}?session_id={CHECKOUT_SESSION_ID}${extraParam ? `&${extraParam}` : ''}`,
         metaData,
      });
      if (res?.data && res.data?.clientSecret) {
         const parsedData = JSON.parse(JSON.stringify(res.data));
         sessionStorage.setItem(parsedData.checkoutSessionId, JSON.stringify(parsedData.items));
         return res.data.clientSecret;
      }
      return new Error('Server error');
   } catch (error: any) {
      throw new Error(error);
   }
};

export const updateUser = async (data: UserDataUpdateType) => {
   try {
      if (Object.keys(data).length === 0) {
         throw new Error('Data to update is empty');
      }
      const res = await api.patch('/v1/users/me', {
         ...data,
      });
      return res;
   } catch (error: any) {
      throw new Error(error);
   }
};

export const checkPayment = async (sessionId: string) => {
   try {
      const res = await api.get(`/v1/payment/stripe/subscriptions/check/${sessionId}`);
      if (res?.data) return res.data;
      return new Error('Server error');
   } catch (error: any) {
      throw new Error(error);
   }
};

export const createStripeSubscription = (money: number, serviceFee?: number, promoCode?: string | null) => {
   return api.post('/v1/payment/stripe/createSubscriptions', {
      amount: money * 100,
      serviceFee: serviceFee ? serviceFee * 100 : 0,
      successUrl: `${API_DOMAIN}/${routes().creatorFlow.base}/${
         routes().creatorFlow.gratitude
      }?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${API_DOMAIN}/donation`,
      couponCode: promoCode,
   });
};

export const getStripeFromProfile = () => {
   return api.get('/v1/payment/stripe/portal');
};

export const registerUser = (data: SignupDataTypes) => {
   return api.post('/v1/auth/register', data);
};

export const registerUserAnonymous = (data: SignupDataTypes, causes: CauseTypes[], charities: CharityTypes[]) => {
   return api.post('/v1/auth/register/anonymous', {
      ...data,
      causes: causes.map((c) => c.id),
      charities: charities.map((c) => c.id),
   });
};

export const registerUserAnonymousUpdate = (data: SignupDataTypes) => {
   return api.patch('/v1/users/update/anonymous', data);
};

export const loginWithGoogle = (googleToken: string) => {
   return api.put('/v1/auth/login/google', { token: googleToken });
};

export const loginWithGoogleAnonymous = (
   googleToken: string,
   data?: SignupDataTypes,
   causes?: CauseTypes[],
   charities?: CharityTypes[]
) => {
   const userData = {
      ...data,
      causes: causes?.map((c) => c.id),
      charities: charities?.map((c) => c.id),
   };

   return api.put('/v1/auth/login/google', { token: googleToken, ...userData });
};

export const loginWithReturnUserEmail = ({ email }: { email: string }) => {
   return api.get(`/v1/auth/login/flowReturn/${email}`);
};

export const loginUser = (data: { email: string; password: string }) => {
   return api.post('/v1/auth/login', data);
};

export const loginWithApple = (
   appleToken: string,
   fullName: AppleNameFormat,
   data?: SignupDataTypes,
   causes?: CauseTypes[],
   charities?: CharityTypes[]
) => {
   const userData = {
      ...data,
      causes: causes?.map((c) => c.id),
      charities: charities?.map((c) => c.id),
   };
   return api.put('/v1/auth/login/apple', { token: appleToken, fullName, ...userData });
};

export const getUserByAccessToken = () => {
   return api.get(`/v1/users/me`);
};

export const getUserBySlug = (slug: string) => {
   return api.get(`/v1/publicProfile/slug/${slug}`);
};

export const getVerificationCode = (email: string) => {
   return api.get(`/v1/auth/verify/password.forgot/email=${email}`);
};

export const updatePasswordWithCode = (userData: { email: string; password: string; code: string }) => {
   return api.put(`/v1/auth/passwords/forgot`, userData);
};

export const signInWithMagicLink = (data: { email: string; mobile?: boolean }, flow?: string) => {
   return api.post(`/v1/auth/login/magicLink`, {
      ...data,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/ml${flow ? `?flow=${flow}` : ''}`,
   });
};

export const validateMagicLinkCode = (code: string) => {
   return api.get(`/v1/auth/login/magicLink/verify/${code}`);
};

export const sendVerificationCodeMagicLink = (email: string, type: string) => {
   return api.get(`/v1/auth/verify/${type}/email=${email}`);
};

export const createSession = (utmParams: object) => {
   return api.post(`/v1/integrations/session/create`, utmParams);
};
