import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import { routes } from 'routes/routes';
import { MAIN_ACTION_COLOR, NAV_BG_COLOR } from 'styles/constants';

const StepIndicator = () => {
   const { pathname } = useLocation();

   const steps = [
      { route: routes().newflow1.causes, stepNumber: 1 },
      { route: routes().newflow1.portfolio, stepNumber: 2 },
      { route: routes().newflow1.subscription, stepNumber: 3 },
      { route: routes().newflow1.signup, stepNumber: 4 },
   ];
   const currentStep = steps.find((step) => pathname.includes(step.route));

   const getBoxStepStyle = (stepIdx: number) => {
      if (!currentStep) return {};
      const isCurrentStep = steps[stepIdx].stepNumber === currentStep?.stepNumber;
      if (isCurrentStep) {
         return { backgroundColor: MAIN_ACTION_COLOR };
      }
      return { backgroundColor: '#FFFFFF' };
   };

   const getTypographyStepStyle = (stepIdx: number) => {
      if (!currentStep) return {};
      const isCurrentStep = steps[stepIdx].stepNumber === currentStep?.stepNumber;
      if (isCurrentStep) {
         return { color: '#FFFFFF' };
      }
      return { color: '#999999' };
   };
   return currentStep ? (
      <Box
         display='flex'
         flexDirection='row'
         sx={{
            position: { sm: 'fixed' },
            zIndex: 501,
            mt: { xs: '-16px', sm: '44px' },
            columnGap: 1,
            width: '100vw',
            height: '32px',
            justifyContent: 'center',
         }}
      >
         {steps.map((step, idx) => (
            <Box
               sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `1px solid ${NAV_BG_COLOR}`,
                  width: 32,
                  height: 32,
                  borderRadius: '50%',
                  ...getBoxStepStyle(idx),
               }}
               key={step.route}
            >
               {step.stepNumber < currentStep.stepNumber ? (
                  <DynamicGoogleIcon iconColor='#999999' iconName='check_small' />
               ) : (
                  <Typography
                     sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: '14px',
                        ...getTypographyStepStyle(idx),
                     }}
                  >
                     {step.stepNumber}
                  </Typography>
               )}
            </Box>
         ))}
      </Box>
   ) : null;
};

export default StepIndicator;
