import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';
import { NEW_FLOW1_MAX_CONTENT_WIDTH } from 'styles/constants';

const NewFlowFooter = () => {
   const { t } = useTranslation();
   const location = useLocation();
   const isHighScreen = useMediaQuery('(min-height:670px)');
   const isDesktop = useMediaQuery('(min-width:1090px)');
   const isNewflow1SummaryPage = location.pathname.includes(routes().newflow1.summary);
   const showWideContent = isDesktop && isNewflow1SummaryPage;
   const isSeaspiracyFlow = location.pathname.includes(routes().seaspiracy.base);
   return (
      <Box
         sx={{
            pt: 3.75,
            pb: isSeaspiracyFlow ? 3.75 : { xs: !isHighScreen ? 15 : 3.75, sm: 3.75 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#EFCAC9',
            zIndex: 2,
         }}
      >
         <Box>
            <svg width={80} height={35} viewBox='0 0 80 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
               <g clipPath='url(#clip0_16995_22438)'>
                  <path
                     d='M6.67892 34.9981C6.48158 34.9981 6.29071 34.9399 6.12573 34.8331L1.38638 31.7534C1.11787 31.5819 0.949647 31.2746 0.936707 30.9284L0.927002 6.1997C0.927002 5.71767 1.26992 5.30035 1.739 5.20654L7.30005 4.08074C7.36152 4.0678 7.42945 4.06133 7.49415 4.06133C7.68179 4.06133 7.87265 4.11633 8.04088 4.21985L12.7802 7.29638C13.0681 7.48401 13.2428 7.80105 13.2428 8.1472V10.9487C14.3428 11.0037 15.3553 11.2949 16.2579 11.8125L16.2644 11.806L16.5588 11.9969L20.3082 14.4297C22.0033 10.3438 26.5583 7.2058 30.8933 7.2058C32.3943 7.2058 33.7951 7.56489 35.0568 8.27013L35.0665 8.25719L35.3738 8.45129L38.9097 10.7449C40.262 6.892 44.309 4.04192 48.5275 4.04192C49.4107 4.04192 50.2712 4.17132 51.0864 4.42366C51.8661 4.6404 53.5516 5.76297 54.907 6.66878C55.2597 6.90494 55.5799 7.11845 55.8387 7.28667C57.521 3.17169 62.0888 0.0078125 66.4529 0.0078125C68.0769 0.0078125 69.578 0.425134 70.9205 1.24684L75.6566 4.32013C77.6139 5.55592 78.9143 7.9078 79.0437 10.457C79.2864 13.5336 78.1541 16.6845 75.9284 19.0946C73.6897 21.5209 70.6132 22.912 67.4914 22.9152C65.8868 22.9152 64.4278 22.4979 63.1499 21.6729L60.1543 19.7287V23.7434C60.1543 24.2254 59.8146 24.6427 59.3423 24.7365L44.2831 27.7872C44.2152 27.8001 44.1505 27.8066 44.0826 27.8066C43.8852 27.8066 43.6944 27.7516 43.5294 27.6416L40.7763 25.8559C38.6153 28.4827 35.2573 30.1067 31.935 30.1164C30.3304 30.1164 28.8714 29.6991 27.5935 28.8742L23.8861 26.4673C22.8768 29.2947 20.8096 31.6272 18.2895 32.7627C17.0764 33.3094 15.8276 33.5973 14.6857 33.5973C14.1001 33.5973 13.5405 33.5229 13.0131 33.3741C12.874 33.6329 12.6249 33.8238 12.3208 33.8853L6.88596 34.9852C6.82126 34.9981 6.75333 35.0046 6.68539 35.0046L6.67892 34.9981Z'
                     fill='#252626'
                  />
                  <path
                     d='M18.8491 19.1887C18.9656 15.572 16.8369 11.8937 12.8999 11.9519C10.933 11.9455 8.96281 12.6604 7.5038 13.9771V5.07422L1.94922 6.20002V30.919C3.07178 30.6926 6.31007 30.0359 7.3841 29.8191V28.8324C9.64217 30.1491 12.5537 29.3597 14.5724 27.9363C17.3286 25.9856 18.8879 22.5629 18.8459 19.1855L18.8491 19.1887ZM13.204 20.8095C13.0907 22.165 12.2884 23.4622 11.0526 24.0478C9.89127 24.6527 8.2608 24.4748 7.60409 23.2293C6.18713 20.596 8.704 16.5942 11.7967 17.7006C12.984 18.1956 13.3301 19.5737 13.204 20.8095Z'
                     fill='white'
                  />
                  <path
                     d='M37.5735 15.5909C37.4118 5.93754 25.6685 6.95011 21.7703 13.6887C18.5805 18.6836 20.3145 26.2828 27.0726 26.2601C32.6918 26.2439 37.6512 21.2005 37.5735 15.5877V15.5909Z'
                     fill='white'
                  />
                  <path
                     d='M48.8478 21.7923L54.4089 20.6665V11.0713C54.4898 6.67168 50.9344 4.28745 46.8389 5.24502C44.0147 5.79822 41.5852 7.57103 40.2944 10.0944C39.6603 11.3302 39.3433 12.6727 39.3433 14.1188V23.7139L44.9075 22.5881V12.993C44.8849 11.6957 45.6193 10.6055 46.8712 10.3564C48.0585 10.0394 48.9158 11.0034 48.8414 12.1971L48.8446 21.7923H48.8478Z'
                     fill='white'
                  />
                  <path
                     d='M73.2563 6.94737C71.9105 -1.82285 60.8046 0.166706 57.4013 6.4427C54.231 11.6964 56.3952 19.2729 63.3603 18.8006C69.2028 18.4803 74.0456 12.7705 73.2595 6.94737H73.2563Z'
                     fill='white'
                  />
                  <path d='M22.77 15.553L25.853 11.8359L26.6327 12.5444L23.5497 16.2647L22.77 15.553Z' fill='#252626' />
                  <path
                     d='M22.8154 19.8311L30.2916 10.8086L31.0745 11.5203L23.5951 20.5396L22.8154 19.8311Z'
                     fill='#252626'
                  />
                  <path
                     d='M58.4592 8.0452L61.539 4.32812L62.3219 5.0366L59.2389 8.75691L58.4592 8.0452Z'
                     fill='#252626'
                  />
                  <path
                     d='M58.5012 12.3201L65.9807 3.30078L66.7635 4.00926L59.2841 13.0286L58.5012 12.3201Z'
                     fill='#252626'
                  />
               </g>
               <defs>
                  <clipPath id='clip0_16995_22438'>
                     <rect width={78.1459} height={35} fill='white' transform='translate(0.927002)' />
                  </clipPath>
               </defs>
            </svg>
         </Box>
         {/* Social Icons */}
         <Box sx={{ mt: 1.25, mb: 2.5, display: 'flex', flexDirection: 'row', columnGap: 1.25 }}>
            <Link href='https://www.linkedin.com/company/thebonoway/' target='_blank' rel='noopener noreferrer'>
               <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M20.47 2H3.53a1.45 1.45 0 0 0-1.47 1.43v17.14A1.45 1.45 0 0 0 3.53 22h16.94a1.45 1.45 0 0 0 1.47-1.43V3.43A1.45 1.45 0 0 0 20.47 2M8.09 18.74h-3v-9h3zM6.59 8.48a1.56 1.56 0 0 1 0-3.12 1.57 1.57 0 1 1 0 3.12m12.32 10.26h-3v-4.83c0-1.21-.43-2-1.52-2A1.65 1.65 0 0 0 12.85 13a2 2 0 0 0-.1.73v5h-3v-9h3V11a3 3 0 0 1 2.71-1.5c2 0 3.45 1.29 3.45 4.06z'
                     fill='#333'
                  />
               </svg>
            </Link>
            <Link href='https://www.instagram.com/thebonoway/' target='_blank' rel='noopener noreferrer'>
               <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10m0 8a3 3 0 1 1 0-5.999A3 3 0 0 1 12 15m5-9a1 1 0 1 0 0 2 1 1 0 0 0 0-2m4.94 2.24a7.5 7.5 0 0 0-.48-2.5 5.56 5.56 0 0 0-3.2-3.2 7.5 7.5 0 0 0-2.5-.48C14.79 2 14.47 2 12 2s-2.79 0-3.76.06a7.5 7.5 0 0 0-2.5.48 5.56 5.56 0 0 0-3.2 3.2 7.5 7.5 0 0 0-.48 2.5C2 9.22 2 9.54 2 12s0 2.78.06 3.76c.017.855.179 1.7.48 2.5a5.56 5.56 0 0 0 3.2 3.2 7.5 7.5 0 0 0 2.5.48c1 0 1.29.06 3.76.06s2.79 0 3.76-.06a7.5 7.5 0 0 0 2.5-.48 5.56 5.56 0 0 0 3.2-3.2 7.5 7.5 0 0 0 .48-2.5c0-1 .06-1.3.06-3.76s0-2.78-.06-3.76m-2 7.43a6 6 0 0 1-.35 1.86 3.4 3.4 0 0 1-.82 1.25 3.26 3.26 0 0 1-1.25.81 5.8 5.8 0 0 1-1.87.36c-.94 0-1.23.05-3.66.05H8.34a6 6 0 0 1-1.87-.35 3.4 3.4 0 0 1-1.25-.82 3.26 3.26 0 0 1-.81-1.25 5.7 5.7 0 0 1-.36-1.86V8.38a5.9 5.9 0 0 1 .36-1.87 3.3 3.3 0 0 1 .81-1.24 3.26 3.26 0 0 1 1.25-.81 5.8 5.8 0 0 1 1.87-.36C9.28 4 9.57 4 12 4h3.66a5.9 5.9 0 0 1 1.88.36 3.3 3.3 0 0 1 1.24.81c.363.349.64.776.81 1.25A5.7 5.7 0 0 1 20 8.33c0 1 .05 1.25.05 3.67S20 14.71 20 15.67z'
                     fill='#333'
                  />
               </svg>
            </Link>
            <Link href='mailto:contact@bono.so' target='_blank' rel='noopener noreferrer'>
               <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                     d='M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3M5 6h14a1 1 0 0 1 1 1l-8 4.88L4 7a1 1 0 0 1 1-1m15 11a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9.28l7.48 4.57a1 1 0 0 0 1 0L20 9.28z'
                     fill='#333'
                  />
               </svg>
            </Link>
         </Box>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'row',
               columnGap: 2.5,
               mb: 2.5,
            }}
         >
            <Link href='https://www.bono.so/tc' target='_blank' rel='noopener noreferrer'>
               <Typography
                  sx={{
                     fontSize: '12px',
                     lineHeight: '14px',
                     fontWeight: 600,
                     textDecoration: 'underline',
                  }}
               >
                  {t('termsOfUse')}
               </Typography>
            </Link>
            <Link href='https://www.bono.so/privacy-policy' target='_blank' rel='noopener noreferrer'>
               <Typography
                  sx={{
                     fontSize: '12px',
                     lineHeight: '14px',
                     fontWeight: 600,
                     textDecoration: 'underline',
                  }}
               >
                  {t('privacyPolicy')}
               </Typography>
            </Link>
            <Link href='https://bono.onelink.me/uiDA/app' target='_blank' rel='noopener noreferrer'>
               <Typography
                  sx={{
                     fontSize: '12px',
                     lineHeight: '14px',
                     fontWeight: 600,
                     textDecoration: 'underline',
                  }}
               >
                  {t('mobileApp')}
               </Typography>
            </Link>
         </Box>
         <Box
            sx={{
               maxWidth: showWideContent ? '720px' : NEW_FLOW1_MAX_CONTENT_WIDTH,
               display: 'flex',
               flexDirection: 'column',
               rowGap: 2.5,
            }}
         >
            <Typography sx={{ color: '#666666', fontSize: '12px', lineHeight: '14px' }} textAlign='center'>
               {t('getStarted.footer.allRightsReserved')}
            </Typography>
            <Typography sx={{ color: '#666666', fontSize: '12px', lineHeight: '14px' }} textAlign='center'>
               {t('getStarted.footer.text')}
            </Typography>
         </Box>
      </Box>
   );
};

export default NewFlowFooter;
