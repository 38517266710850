import { Box } from '@mui/material';

import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BackIconButton } from 'components/common';
import { commonState } from 'store/common/common.slice';
import { useAppSelector } from 'store/hooks';
import Logo2 from 'components/Logo2';
import { NAV_BG_COLOR, NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS } from 'styles/constants';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import useThemePage from 'hooks/useThemePage';
import { authState } from 'store/auth/auth.slice';
import StepIndicator from 'components/newFlow1/StepIndicator';
import NewFlowFooter from 'components/newFlow1/NewFlowFooter';
import { newFlow1State } from 'store/newFlow1/newFlow1.slice';
import { routes } from '../../routes/routes';

interface IHeader {
   handleBackPress?: () => void;
   layoutTheme?: string;
}

export const Header: React.FC<IHeader> = ({ handleBackPress, layoutTheme }): React.ReactElement | null => {
   const { theme } = useThemePage();
   const showThemePage = !!theme;
   const isMobile = useMediaQuery('(max-width:600px)');
   const navigate = useNavigate();
   const location = useLocation();
   const { loggedUserData } = useAppSelector(authState);
   const { project } = useAppSelector(commonState);
   const { returnedUser } = useAppSelector(newFlow1State);

   // Theme-based configurations
   const themeConfig: Record<
      string,
      { minHeightXS: string; navBackground: string; hideBackButton?: boolean; buttonTop?: string }
   > = {
      newflow1: { minHeightXS: '110px', navBackground: NAV_BG_COLOR, buttonTop: '0px' },
      seaspiracy: { minHeightXS: '72px', navBackground: NAV_BG_COLOR, buttonTop: '0px' },
      default: { minHeightXS: '90px', navBackground: 'none' },
   };

   const { minHeightXS, navBackground, buttonTop } = layoutTheme
      ? (themeConfig[layoutTheme] ?? themeConfig.default)
      : themeConfig.default;

   const shouldShowBackButton = (): boolean => {
      const isNewflow1ReturnedUserOnCausesPage = location.pathname.includes(routes().newflow1.causes) && returnedUser;
      const isPortfolioHelene = location.pathname === routes().portfolio && project === 'helene';
      const isSeaspiracyIntro = location.pathname === routes().seaspiracy.intro;
      const isGetStartedWelcomePage = location.pathname.includes(routes().newflow1.welcome);
      const isGeneratingPortfolioPage = location.pathname.includes(routes().generatePage);

      return (
         !isGeneratingPortfolioPage &&
         !isPortfolioHelene &&
         !isNewflow1ReturnedUserOnCausesPage &&
         !isSeaspiracyIntro &&
         !isGetStartedWelcomePage
      );
   };

   const hideBackButtonRoutes = ['/share', '/'];
   const showBackButton = shouldShowBackButton() && !hideBackButtonRoutes.includes(location.pathname);

   const handleBack = () => {
      if (handleBackPress) {
         handleBackPress();
      } else if (location.pathname === routes().portfolio) {
         navigate(routes().causes);
      } else {
         navigate(-1);
      }
   };

   if (showThemePage && isMobile && !loggedUserData) return null;

   return (
      <Box
         component='nav'
         width='100%'
         minHeight={{ xs: minHeightXS, sm: '60px' }}
         display='flex'
         sx={{
            position: { sm: 'fixed' },
            px: { sm: 3.75 },
            background: { xs: navBackground, sm: NAV_BG_COLOR },
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 500,
         }}
      >
         {showBackButton && !hideBackButtonRoutes.includes(location.pathname) && (
            <BackIconButton
               sx={{
                  position: 'relative',
                  display: { xs: 'flex', sm: 'none' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: !layoutTheme ? 0 : { xs: buttonTop ?? '30px', sm: '90px' },
                  left: { xs: '20px', sm: '40px' },
               }}
               onClick={handleBack}
            >
               <DynamicGoogleIcon iconColor='#999999' iconName='arrow_back' />
            </BackIconButton>
         )}
         {(location.pathname === '/' && isMobile) || !isMobile || (isMobile && layoutTheme) ? (
            <Box
               sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'start' },
                  mt: { xs: '34px', sm: '0' },
                  mb: { xs: '36px', sm: '0' },
                  mr: showBackButton ? '48px' : 0,
               }}
            >
               <Logo2 />
            </Box>
         ) : null}
      </Box>
   );
};

export const PublicLayout = (): React.ReactElement => {
   const isMobile = useMediaQuery('(max-width:600px)');
   const location = useLocation();
   const { loggedUserData } = useAppSelector(authState);
   const { theme, themedBG } = useThemePage();
   const showThemePage = !!theme;
   const bg = isMobile
      ? {
           backgroundImage: themedBG?.mobile,
           backgroundSize: 'cover',
           backgroundRepeat: 'no-repeat',
           backgroundPosition: 'top',
        }
      : {
           backgroundImage: themedBG?.desktop,
           backgroundSize: 'cover',
           backgroundRepeat: 'no-repeat',
           backgroundPosition: 'left top',
        };
   const backgroundImageData = showThemePage && !loggedUserData ? bg : {};

   return (
      <Box
         sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100dvh',
            backgroundColor: '#F7F7F7',
            ...backgroundImageData,
         }}
         component='main'
      >
         {location.pathname !== routes().mobileRedirect && <Header />}
         <Box
            sx={{
               pt: { xs: 0, sm: '130px' },
               position: 'relative',
               display: 'flex',
               flex: 1,
               alignSelf: 'center',
               width: '100%',
               // overflow: { xs: 'auto', sm: 'visible' },
               '&::-webkit-scrollbar': { display: 'none' },
            }}
         >
            <Outlet />
         </Box>
      </Box>
   );
};

export const NewFlow1Layout = (): React.ReactElement => {
   const location = useLocation();
   const isMobile = useMediaQuery('(max-width:600px)');
   const isDesktop = useMediaQuery('(min-width:1090px)');
   const navigate = useNavigate();
   const { returnedUser } = useAppSelector(newFlow1State);

   const getNewflow1MaxWidth = () => {
      if (isDesktop) {
         if (location.pathname.includes(routes().newflow1.summary)) return '1090px';
         if (location.pathname.includes(routes().newflow1.portfolio)) return '1110px';
         if (location.pathname.includes(routes().newflow1.payment)) return '1000px';
      }
      return NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS;
   };

   const getSeaspiracyMaxWidth = () => {
      if (!isMobile) {
         if (location.pathname.includes(routes().seaspiracy.payment)) {
            return '1000px';
         }
         return '720px';
      }
      return undefined;
   };

   // Define theme settings for different sections of the app
   const themeConfig: Record<
      string,
      {
         showHeader: boolean;
         showFooter: boolean;
         maxWidth?: string;
         backgroundColor: string;
         themeName: string;
      }
   > = {
      [routes().newflow1.base]: {
         themeName: 'newflow1',
         showHeader: ![
            routes().newflow1.matchingAnimation,
            routes().newflow1.thankYou,
            routes().newflow1.planbuildingAnimation,
         ].some((path) => location.pathname.includes(path)),
         showFooter: location.pathname.includes(routes().newflow1.summary),
         maxWidth: getNewflow1MaxWidth(),
         backgroundColor: '#EBE9E5',
      },
      [routes().seaspiracy.base]: {
         themeName: 'seaspiracy',
         showHeader: true,
         showFooter: location.pathname.includes(routes().seaspiracy.intro),
         maxWidth: getSeaspiracyMaxWidth(),
         backgroundColor: '#F8F9FA',
      },
      // Add more themes here
   };

   // Determine the current theme dynamically
   const currentTheme =
      Object.entries(themeConfig).find(([routePrefix]) => location.pathname.startsWith(routePrefix))?.[1] ||
      themeConfig[routes().newflow1.base]; // Default to newflow1 if no match

   const getBackPressHandler = () => {
      const backRoutes: Record<string, string> = {
         [routes().newflow1.causes]: returnedUser ? routes().newflow1.summary : routes().newflow1.welcome,
         [routes().newflow1.portfolio]: routes().newflow1.causes,
         [routes().newflow1.subscription]: routes().newflow1.portfolio,
         [routes().newflow1.signup]: routes().newflow1.subscription,
         [routes().newflow1.summary]: routes().newflow1.signup,
         [routes().newflow1.payment]: routes().newflow1.summary,
      };

      for (const [route, backRoute] of Object.entries(backRoutes)) {
         if (location.pathname.includes(route)) {
            return () => navigate(backRoute);
         }
      }

      return undefined;
   };

   useEffect(() => {
      const root = document.getElementById('root');
      if (root) {
         root.style.backgroundColor = currentTheme.backgroundColor;
      }

      const handleBackPress = (event: PopStateEvent) => {
         event.preventDefault();
         const backHandler = getBackPressHandler();
         if (backHandler) backHandler();
      };

      if ([routes().newflow1.portfolio, routes().newflow1.signup].some((path) => location.pathname.includes(path))) {
         window.history.pushState(null, '', window.location.href);
         window.addEventListener('popstate', handleBackPress);

         return () => {
            window.removeEventListener('popstate', handleBackPress);
         };
      }

      return () => undefined;
   }, [location.pathname, navigate]);

   return (
      <Box
         sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100dvh',
            backgroundColor: currentTheme.backgroundColor,
         }}
         component='main'
      >
         {(currentTheme.showHeader || !isMobile) && (
            <Header layoutTheme={currentTheme.themeName} handleBackPress={getBackPressHandler()} />
         )}
         {location.pathname.includes(routes().newflow1.base) && <StepIndicator />}
         <Box
            sx={{
               backgroundColor: currentTheme.backgroundColor,
               maxWidth: currentTheme.maxWidth,
               pt: { xs: 0, sm: '60px' },
               position: 'relative',
               display: 'flex',
               flex: 1,
               alignSelf: 'center',
               width: '100%',
               '&::-webkit-scrollbar': { display: 'none' },
            }}
         >
            <Outlet />
         </Box>
         {currentTheme.showFooter && <NewFlowFooter />}
      </Box>
   );
};

export const NoLayout = (): React.ReactElement => {
   return <Outlet />;
};
