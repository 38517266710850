export const routes = () => ({
   welcome: '/',
   intro: '/intro',
   email: '/email',
   save: '/save',
   password: '/password',
   loginEmail: '/loginEmail',
   loginPassword: '/loginPassword',
   newPassword: '/newPassword',
   resetPassword: '/resetPassword',
   forgotPassword: '/forgotPassword',
   anonymousConnect: '/saveProfile',
   loadingProfile: '/loadingProfile',
   checkYourEmail: '/checkYourEmail',
   signIn: '/signIn',
   share: '/share',
   mobileRedirect: '/mobileredirect',

   gender: '/gender',
   causes: '/causes',
   subscription: '/subscription',
   donation: '/donation',
   portfolio: '/portfolio',
   charity: '/charity/:id',
   payment: '/payment',
   paymentCheck: '/paymentCheck',
   name: '/name',
   generatePage: '/generatePortfolio',
   gratitude: '/gratitude',
   profile: '/profile',
   myProfile: '/myProfile',
   impactDetail: '/impactDetail/:slug/:impcatId',
   publicProfile: '/p/:slug',
   magicLink: '/ml',
   marketingSurvey: '/marketingSurvey',

   creatorFlow: {
      base: '/creators',
      intro: '',
      emailValidation: 'ml/:code',
      creatorImpact: 'impact',
      gratitude: 'gratitude',
   },
   seaspiracy: {
      base: '/seaspiracy',
      intro: '/seaspiracy/intro',
      signup: '/seaspiracy/signup',
      payment: '/seaspiracy/payment',
      thankYou: '/seaspiracy/thank_you',
   },
   newflow1: {
      base: '/newflow1/',
      welcome: '/newflow1/welcome',
      yearly: '/newflow1/yearly',
      causes: '/newflow1/causes',
      matchingAnimation: '/newflow1/matching_animation',
      portfolio: '/newflow1/portfolio',
      subscription: '/newflow1/subscription',
      planbuildingAnimation: '/newflow1/planbuilding_animation',
      signup: '/newflow1/signup',
      summary: '/newflow1/summary',
      payment: '/newflow1/payment',
      thankYou: '/newflow1/thank_you',
   },
});
