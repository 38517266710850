import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Box, Collapse } from '@mui/material';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';

interface IFaqItem {
   faq: {
      order: number;
      answer: string;
      question: string;
   };
}

const FaqItem: React.FC<IFaqItem> = ({ faq }) => {
   const { t } = useTranslation();
   const [isOpen, setIsOpen] = useState<boolean>(false);

   const handleOnClick = () => {
      setIsOpen((prev) => {
         if (!prev) {
            analytics?.track('opened_faq_question', {
               question: t(faq.question),
               questionPosition: faq.order,
            });
         }
         return !prev;
      });
   };

   return (
      <Box sx={{ p: 2.5, borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-between',
               alignItems: 'center',
               cursor: 'pointer',
            }}
            onClick={handleOnClick}
            // onClick={() => setIsOpen((prev) => !prev)}
         >
            <Typography sx={{ fontWeight: 700, fontSize: '14px', lineHeight: '17px' }}>{t(faq.question)}</Typography>
            <Box sx={{ transition: 'transform 0.3s ease-in-out', rotate: isOpen ? '180deg' : 0 }}>
               <DynamicGoogleIcon iconName='keyboard_arrow_down' />
            </Box>
         </Box>
         <Collapse in={isOpen} timeout='auto'>
            <Box sx={{ mt: 1.375 }}>
               <Typography sx={{ color: '#666666', fontWeight: 600, fontSize: '14px', lineHeight: '17px' }}>
                  {t(faq.answer)}
               </Typography>
            </Box>
         </Collapse>
      </Box>
   );
};

export default FaqItem;
