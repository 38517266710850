import { Box, Typography, Button, Modal, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import { authState } from 'store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ImpactCarousel from 'components/impactCarousel';
import { getImactCarouselData, tryParseInt } from 'utils/utils';
import FaqItem from 'components/newFlow1/FaqItem';
import { newFlow1State, setDonationAmount, setReturnedUser } from 'store/newFlow1/newFlow1.slice';
import { routes } from 'routes/routes';
import { getStripePaymentEmbedded } from 'store/auth/auth.services';
import { selectedCharitiesState } from 'store/charities/charities.slice';
import { causesState } from 'store/causes/causes.slice';
import { IconWrapper, AmountBox } from 'pages/subscription/styles';
import { NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS } from 'styles/constants';

const NewFlowSummaryPage = () => {
   const [isFullyVisible, setIsFullyVisible] = useState(false);
   const buttonRef = useRef<HTMLButtonElement | null>(null);
   const summaryContainerRef = useRef<HTMLDivElement | null>(null);

   const isDesktop = useMediaQuery('(min-width:1090px)');
   const isHighScreen = useMediaQuery('(min-height:670px)');
   const minAmount = tryParseInt(process.env.REACT_APP_MIN_AMOUNT, 5);
   const changeAmount = tryParseInt(process.env.REACT_APP_CHANGE_AMOUNT, 5);
   const maxAmount = tryParseInt(process.env.REACT_APP_MAX_AMOUNT, 3000);

   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const { donationAmount, returnedUser, couponCodeID } = useAppSelector(newFlow1State);
   const { loggedUserData, signupData } = useAppSelector(authState);
   const { selectedCausesList } = useAppSelector(causesState);
   const { selectedCharities } = useAppSelector(selectedCharitiesState);

   const feedbackTexts = [
      'getStarted.summary.feedback1',
      'getStarted.summary.feedback2',
      'getStarted.summary.feedback3',
   ];
   const faqData = [
      {
         order: 1,
         question: 'getStarted.summary.faq.q1',
         answer: 'getStarted.summary.faq.a1',
      },
      {
         order: 2,
         question: 'getStarted.summary.faq.q2',
         answer: 'getStarted.summary.faq.a2',
      },
      {
         order: 3,
         question: 'getStarted.summary.faq.q3',
         answer: 'getStarted.summary.faq.a3',
      },
      {
         order: 4,
         question: 'getStarted.summary.faq.q4',
         answer: 'getStarted.summary.faq.a4',
      },
      {
         order: 5,
         question: 'getStarted.summary.faq.q5',
         answer: 'getStarted.summary.faq.a5',
      },
      {
         order: 6,
         question: 'getStarted.summary.faq.q6',
         answer: 'getStarted.summary.faq.a6',
      },
      {
         order: 7,
         question: 'getStarted.summary.faq.q7',
         answer: 'getStarted.summary.faq.a7',
      },
      {
         order: 8,
         question: 'getStarted.summary.faq.q8',
         answer: 'getStarted.summary.faq.a8',
      },
      {
         order: 9,
         question: 'getStarted.summary.faq.q9',
         answer: 'getStarted.summary.faq.a9',
      },
      {
         order: 10,
         question: 'getStarted.summary.faq.q10',
         answer: 'getStarted.summary.faq.a10',
      },
      {
         order: 11,
         question: 'getStarted.summary.faq.q11',
         answer: 'getStarted.summary.faq.a11',
      },
      {
         order: 12,
         question: 'getStarted.summary.faq.q12',
         answer: 'getStarted.summary.faq.a12',
      },
      {
         order: 13,
         question: 'getStarted.summary.faq.q13',
         answer: 'getStarted.summary.faq.a13',
      },
      {
         order: 14,
         question: 'getStarted.summary.faq.q14',
         answer: 'getStarted.summary.faq.a14',
      },
   ];

   const [amount, setAmount] = useState(donationAmount);
   const [showAmountModal, setShowAmountModal] = useState(false);
   const [showImpactInfo, setShowImpactInfo] = useState(false);

   // const getCauses = useCallback(() => {
   //    const causesSet = new Set<string>();
   //    selectedCharities.forEach((ch) => causesSet.add(ch.causes[0].title));
   //    const causesArray = Array.from(causesSet);
   //    return causesArray;
   // }, [selectedCharities]);

   const handleNext = async () => {
      const metaData = {
         sytemSessionId: signupData.sessionId,
         flow: 'newflow1',
         ref_id: signupData.referralCode,
      };
      const cs = await getStripePaymentEmbedded({
         money: donationAmount,
         serviceFee: 0,
         extraParam: 'isNewFlow=true',
         metaData,
         couponCodeID,
      });
      analytics?.track('new_flow1_begin_checkout', {
         causes: `[${selectedCausesList.map((cause) => cause.id).join()}]`,
         value: amount,
         currency: 'USD',
      });
      navigate(routes().newflow1.payment, { state: { cs } });
   };

   const changeAmountButtonClick = (increase: boolean) => {
      let multi = Math.floor(amount / changeAmount);
      // eslint-disable-next-line no-plusplus
      increase ? multi++ : multi--;
      let finalValue = multi * changeAmount;
      if (finalValue < minAmount) {
         finalValue = minAmount;
      } else if (finalValue > maxAmount) {
         finalValue = maxAmount;
      }
      setAmount(finalValue);
   };

   const editCauses = () => {
      navigate(routes().newflow1.causes);
   };

   const handleSave = () => {
      dispatch(setDonationAmount(amount));
      analytics?.track('new_flow1_amount_changed', {
         value: amount,
         currency: 'USD',
      });
      setShowAmountModal(false);
   };

   const userName = loggedUserData?.firstName ?? 'Your';

   const checkScreenHeight = () => {
      if (buttonRef.current) {
         const rect = buttonRef.current.getBoundingClientRect();

         const isVisible =
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
         setIsFullyVisible(isVisible);
      }
   };

   const handleCloseModal = () => {
      if (showAmountModal) {
         setShowAmountModal(false);
      }
      if (showImpactInfo) {
         setShowImpactInfo(false);
      }
   };

   useEffect(() => {
      const scrollContainer = document.getElementById('root');
      if (!isDesktop && buttonRef.current && scrollContainer) {
         // Initial check on component mount
         checkScreenHeight();
      }
   }, [buttonRef, isDesktop, summaryContainerRef.current?.offsetHeight]);

   return (
      <Box
         sx={{
            flex: 1,
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            columnGap: isDesktop ? 2.5 : 0,
            width: '100%',
            mt: { xs: 5 },
         }}
      >
         <Box
            sx={{
               maxWidth: isDesktop ? '720px' : '100%',
               flex: '1',
               display: 'flex',
               flexDirection: 'column',
               px: isDesktop ? 0 : 2.5,
               pb: 5,
               overflow: 'hidden',
            }}
         >
            {/* <Box sx={{ pt: { xs: 5, sm: 12.5 }, height: { xs: 'calc(100vh - 130px)', sm: 'calc(100vh - 30px)' } }}> */}
            <Box sx={{ mb: 2.5 }}>
               <Box ref={summaryContainerRef} sx={{ backgroundColor: '#FFFFFF', py: 2.5, borderRadius: '8px' }}>
                  <Box sx={{ borderBottom: '1px solid #E5E5E5', mx: 2.5, pb: 1.5, mb: 1.5 }}>
                     <Typography
                        sx={{
                           fontSize: '20px',
                           lineHeight: '22px',
                           color: '#333333',
                        }}
                        variant='custom-header'
                     >
                        {userName}
                        {t('getStarted.summary.title')}
                     </Typography>
                     {returnedUser && (
                        <Typography sx={{ fontSize: '16px', lineHeight: '19px', color: '#666666', mt: '12px' }}>
                           {t('getStarted.summary.subtitle', { user: userName })}
                        </Typography>
                     )}
                  </Box>
                  <Box
                     sx={{
                        mb: 0.75,
                        mx: 2.5,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                     }}
                  >
                     <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                        {t('getStarted.summary.causes').toUpperCase()}
                     </Typography>
                     {returnedUser && (
                        <Button onClick={editCauses} sx={{ p: 0, minWidth: 'unset' }}>
                           <DynamicGoogleIcon iconSizeVariant='small' iconName='edit' />
                        </Button>
                     )}
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '6px',
                        borderBottom: '1px solid #E5E5E5',
                        pb: 1.5,
                        mb: 1.5,
                        mx: 2.5,
                     }}
                  >
                     {/* {getCauses().map((c) => ( */}
                     {selectedCausesList.map((c) => (
                        <Box
                           sx={{
                              width: isDesktop ? 'fit-content' : '33%',
                              borderRadius: '6px',
                              backgroundColor: '#EFCAC9',
                              py: 0.75,
                              px: 1,
                           }}
                           key={c.title}
                        >
                           <Typography sx={{ fontWeight: 600, fontSize: '12px', lineHeight: '14px' }}>
                              {c.title}
                           </Typography>
                        </Box>
                     ))}
                  </Box>
                  <Box sx={{ mb: 0.5, mx: 2.5 }}>
                     <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                        {t('getStarted.summary.charities').toUpperCase()}
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: isDesktop ? 'wrap' : 'nowrap',
                        columnGap: '10px',
                        rowGap: isDesktop ? 0.75 : 0,
                        width: '100%',
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        scrollbarWidth: 'none', // For Firefox
                        '&::-webkit-scrollbar': {
                           display: 'none', // For Chrome, Safari, and Edge
                        },
                        px: 2.5,
                        mb: 1.5,
                     }}
                  >
                     {selectedCharities.map((ch) => (
                        <Box
                           key={ch.title}
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                              columnGap: 1,
                              width: isDesktop ? '32%' : 'fit-content',
                              height: '40px',
                           }}
                        >
                           <Box
                              sx={{
                                 width: '40px',
                                 height: '40px',
                                 boxSizing: 'border-box',
                                 border: '1px solid #E6E6E6',
                                 overflow: 'hidden',
                              }}
                           >
                              <img alt={ch.title} src={ch.icon} height='100%' width='100%' />
                           </Box>

                           <Box
                              sx={{
                                 mr: 'auto',
                              }}
                           >
                              <Typography sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: 600 }}>
                                 {ch.title}
                              </Typography>
                              <Typography
                                 noWrap
                                 sx={{
                                    fontSize: '11px',
                                    lineHeight: '13px',
                                    fontWeight: 500,
                                    color: '#999999',
                                 }}
                              >
                                 {ch.shortDescription}
                              </Typography>
                           </Box>
                        </Box>
                     ))}
                  </Box>
                  <Box
                     sx={{
                        display: isDesktop ? 'none' : 'block',
                        borderBottom: '1px solid #E5E5E5',
                        borderTop: '1px solid #E5E5E5',
                        mx: 2.5,
                        py: 1.5,
                        mb: 1.5,
                     }}
                  >
                     <Box sx={{ mb: 0.75 }}>
                        <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                           {t('getStarted.summary.donationPlan').toUpperCase()}
                        </Typography>
                     </Box>

                     <Button onClick={() => setShowAmountModal((prev) => !prev)} sx={{ p: 0, justifyContent: 'start' }}>
                        <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px', mr: 0.5 }}>
                           ${donationAmount}
                        </Typography>
                        <DynamicGoogleIcon iconSizeVariant='small' iconName='edit' />
                     </Button>
                  </Box>
                  <Box>
                     <Box
                        sx={{
                           width: '100%',
                           px: 2.5,
                        }}
                     >
                        <Button
                           onClick={() => setShowImpactInfo(true)}
                           sx={{
                              p: 0,
                              width: '100%',
                              borderTop: isDesktop ? '1px solid #E5E5E5' : '',
                              pt: isDesktop ? 1.5 : 0,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'start',
                              alignItems: 'center',
                              mb: 0.75,
                           }}
                        >
                           <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                              {t('getStarted.summary.impact')}
                           </Typography>
                           <DynamicGoogleIcon iconColor='#666666' iconSizeVariant='small' iconName='contact_support' />
                        </Button>
                     </Box>

                     <ImpactCarousel
                        size='small'
                        amount={donationAmount}
                        charities={getImactCarouselData(selectedCharities)}
                     />
                  </Box>
                  <Box
                     sx={{
                        mx: 2.5,
                        mt: 1.5,
                        pt: 1.5,
                        display: isDesktop ? 'none' : 'block',
                        borderTop: '1px solid #E5E5E5',
                     }}
                  >
                     <Typography>{t('getStarted.summary.donationsDisclaimer')}</Typography>
                  </Box>
               </Box>

               <Box
                  ref={buttonRef}
                  sx={{
                     width: { xs: isHighScreen ? '100%' : '100vw', sm: '100%' },
                     mt: 2.5,
                     px: isHighScreen ? 0 : 1.5,
                     display: isDesktop ? 'none' : 'flex',
                     position: { xs: isFullyVisible ? 'static' : 'fixed', sm: 'static' },
                     bottom: 20,
                     left: 0,
                     zIndex: 4,
                  }}
               >
                  <Button sx={{ width: '100%' }} onClick={handleNext} variant='primaryBtn'>
                     <Typography>{t('getStarted.summaryPage.CTA')}</Typography>
                  </Button>
               </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2.5 }}>
               <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px', color: '#333333' }}>
                  {t('getStarted.summary.feedBackTitle')}
               </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 1.25, alignItems: 'center', mb: 5 }}>
               {feedbackTexts.map((text) => (
                  <Box
                     sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        rowGap: 0.5,
                        px: 1.25,
                        py: 2.5,
                        backgroundColor: '#FFFFFF',
                        height: '100%',
                        borderRadius: '8px',
                     }}
                     key={text}
                  >
                     <DynamicGoogleIcon iconColor='#F55D66' iconName='task_alt' />
                     <Typography textAlign='center'>{t(text)}</Typography>
                  </Box>
               ))}
            </Box>

            <Box sx={{ mb: 2.5 }}>
               <Typography textAlign='center' sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                  FAQ
               </Typography>
            </Box>
            {/* FAQ */}
            <Box sx={{ rowGap: 2.5, display: 'flex', flexDirection: 'column' }}>
               {faqData.map((faq) => (
                  <FaqItem key={faq.question} faq={faq} />
               ))}
            </Box>

            <Modal open={showAmountModal || showImpactInfo} onClose={handleCloseModal}>
               <Box
                  sx={{
                     width: '100vw',
                     height: '100vh',
                     overflow: 'hidden',
                     position: 'absolute',
                     backgroundColor: 'rgba(0, 0, 0, 0.3)',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     px: 2.5,
                  }}
               >
                  <Box
                     sx={{
                        borderRadius: '6px',
                        backgroundColor: '#FFFFFF',
                        p: 2.5,
                     }}
                  >
                     {showAmountModal && (
                        <Box
                           sx={{
                              maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS,
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                           }}
                        >
                           <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                              {t('getStarted.summaryPage.amountModalTitle')}
                           </Typography>
                           <Box
                              sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS,
                                 width: '100%',
                                 px: '20px',
                                 pt: '20px',
                                 backgroundColor: '#FFFFFF',
                                 borderRadius: '8px',
                              }}
                           >
                              <Box
                                 sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                 }}
                              >
                                 <IconWrapper
                                    onClick={() => {
                                       analytics?.track('new_flow1_amount_change', {
                                          action: 'minus',
                                          amount: amount - changeAmount,
                                       });
                                       changeAmountButtonClick(false);
                                    }}
                                    disabled={amount === minAmount}
                                 >
                                    <DynamicGoogleIcon iconColor='#FFFFFF' iconName='remove' />
                                 </IconWrapper>
                                 <AmountBox>
                                    <Typography component='h6' className='SubscriptionAmount'>
                                       ${amount}
                                    </Typography>
                                 </AmountBox>
                                 <IconWrapper
                                    onClick={() => {
                                       analytics?.track('new_flow1_amount_change', {
                                          action: 'plus',
                                          amount: amount + changeAmount,
                                       });

                                       changeAmountButtonClick(true);
                                    }}
                                    disabled={amount === maxAmount}
                                 >
                                    <DynamicGoogleIcon iconColor='#FFFFFF' iconName='add' />
                                 </IconWrapper>
                              </Box>
                              <Box>
                                 <Button onClick={handleSave} variant='primaryBtn'>
                                    <Typography>Save</Typography>
                                 </Button>
                                 <Button
                                    onClick={handleCloseModal}
                                    sx={{
                                       backgroundColor: '#FFFFFF',
                                       '&:hover': {
                                          backgroundColor: '#FFFFFF',
                                       },
                                    }}
                                    variant='primaryBtn'
                                 >
                                    <Typography sx={{ color: '#F55D66 !important' }}>Cancel</Typography>
                                 </Button>
                              </Box>
                           </Box>
                        </Box>
                     )}
                     {showImpactInfo && (
                        <Box
                           sx={{
                              maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS,
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: 2.5,
                           }}
                        >
                           <Box>
                              <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px' }}>
                                 {t('getStarted.summary.ImactInfoTitle')}
                              </Typography>
                           </Box>
                           <Box>
                              <Typography
                                 sx={{ color: '#666666', fontWeight: 600, fontSize: '16px', lineHeight: '19px' }}
                              >
                                 {t('getStarted.summary.ImactInfoText')}
                              </Typography>
                           </Box>
                           <Box>
                              <Button onClick={handleCloseModal} variant='primaryBtn'>
                                 <Typography>{t('getStarted.summary.ImpactInfoCTA')}</Typography>
                              </Button>
                           </Box>
                        </Box>
                     )}
                  </Box>
               </Box>
            </Modal>
         </Box>
         {isDesktop && (
            <Box
               sx={{
                  width: '350px',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#FFFFFF',
                  p: 2.5,
                  borderRadius: '8px',
                  height: 'fit-content',
               }}
            >
               <Box>
                  <Typography
                     sx={{
                        fontSize: '20px',
                        lineHeight: '22px',
                        color: '#333333',
                     }}
                     variant='custom-header'
                  >
                     {t('getStarted.summary.title2', { charities: selectedCharities.length })}
                  </Typography>
               </Box>

               <Box
                  sx={{
                     borderBottom: '1px solid #E5E5E5',
                     borderTop: '1px solid #E5E5E5',
                     py: 1.5,
                     my: 1.5,
                  }}
               >
                  <Box sx={{ mb: 0.75 }}>
                     <Typography sx={{ color: '#666666', fontWeight: 700, fontSize: '12px', lineHeight: '14px' }}>
                        {t('getStarted.summary.donationPlan').toUpperCase()}
                     </Typography>
                  </Box>

                  <Button onClick={() => setShowAmountModal((prev) => !prev)} sx={{ p: 0, justifyContent: 'start' }}>
                     <Typography sx={{ fontWeight: 700, fontSize: '20px', lineHeight: '22px', mr: 0.5 }}>
                        ${donationAmount}
                     </Typography>
                     <DynamicGoogleIcon iconSizeVariant='small' iconName='edit' />
                  </Button>
               </Box>

               <Box>
                  <Typography>{t('getStarted.summary.donationsDisclaimer')}</Typography>
               </Box>
               <Button sx={{ mt: 2.5 }} onClick={handleNext} variant='primaryBtn'>
                  <Typography>{t('getStarted.summaryPage.CTA')}</Typography>
               </Button>
            </Box>
         )}
      </Box>
   );
};

export default NewFlowSummaryPage;
