import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { confetti } from '@tsparticles/confetti';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo2 from 'components/Logo2';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import { routes } from 'routes/routes';
import { NEW_FLOW1_MAX_CONTENT_WIDTH } from 'styles/constants';
import { authState, fetchUserByAccessToken } from 'store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AnalyticsTrackPurchase } from 'utils/utils';
import { clearNewFlowStore } from 'store/newFlow1/newFlow1.slice';
import { clearCausesData } from 'store/causes/causes.slice';
import { clearSelectedCharities } from 'store/charities/charities.slice';
import { clearQuotesStore } from 'store/creatorQuotes/creatorQuotes.slice';

const AnimationFinishPage = () => {
   const { t } = useTranslation();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const { loggedUserData } = useAppSelector(authState);

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const sessionId = searchParams.get('session_id') || '';

   const selectedCausesData = localStorage.getItem('selectedCauses') || '[]';
   const convertedCausesList = JSON.parse(selectedCausesData).map((cause: any) => cause.id);

   const items = JSON.parse(sessionStorage.getItem(sessionId) || '[]');

   const sendPurchaseEvent = () => {
      AnalyticsTrackPurchase(sessionId, items, convertedCausesList);
   };

   const fetchUserWithRetries = async (retries: number) => {
      if (retries <= 0) {
         setIsLoading(false);
         console.log('finished retries, user has no active subscribtion');
         return;
      }
      const response = await dispatch(fetchUserByAccessToken());
      if (
         response.payload?.subscription.status &&
         (response.payload.subscription.status === 'active' || response.payload.subscription.status === 'complete')
      ) {
         sendPurchaseEvent();
         setIsLoading(false);
         dispatch(clearCausesData());
         dispatch(clearSelectedCharities());
         dispatch(clearNewFlowStore());
         navigate(routes().myProfile);
      } else {
         setTimeout(() => fetchUserWithRetries(retries - 1), 2000);
      }
   };

   const handleNext = async () => {
      const confettiCanvas = document.getElementById('confetti');
      // animation clean-up
      confettiCanvas?.remove();
      if (
         loggedUserData?.subscription?.status &&
         (loggedUserData?.subscription?.status === 'active' || loggedUserData?.subscription?.status === 'complete')
      ) {
         sendPurchaseEvent();
         dispatch(clearCausesData());
         dispatch(clearSelectedCharities());
         dispatch(clearNewFlowStore());
         dispatch(clearQuotesStore());
         navigate(routes().myProfile);
      } else {
         setIsLoading(true);
         await fetchUserWithRetries(3);
      }
   };
   useEffect(() => {
      dispatch(fetchUserByAccessToken());
   }, [dispatch]);

   useEffect(() => {
      const end = Date.now() + 5 * 1000;
      const colors = ['#F55D66', '#FFFFFF'];

      confetti({
         particleCount: 100,
         spread: 100,
         origin: { y: 0.1 },
         colors: ['#FFFFFF', '#F55D66', '#E7BABA'],
      });

      const frame = () => {
         confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors,
         });
         confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors,
         });
         if (Date.now() < end) {
            requestAnimationFrame(frame);
         }
      };

      frame();
   }, []);

   return (
      <Box
         display='flex'
         flexDirection='column'
         justifyItems='center'
         alignItems='center'
         sx={{ width: '100%', height: '100%', pt: '31vh' }}
      >
         <Logo2 isInContentPart />
         <Box sx={{ mt: 2.5 }}>
            <Typography variant='custom-header'>{t('getStarted.finalAnimationPage.title')}</Typography>
         </Box>
         <Box
            sx={{
               position: { xs: 'absolute', sm: 'static' },
               bottom: '40px',
               display: 'flex',
               justifyContent: 'center',
               width: '100%',
               pt: { sm: 5 },
               px: 2.5,
            }}
         >
            <Button
               disabled={isLoading}
               onClick={handleNext}
               sx={{ maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH, zIndex: 101 }}
               variant='primaryBtn'
            >
               {isLoading ? (
                  <CircularProgress size={30} sx={{ color: '#F55D66' }} />
               ) : (
                  <>
                     <Typography>{t('getStarted.finalAnimationPage.CTA')}</Typography>
                     <DynamicGoogleIcon iconColor='#FFFFFF' iconSizeVariant='small' iconName='arrow_forward' />
                  </>
               )}
            </Button>
         </Box>
      </Box>
   );
};

export default AnimationFinishPage;
