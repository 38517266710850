import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';

import { authState, loginReturnUser } from 'store/auth/auth.slice';
import { useLogout } from 'hooks/useLogout';
import RenderSwitch from 'components/welcomePageRenderSwitch/RenderSwitch';
import { setCouponId, setDonationAmount, setReturnedUser } from 'store/newFlow1/newFlow1.slice';
import { setSelectedCauses } from 'store/causes/causes.slice';
import { CauseTypes, CharityTypes } from 'store/causes/types';
import { storeSelectedCharities } from 'store/charities/charities.slice';

export const WelcomePageV2 = () => {
   const { logout } = useLogout();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { t } = useTranslation();
   const [searchParams] = useSearchParams();
   const { loggedUserData } = useAppSelector(authState);
   const [returnUser, setReturnUser] = useState(searchParams.get('back') === 'true');
   const isNewFlowReturnedUser = searchParams.get('back') === 'newflow1';

   const handleSwitchUser = () => {
      setReturnUser(false);
      logout();
   };

   const handleGetUserToNewFLow1 = async () => {
      const email = searchParams.get('email');
      const couponCodeID = searchParams.get('couponId');
      if (email) {
         const encodedEmail = encodeURIComponent(email);
         const response = await dispatch(loginReturnUser({ email: encodedEmail }));
         const causes = response.payload.user.userCauses;
         const charities = response.payload.user.userCharities;
         const donationAmount = response.payload.user.details.dynamic?.donationAmount ?? 20;
         if (causes && causes.length > 0) {
            const selectedCauses = causes.map((c: { cause: CauseTypes }) => c.cause);
            dispatch(setSelectedCauses(selectedCauses));
         }
         if (charities && charities.length > 0) {
            const selectedCharities = charities
               .filter((ch: { active: boolean }) => ch.active === true)
               .map((ch: { charity: CharityTypes }) => ch.charity);
            dispatch(storeSelectedCharities(selectedCharities));
         }
         dispatch(setDonationAmount(donationAmount));
         couponCodeID && dispatch(setCouponId(couponCodeID));
         dispatch(setReturnedUser(true));
      }
   };

   useEffect(() => {
      if (isNewFlowReturnedUser) {
         handleGetUserToNewFLow1();
      }
   }, [searchParams]);

   useEffect(() => {
      const loadAppleScript = () => {
         return new Promise<void>((resolve, reject) => {
            if (document.getElementById('apple-js')) {
               resolve(); // Avoid loading multiple times
               return;
            }

            const script = document.createElement('script');
            script.id = 'apple-js';
            script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Apple JS SDK failed to load'));
            document.body.appendChild(script);
         });
      };

      const initializeAppleLogin = () => {
         if (window.AppleID) {
            const redirectURI = process.env.REACT_APP_BASE_URL ?? '';

            window.AppleID.auth.init({
               clientId: 'so.bono',
               scope: 'name email',
               redirectURI,
               usePopup: true,
            });
         }
      };

      loadAppleScript()
         .then(() => initializeAppleLogin())
         .catch((error) => console.error('Apple Login initialization failed:', error));

      document.title = t('siteTitleName');
   }, []);

   useEffect(() => {
      if (loggedUserData) {
         if (loggedUserData.subscription?.status === 'active') {
            navigate(routes().myProfile);
         } else {
            setReturnUser(true);
         }
      }
   }, [loggedUserData]);

   const handleNext = () => {
      if (isNewFlowReturnedUser && loggedUserData) {
         navigate(routes().newflow1.summary);
      } else if (loggedUserData) {
         navigate(`${routes().causes}${returnUser ? '?back=true' : ''}`);
      } else {
         navigate(routes().loginEmail);
      }
   };

   return <RenderSwitch returnUser={returnUser} handleSwitchUser={handleSwitchUser} handleNext={handleNext} />;
};
