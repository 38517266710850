import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';
import { checkPayment, getUserByAccessToken } from 'store/auth/auth.services';
import { addTokens, saveUserData } from 'store/auth/auth.slice';
import { reassignCausesToUserByAccessToken } from 'store/causes/causes.services';
import { causesState, setSelectedCauses } from 'store/causes/causes.slice';
import { reassignCharitiesByAccessToken } from 'store/charities/charities.services';
import { storeSelectedCharities } from 'store/charities/charities.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearNewFlowStore } from 'store/newFlow1/newFlow1.slice';

const PaymentCheckPage = () => {
   const location = useLocation();
   // const { accessToken } = useAppSelector(authState);
   const { specialCauseData } = useAppSelector(causesState);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [isPaymentSucceded, setIsPaymentSucceded] = useState<boolean | null>(null);
   const searchParams = new URLSearchParams(location.search);
   const sessionId = searchParams.get('session_id') || '';
   const isNewFlow = searchParams.get('isNewFlow') || '';
   const flow = searchParams.get('flow') || '';
   const isSeaspiracyFlow = flow === 'seaspiracy';

   const check = async () => {
      if (sessionId) {
         const data = await checkPayment(sessionId);
         if (isSeaspiracyFlow && data?.token && specialCauseData?.seaspiracy) {
            dispatch(addTokens(data.token));
            // fetch user data
            const userData = await getUserByAccessToken();

            dispatch(saveUserData({ userData: userData.data, updateAnalytics: true }));
            // send causes
            const causeIds = `${specialCauseData.seaspiracy?.causes?.map((cause) => `${cause.id}`).join(',') ?? ''}`;
            reassignCausesToUserByAccessToken({ causeIds });
            // send charities
            const charitiesIds =
               specialCauseData.seaspiracy?.charities?.map((charity) => `${charity.id}`).join(',') || '';
            reassignCharitiesByAccessToken({ charitiesIds });

            // update causes and charities at store
            dispatch(setSelectedCauses(specialCauseData.seaspiracy.causes));
            dispatch(storeSelectedCharities(specialCauseData.seaspiracy.charities));
         }

         if (data?.sessionDetails && data.sessionDetails.status && data.sessionDetails.payment_status) {
            setIsPaymentSucceded(
               data.sessionDetails.status === 'complete' && data.sessionDetails.payment_status === 'paid'
            );
         } else setIsPaymentSucceded(false);
      }
   };

   useEffect(() => {
      if (sessionId) {
         check();
      }
   }, [sessionId]);

   useEffect(() => {
      if (isPaymentSucceded !== null) {
         if (isNewFlow) {
            if (isPaymentSucceded) {
               dispatch(clearNewFlowStore());
               navigate(`${routes().newflow1.thankYou}?session_id=${sessionId}`);
            } else navigate(routes().newflow1.summary);
         } else if (isSeaspiracyFlow) {
            if (isPaymentSucceded) {
               navigate(`${routes().seaspiracy.thankYou}?session_id=${sessionId}`);
            } else navigate(routes().seaspiracy.intro);
         } else if (isPaymentSucceded) {
            navigate(routes().gratitude);
         } else navigate(routes().subscription);
      }
   }, [isPaymentSucceded]);

   if (isPaymentSucceded !== null)
      return (
         <Box
            sx={{
               flex: '1',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
               <path
                  d='M8 15C6.61553 15 5.26215 14.5895 4.11101 13.8203C2.95986 13.0511 2.06266 11.9579 1.53284 10.6788C1.00303 9.3997 0.864406 7.99223 1.1345 6.63437C1.4046 5.2765 2.07128 4.02922 3.05025 3.05025C4.02922 2.07128 5.2765 1.4046 6.63437 1.1345C7.99223 0.864406 9.3997 1.00303 10.6788 1.53284C11.9579 2.06266 13.0511 2.95986 13.8203 4.11101C14.5895 5.26215 15 6.61553 15 8'
                  stroke='#F55D66'
                  strokeWidth='2'
               />
               <animateTransform
                  attributeType='xml'
                  attributeName='transform'
                  type='rotate'
                  values='0 0 0; 360 0 0'
                  dur='1s'
                  additive='sum'
                  repeatCount='indefinite'
               />
            </svg>
         </Box>
      );

   return null;
};

export default PaymentCheckPage;
