import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo2 from 'components/Logo2';
import { routes } from 'routes/routes';
import { causesState, fetchCharitiesByPassedSelectedCauses } from 'store/causes/causes.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS } from 'styles/constants';
import { creatorQuotesState } from 'store/creatorQuotes/creatorQuotes.slice';
import CreatorQuote from 'components/newFlow1/CreatorQuote';

const MatchingAnimationPage = () => {
   const bgImage = '/images/getStartedFlow/bg-matching-mobile.png';
   const bgDesktopImage = '/images/getStartedFlow/bg-matching-desktop.png';
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const loadingRef = useRef(true);
   const startTimeRef = useRef(new Date().getTime());
   const { selectedCausesList } = useAppSelector(causesState);
   const { creatorPhoto, textLocizeParam } = useAppSelector(creatorQuotesState);

   const getCharities = () => {
      dispatch(fetchCharitiesByPassedSelectedCauses()).then(() => {
         loadingRef.current = false;
         localStorage.setItem('charitiesToSelect', JSON.stringify(selectedCausesList));
      });
   };

   const handleNext = () => {
      if (loadingRef.current === false) {
         navigate(routes().newflow1.portfolio);
      }
   };

   useEffect(() => {
      getCharities();
      const timeout = setTimeout(handleNext, 8000);
      return () => clearTimeout(timeout);
   }, []);

   useEffect(() => {
      const timeNow = new Date().getTime();
      if (loadingRef.current === false && timeNow - startTimeRef.current > 8 * 1000) {
         navigate(routes().newflow1.portfolio);
      }
   }, [loadingRef.current]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'flex-start' },
            px: 2.5,
            pt: { sm: 12.5 },
            backgroundImage: { xs: `url(${bgImage})`, sm: `url(${bgDesktopImage})` },
            backgroundOrigin: 'border-box',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
         }}
      >
         <Box
            sx={{
               width: '100%',
               maxWidth: NEW_FLOW1_MAX_CONTENT_WIDTH_WITH_PADDINGS,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <Logo2 isInContentPart />

            <Box sx={{ width: '100%', my: 3.75, textAlign: 'center' }}>
               <Typography variant='custom-header'>{t('getStarted.matchingAnimationPage.title')}</Typography>
            </Box>

            <Box sx={{ width: '100%', mb: 3.75, textAlign: 'center' }}>
               <Typography
                  sx={{
                     fontWeight: 700,
                     fontSize: '20px',
                     color: '#666666',
                  }}
               >
                  {t('getStarted.matchingAnimationPage.text1')}
               </Typography>
            </Box>

            <Box>
               <img
                  style={{
                     width: '100%',
                  }}
                  src='/images/getStartedFlow/badges-animation.gif'
                  alt='animation'
               />
            </Box>

            <Box sx={{ mt: 3.75 }}>
               <Typography
                  sx={{
                     fontWeight: 700,
                     fontSize: '20px',
                     color: '#666666',
                  }}
                  textAlign='center'
               >
                  {t('getStarted.matchingAnimationPage.text2')}
               </Typography>
            </Box>
            {creatorPhoto && textLocizeParam && (
               <CreatorQuote
                  creatorQuote={t(`getStarted.matchingAnimationPage.creatorQuote_${textLocizeParam}`)}
                  creatorPhoto={creatorPhoto}
               />
            )}
         </Box>
      </Box>
   );
};

export default MatchingAnimationPage;
